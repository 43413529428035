import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';

const API_URL_PATH = 'api/v1/auth/';
const API_URL_PATH_SIGNUP = 'api/v1/users/';
const API_URL_PATH_OTP = 'api/v1/otp/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'channel': 'lottopi', 'version': environment.apiVersion })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private tokenStorageSerice: TokenStorageService) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.baseUrl + API_URL_PATH + 'login', {
      username,
      password
    }, httpOptions);
  }

  loginViaOtp(username: string, password: string): Observable<any> {
    return this.http.post(this.baseUrl + API_URL_PATH + 'loginViaOtp', {
      username,
      password
    }, httpOptions);
  }

  register(
    mobileNumber: string,
    password: string,
    otp: string,
    referralCode: string,
    promoCode?: string,
    promoCodeSource?: string
  ): Observable<any> {
    const body: any = {
      mobileNumber,
      password,
      otp,
      referralCode
    };

    if (promoCode) {
      body.promoCode = promoCode;
      body.promoCodeSource = promoCodeSource;
    }
    return this.http.post(this.baseUrl + API_URL_PATH_SIGNUP + 'signup', body, httpOptions);
  }

  getOtp(countryCode: string, mobileNumber: string, purpose: string) {
    return this.http.post(this.baseUrl + API_URL_PATH_OTP + 'sendOtp?type=' + purpose, { countryCode, mobileNumber }, httpOptions);
  }

  checkLoggedIn(): boolean {
    return this.tokenStorageSerice.isLoggedIn();
  }

  checkIsAdmin(): boolean {
    return this.tokenStorageSerice.isAdmin();
  }
}
