import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { AuthService } from 'src/app/_services/auth.service';
import { UserSessionStorageService } from 'src/app/_services/user.session.storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-games-home',
  templateUrl: './games-home.component.html',
  styleUrls: ['./games-home.component.css']
})
export class GamesHomeComponent implements OnInit {
  canShowSpinWheel = true
  constructor(private router: Router, private authService: AuthService, private sessionServices: UserSessionStorageService) { }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    this.canShowSpinWheel = !this.authService.checkLoggedIn() && !this.sessionServices.hasUserSpun()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }
}
