import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from "@angular/core";
import { SelectedOptionService } from "src/app/_services/selectedOption.service";
import { MatDialog } from "@angular/material/dialog";
import { DuplicatePopupComponent } from '../../lottery-games/duplicate-popup/duplicate-popup.component';

@Component({
    selector: "app-three-d",
    templateUrl: "./three-d.component.html",
    styleUrls: ["./three-d.component.css"],
})
export class ThreeDComponent implements OnInit, OnChanges {
    colors: any[] = [
        { label: "C", color: "rgb(213, 0, 0)" },
        { label: "D", color: "rgb(103, 165, 25)" },
        { label: "E", color: "rgb(0, 135, 212)" }
    ];

    @Input() duplicateCombination: any[]=[];
    @Input() nonDuplicates: any[] = [];
    @Input() clearSelection: boolean = false;
    @Output() dataBack = new EventEmitter()
    selectedValuesC: string[] = [];
    selectedValuesD: string[] = [];
    selectedValuesE: string[] = [];
    betSlipCount = 0;
    showWarningMessage = false;

    // Structure to store all checked values and details
    checkedDetails: { option: number, color: string, index: number, label: string }[] = [];
    
    // Store color information for each selected option, keyed by option number + label
    optionColors: { [key: string]: string } = {};

    constructor(private lotterService: SelectedOptionService, private dialog: MatDialog) {
    }

    ngOnInit(): void {
               
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['duplicateCombination'] && this.duplicateCombination?.length > 0) {
        
          const dialogRef = this.dialog.open(DuplicatePopupComponent, {
              data: {
                  duplicateCombination: this.duplicateCombination,
                  nonDuplicate: this.nonDuplicates
              }
          });
  
          dialogRef.afterClosed().subscribe(result => {
              if (result) {
                  this.dataBack.emit(result);
                  this.duplicateCombination = [];
              }
          });
      }
  
      if (changes['clearSelection'] && this.clearSelection) {
          this.resetSelections();
      }
  }
  

    onCheckboxChange(option: number, color: string, index: number, label: string): void {
        if (label === 'C') {
            this.toggleSelection(this.selectedValuesC, option.toString(), label, color);
        } else if (label === 'D') {
            this.toggleSelection(this.selectedValuesD, option.toString(), label, color);
        } else if (label === 'E') {
            this.toggleSelection(this.selectedValuesE, option.toString(), label, color);
        }

        this.updateBetSlipCount();
        this.updateCheckedDetails(option, color, index, label);
    }

    isSelected(option: number, label: string): boolean {
        if (label === 'C') {
            return this.selectedValuesC.includes(option.toString());
        } else if (label === 'D') {
            return this.selectedValuesD.includes(option.toString());
        } else if (label === 'E') {
            return this.selectedValuesE.includes(option.toString());
        }
        return false;
    }

    toggleSelection(array: string[], option: string, label: string, color: string) {
        const valueIndex = array.indexOf(option);

        if (valueIndex === -1) {
            array.push(option);
            this.optionColors[option + label] = color;  // Unique key based on option + label
        } else {
            array.splice(valueIndex, 1);
            delete this.optionColors[option + label];  // Remove the color using the unique key
        }
    }

    updateBetSlipCount() {
        this.betSlipCount = this.selectedValuesC.length * this.selectedValuesD.length * this.selectedValuesE.length;
    }

    resetSelections() {
        this.selectedValuesC = [];
        this.selectedValuesD = [];
        this.selectedValuesE = [];
        this.betSlipCount = 0;
        this.optionColors = {};
    }

    updateCheckedDetails(option: number, color: string, index: number, label: string) {
        const existingDetail = this.checkedDetails.find(detail => detail.option === option && detail.label === label);

        if (existingDetail) {
            this.checkedDetails = this.checkedDetails.filter(detail => !(detail.option === option && detail.label === label));
        } else {
            this.checkedDetails.push({ option, color, index, label });
        }
    }

    generateCombinationsWithColors(): { combination: number[], colors: string[], selectedTab: string, id: string }[] {
        const combinationsWithColors: { combination: number[], colors: string[], selectedTab: string, id: string }[] = [];

        for (const valueC of this.selectedValuesC) {
            for (const valueD of this.selectedValuesD) {
                for (const valueE of this.selectedValuesE) {
                    const combination = [Number(valueC), Number(valueD), Number(valueE)];
                    const colors = [
                        this.optionColors[valueC + 'C'] || '',
                        this.optionColors[valueD + 'D'] || '',
                        this.optionColors[valueE + 'E'] || ''
                    ];
                    const id = `${combination.join('-')}_${colors.join('-')}`;
                    combinationsWithColors.push({ combination, colors, selectedTab: '3D', id });
                }
            }
        }

        return combinationsWithColors;
    }

    addToSlip() {
        if (this.betSlipCount > 100) {
            this.showWarningMessage = true;

            setTimeout(() => {
                this.showWarningMessage = false;
            }, 3000);
        }

        const combinations = this.generateCombinationsWithColors();
        this.lotterService.updateCombinations(combinations);
    }
}
