import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

const API_URL_PATH = "api/v1/dice/";

@Injectable({
  providedIn: "root",
})
export class DiceService {

  private baseUrl = environment.apiUrl + API_URL_PATH;

  constructor(private http: HttpClient) { }
  private getHttpOptions() {
    const token = localStorage.getItem("Authorization"); // Replace 'authToken' with the key used in local storage
    // console.log(token);
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        channel: "lottopi",
        version: environment.apiVersion,
        Authorization: `${token}`, // Assuming a Bearer token, adjust as needed
      }),
    };
  } getNearestDice(gameConfig: number): Observable<any> {
    return this.http.get(
      `${this.baseUrl}getNearest?configId=${gameConfig}`,
      this.getHttpOptions()
    );
  }

  getLotteryAnalysis(): Observable<any> {
    return this.http.get(
      `${this.baseUrl}analyze`,
      this.getHttpOptions()
    );
  }

  getDiceResults(configId: number, page: number = 1, limit: number): Observable<any> {
    return this.http.get(
      `${this.baseUrl}getLatest?configId=${configId}&page=${page}&limit=${limit}`,
      this.getHttpOptions()
    );
  }

  myBets(gameId: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}myBets?gameId=${gameId}`,
      this.getHttpOptions()
    );
  }
  placeBet(
    id: string,
    predictionType: string,
    sumGame: string | null,
    sumGameTotalNumber: number | null,
    betAmount: number,
    betCount: number,
    singlePair?: number,
    doublePair?: number,
    triplePair?: number
  ) {
    // Construct the body dynamically
    const body: any = {
      id,
      predictionType,
      betAmount,
      betCount,
    };

    // Include only one of sumGame or sumGameTotalNumber in the request body
    if (predictionType == 'sum') {
      if (sumGameTotalNumber) {  // This will check if sumGame is not null, undefined, or an empty string
        body.sumGameTotalNumber = sumGameTotalNumber;
      } else if (sumGame !== null) {  // This will check if sumGameTotalNumber is not null
        body.sumGame = sumGame;
      }
    } else if (predictionType == 'double') {
      body.doublePair = doublePair
    } else if (predictionType == 'single') {
      body.singlePair = singlePair
    }
    else if (predictionType == 'triple') {
      body.triplePair = triplePair
    }
    return this.http.post(`${this.baseUrl}placeBet`, body, this.getHttpOptions());
  }

  getMyOrders(page: number = 1, pageSize: number): Observable<any> {
    console.log(page, pageSize);
    return this.http.get(this.baseUrl + `orders?page=${page}&pageSize=${pageSize}`, this.getHttpOptions());
  }
  getWinners(): Observable<any> {
    return this.http.get(this.baseUrl + 'top-winners', this.getHttpOptions())
  }
  getWinStatus(): Observable<any> {
    return this.http.patch(this.baseUrl + 'winStatus', this.getHttpOptions())
  }
  // final dice animation image
  getLastWon(configId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}getRecentResult?configId=${configId}`, this.getHttpOptions())
  }

  
}
