import { Component, OnInit } from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from "src/environments/environment";
import { BallGameService } from 'src/app/_services/ball.game.service';

@Component({
  selector: 'app-ball-game-orders',
  templateUrl: './ball-game-orders.component.html',
  styleUrls: ['./ball-game-orders.component.css']
})

export class BallGameOrdersComponent implements OnInit {
  constructor(
    private ballGameService: BallGameService
  ) { }

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  totalPages = 0;
  private analytics: any;

  pageSize: number = 10;
  orders: any[] = [];
  myOrder: any[] = [];
  firstImageSrc? = ""

  ngOnInit(): void {
    this.getMyOrders();
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  getMyOrders() {
    this.ballGameService.getMyOrders(this.currentPage, this.itemsPerPage).subscribe({
      next: (data) => {
        this.orders = data.data;
        this.totalItems = data.totalBets;
        this.totalPages = data.totalPages;
        this.currentPage = data.currentPage;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;

    logEvent(this.analytics, 'ball_game_orders_pagination_change', {
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage,
      totalItems: this.totalItems
    });
    this.getMyOrders();
  }

  getImageForWinColor(winColor: string, winNumber: number): string {
    if (winNumber !== null && winNumber !== undefined) {
      switch (winColor) {
        case 'r':
          return '../../../../assets/red_ball.webp';
        case 'g':
          return '../../../../assets/green_ball.webp';
        case 'v':
          return '../../../../assets/color-violet.webp';
        case 'rv':
          return '../../../../assets/red-violet.webp';
        case 'gv':
          return '../../../../assets/green-violet.webp';
        default:
          return '../../../../assets/red_ball.webp';
      }
    } else {
      switch (winColor) {
        case 'r':
          return '../../../../assets/color-red.webp';
        case 'g':
          return '../../../../assets/color-green.webp';
        case 'v':
          return '../../../../assets/color-violet.webp';
        case 'rv':
          return '../../../../assets/red-violet.webp';
        case 'gv':
          return '../../../../assets/green-violet.webp';
        default:
          return '../../../../assets/red_ball.webp';
      }
    }
  }
}

