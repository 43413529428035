import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home-toolbar',
  templateUrl: './home-toolbar.component.html',
  styleUrls: ['./home-toolbar.component.css']
})
export class HomeToolbarComponent implements OnInit {
  isLoggedIn: boolean = false;
  private analytics: any;

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void {
    this.isLoggedIn = !this.tokenStorageService.isLoggedIn();
  }

  onLoginClick(): void {
    this.router.navigate(['/login']);

    logEvent(this.analytics, 'home_login_button_click', {
      source_page: window.location.pathname
    });
  }
}
