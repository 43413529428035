<div class="h-80 w-full flex flex-col">
  <div class="text-lg font-semibold mb-4 flex w-full pt-2 justify-between items-center text-center h-10">
    <h2>Please Choose</h2>
    <button class="mb-1" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <ul class="space-y-4 w-full overflow-y-auto">
    <li class="flex items-center justify-between cursor-pointer p-2 rounded-md"
        *ngFor="let lottery of activeLottery"
        [class.selected]="lottery._id === selectedLotteryId"
        (click)="selectLottery(lottery.name)"> <!-- Call selectLottery on click -->
      <div class="flex items-center space-x-4">
        <!-- Bind the image src and alt to the lottery item -->
        <img [src]="lottery.image" [alt]="lottery.alt" class="w-10 h-10 rounded-full">
        <span>{{lottery.name}}</span>
      </div>
      <div class="flex flex-col items-end">
        <ng-container *ngIf="(lottery.gameEndTimeIST | countdown).days > 0">
          <span class="font-semibold">{{ (lottery.gameEndTimeIST | countdown).days }}d</span>
        </ng-container>    
        <span class="text-gray-500 text-sm font-semibold">
          {{ (lottery.gameEndTimeIST | countdown).hours }}:
          {{ (lottery.gameEndTimeIST | countdown).minutes }}:
          {{ (lottery.gameEndTimeIST | countdown).seconds }}
        </span>
      </div>
    </li>
  </ul>
</div>
