<div class="container">
  <header class="toolbar header-row overflow-hidden">
    <div class="welcome">Welcome {{user.name}}</div>
    <div class="title">Admin Dashboard</div>
    <div class="logout">
      <a href class="logout-link" routerLink="wallet" (click)="logout()">Logout</a>
    </div>
  </header>

  <div class="total-amounts-container">
    <div class="total-amount total-pending">
      <div class="amount-label">Total Pending Amount:</div>
      <div class="amount-value">₹{{ totalPendingAmount }}</div>
    </div>
    <div class="total-amount total-processing">
      <div class="amount-label">Total Processing Amount:</div>
      <div class="amount-value">₹{{ totalProcessingAmount }}</div>
    </div>
    <div class="total-amount available-balance">
      <div class="amount-label">Total Balance</div>
      <div class="amount-value">₹{{ availableBalance }}</div>
    </div>
    <div class="frozen-amount total-amount">
      <div class="amount-label">Frozen Balance</div>
      <div class="amount-value">₹{{ frozenAmount }}</div>
    </div>
    <div class="avail-amount total-amount">
      <div class="amount-label">Available Amount</div>
      <div class="amount-value">₹{{ availableAmount }}</div>
    </div>
  </div>

  <div class="table-section">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Pending Payout Requests</div>
    </header>
    <div class="table-container pending-container">
      <table class="table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleAll()">
            </th>
            <th>Account Number</th>
            <th>IFSC Code</th>
            <th>Receiver Name</th>
            <th>Amount to Transfer</th>
            <th>Status</th>
            <th>Phone Number</th>
            <th>Total Balance</th>
            <th>Withdrawable Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payout of payouts">
            <td>
              <input type="checkbox" [(ngModel)]="payout.selected" (change)="checkIfAllSelected()">
            </td>
            <td>{{ payout.bankAccount.accountNumber }}</td>
            <td>{{ payout.bankAccount.ifscCode }}</td>
            <td>{{ payout.bankAccount.receiverName }}</td>
            <td [ngClass]="{
              'amount-red': payout.amountToTransfer > 5000,
              'amount-dark-yellow': payout.amountToTransfer >= 1000 && payout.amountToTransfer <= 5000,
              'amount-black': payout.amountToTransfer < 1000
            }">₹{{ payout.amountToTransfer }}</td>
            <td>{{ payout.status }}</td>
            <td>{{ payout.userId.phone }}</td>
            <td>₹{{ payout.userId.wallet.totalAmount }}</td>
            <td>₹{{ payout.userId.wallet.withdrawableAmount }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button (click)="goToPage(1)" [disabled]="currentPage === 1">First</button>
        <button (click)="goToPage(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button (click)="goToPage(currentPage + 1)"
          [disabled]="currentPage === totalPages || totalPages === 0">Next</button>
        <button (click)="goToPage(totalPages)" [disabled]="currentPage === totalPages || totalPages === 0">Last</button>
      </div>
      <div class="approve-container">
        <button (click)="approveSelected()" [disabled]="!isAnySelected() || loading"> <span
            *ngIf="!loading">Approve</span>
          <div class="loader" *ngIf="loading"></div>
        </button>
      </div>
    </div>
  </div>

  <div class="table-section">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Processing Payments</div>
    </header>
    <div class="table-container processing-container">
      <table class="table">
        <thead>
          <tr>
            <th>Account Number</th>
            <th>IFSC Code</th>
            <th>Receiver Name</th>
            <th>Amount to Transfer</th>
            <th>Status</th>
            <th>Phone Number</th>
            <th>Total Balance</th>
            <th>Withdrawable Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let request of processingPayouts">
            <td>{{ request.bankAccount.accountNumber }}</td>
            <td>{{ request.bankAccount.ifscCode }}</td>
            <td>{{ request.bankAccount.receiverName }}</td>
            <td [ngClass]="{
              'amount-red': request.amountToTransfer > 5000,
              'amount-dark-yellow': request.amountToTransfer >= 1000 && request.amountToTransfer <= 5000,
              'amount-black': request.amountToTransfer < 1000
            }">₹{{ request.amountToTransfer }}</td>
            <td>{{ request.status }}</td>
            <td>{{ request.userId.phone }}</td>
            <td>₹{{ request.userId.wallet.totalAmount }}</td>
            <td>₹{{ request.userId.wallet.withdrawableAmount }}</td>
          </tr>
          <tr *ngIf="processingPayouts.length === 0">
            <td colspan="8" class="text-center">No processing payments found.</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button (click)="goToProcessingPage(1)" [disabled]="processingCurrentPage === 1">First</button>
        <button (click)="goToProcessingPage(processingCurrentPage - 1)"
          [disabled]="processingCurrentPage === 1">Previous</button>
        <span>Page {{ processingCurrentPage }} of {{ processingTotalPages }}</span>
        <button (click)="goToProcessingPage(processingCurrentPage + 1)"
          [disabled]="processingCurrentPage === processingTotalPages || processingTotalPages === 0">Next</button>
        <button (click)="goToProcessingPage(processingTotalPages)"
          [disabled]="processingCurrentPage === processingTotalPages || processingTotalPages === 0">Last</button>
      </div>
    </div>
  </div>

  <div class="table-section">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Completed Payments</div>
    </header>
    <div class="table-container completed-container">
      <table class="table">
        <thead>
          <tr>
            <th>Account Number</th>
            <th>IFSC Code</th>
            <th>Receiver Name</th>
            <th>Amount to Transfer</th>
            <th>Status</th>
            <th>Phone Number</th>
            <th>Total Balance</th>
            <th>Withdrawable Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let request of completedPayouts">
            <td>{{ request.bankAccount.accountNumber }}</td>
            <td>{{ request.bankAccount.ifscCode }}</td>
            <td>{{ request.bankAccount.receiverName }}</td>
            <td [ngClass]="{
              'amount-red': request.amountToTransfer > 5000,
              'amount-dark-yellow': request.amountToTransfer >= 1000 && request.amountToTransfer <= 5000,
              'amount-black': request.amountToTransfer < 1000
            }">₹{{ request.amountToTransfer }}</td>
            <td>{{ request.status }}</td>
            <td>{{ request.userId.phone }}</td>
            <td>₹{{ request.userId.wallet.totalAmount }}</td>
            <td>₹{{ request.userId.wallet.withdrawableAmount }}</td>
          </tr>
          <tr *ngIf="completedPayouts.length === 0">
            <td colspan="8" class="text-center">No completed payments found.</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button (click)="goToCompletedPage(1)" [disabled]="completedCurrentPage === 1">First</button>
        <button (click)="goToCompletedPage(completedCurrentPage - 1)"
          [disabled]="completedCurrentPage === 1">Previous</button>
        <span>Page {{ completedCurrentPage }} of {{ completedTotalPages }}</span>
        <button (click)="goToCompletedPage(completedCurrentPage + 1)"
          [disabled]="completedCurrentPage === completedTotalPages || completedTotalPages === 0">Next</button>
        <button (click)="goToCompletedPage(completedTotalPages)"
          [disabled]="completedCurrentPage === completedTotalPages || completedTotalPages === 0">Last</button>
      </div>
    </div>
  </div>

  <div class="table-section">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Payout Failures</div>
    </header>

    <div class="filter-container">

      <label for="startDate">Start Date:</label>
      <input type="date" id="sepPayFailureStartDate" [(ngModel)]="sepPayFailureStartDate"
        (change)="sepPayoutDateChange()">

      <label for="endDate">End Date:</label>
      <input type="date" id="sepPayFailureEndDate" [(ngModel)]="sepPayFailureEndDate" (change)="sepPayoutDateChange()">
    </div>

    <div class="table-container pending-container">
      <table class="table">
        <thead>
          <tr>
            <th>Payout Request ID</th>
            <th>Status Code</th>
            <th>Order ID</th>
            <th>Response Code</th>
            <th>Error Message</th>
            <th>Amount</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let failure of sepPayOutFailures">
            <td>{{ failure.payoutRequest._id }}</td>
            <td>{{ failure.statusCode }}</td>
            <td>{{ failure.orderId }}</td>
            <td>{{ failure.respCode }}</td>
            <td>{{ failure.errorMessage }}</td>
            <td>{{ failure.payoutRequest.amountToTransfer }}</td>
            <td>{{ failure.created_at | date:'short' }}</td>
          </tr>
          <tr *ngIf="sepPayOutFailures.length === 0">
            <td colspan="7" class="text-center">No payout failures found.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination">
      <button (click)="sepPayoutGoToPage(1)" [disabled]="sepPayFailureCurrentPage === 1">First</button>
      <button (click)="sepPayoutGoToPage(sepPayFailureCurrentPage - 1)"
        [disabled]="sepPayFailureCurrentPage === 1">Previous</button>
      <span>Page {{ sepPayFailureCurrentPage }} of {{ sepPayFailureTotalPages }}</span>
      <button (click)="sepPayoutGoToPage(sepPayFailureCurrentPage + 1)"
        [disabled]="sepPayFailureCurrentPage === sepPayFailureTotalPages || sepPayFailureTotalPages === 0">Next</button>
      <button (click)="sepPayoutGoToPage(sepPayFailureTotalPages)"
        [disabled]="sepPayFailureCurrentPage === sepPayFailureTotalPages || sepPayFailureTotalPages === 0">Last</button>
    </div>
  </div>

</div>