import { Component, OnInit } from '@angular/core';
import { SelectedOptionService } from 'src/app/_services/selectedOption.service';

@Component({
  selector: 'app-four-d',
  templateUrl: './four-d.component.html',
  styleUrls: ['./four-d.component.css']
})
export class FourDComponent implements OnInit {
  colors: any[] = [
    { label: 'A', color: 'rgb(213, 115, 0)' },
    { label: 'B', color: 'rgb(0, 135, 212)' },
    { label: 'C', color: 'rgb(213, 0, 0)' },
    { label: 'D', color: 'rgb(103, 165, 25)' },
  ];

  selectedValuesA: string[] = [];
  selectedValuesB: string[] = [];
  selectedValuesC: string[] = [];
  selectedValuesD: string[] = [];


  showWarningMessage = false;

  // Variable to hold the calculated bet slip count
  betSlipCount = 0;

  // Structure to store all checked values and details
  checkedDetails: { option: number, color: string, index: number, label: string }[] = [];

  // Store color information for each selected option
  optionColors: { [key: number]: string } = {};

  constructor(private selectedOption: SelectedOptionService) {}

  ngOnInit(): void {}

  onCheckboxChange(option: number, color: string, index: number, label: string): void {
    if (label === 'A') {
      this.toggleSelection(this.selectedValuesA, option.toString(), label, color);
    } else if (label === 'B') {
      this.toggleSelection(this.selectedValuesB, option.toString(), label, color);
    } else if (label === 'C') {
      this.toggleSelection(this.selectedValuesC, option.toString(), label, color);
    } else if (label === 'D') {
      this.toggleSelection(this.selectedValuesD, option.toString(), label, color);
    }

    this.updateBetSlipCount();
    this.updateCheckedDetails(option, color, index, label);
  }

  isSelected(option: number, label: string): boolean {
    if (label === 'A') {
      return this.selectedValuesA.includes(option.toString());
    } else if (label === 'B') {
      return this.selectedValuesB.includes(option.toString());
    } else if (label === 'C') {
      return this.selectedValuesC.includes(option.toString());
    } else if (label === 'D') {
      return this.selectedValuesD.includes(option.toString());
    }
    return false;
  }

  toggleSelection(array: string[], option: string, label: string, color: string) {
    const valueIndex = array.indexOf(option);

    if (valueIndex === -1) {
      array.push(option);
      this.optionColors[Number(option)] = color;
    } else {
      array.splice(valueIndex, 1);
      delete this.optionColors[Number(option)];
    }
  }

  updateBetSlipCount() {
    this.betSlipCount = this.selectedValuesA.length * this.selectedValuesB.length * this.selectedValuesC.length * this.selectedValuesD.length;
  }

  resetSelections() {
    this.selectedValuesA = [];
    this.selectedValuesB = [];
    this.selectedValuesC = [];
    this.selectedValuesD = [];
    this.betSlipCount = 0;
    this.optionColors = {};
  }

  updateCheckedDetails(option: number, color: string, index: number, label: string) {
    const existingDetail = this.checkedDetails.find(detail => detail.option === option && detail.label === label);

    if (existingDetail) {
      this.checkedDetails = this.checkedDetails.filter(detail => !(detail.option === option && detail.label === label));
    } else {
      this.checkedDetails.push({ option, color, index, label });
    }
  }

  generateCombinationsWithColors(): { combination: number[], colors: string[], selectedTab: string, id: string }[] {
    const combinationsWithColors: { combination: number[], colors: string[], selectedTab: string, id: string }[] = [];

    for (const valueA of this.selectedValuesA) {
      for (const valueB of this.selectedValuesB) {
        for (const valueC of this.selectedValuesC) {
          for (const valueD of this.selectedValuesD) {
            const combination = [Number(valueA), Number(valueB), Number(valueC), Number(valueD)];
            const colors = [
              this.optionColors[Number(valueA)] || '',
              this.optionColors[Number(valueB)] || '',
              this.optionColors[Number(valueC)] || '',
              this.optionColors[Number(valueD)] || ''
            ];
            const id = `${combination.join('-')}_${colors.join('-')}`;
            combinationsWithColors.push({ combination, colors, selectedTab: '4D', id });
          }
        }
      }
    }    
    return combinationsWithColors;
  }

  addToSlip() {
    if (this.betSlipCount > 100) {
      this.showWarningMessage = true;

      // Hide the message after 3 seconds
      setTimeout(() => {
        this.showWarningMessage = false;
      }, 3000);
    }
    const combinations = this.generateCombinationsWithColors();
    this.selectedOption.updateCombinations(combinations);
    this.resetSelections();
  }
}
