import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Chart } from "chart.js";
import { LotteryService } from "src/app/_services/lottery.service";
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: "app-chart2",
    templateUrl: "./chart2.component.html",
    styleUrls: ["./chart2.component.css"],
})
export class Chart2Component implements OnInit, OnChanges {
    @Input() selectedGame: string = '';
    analise: any = {};
    public charts: { [key: string]: any } = {};

    constructor(private lotteryService: LotteryService) {
        Chart.register(ChartDataLabels);
    }

    ngOnInit(): void {
        this.getLotterydata();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedGame'] && !changes['selectedGame'].isFirstChange()) {
            this.getLotterydata();
        }
    }

    getLotterydata() {
        this.lotteryService.getChartData(this.selectedGame).subscribe({
            next: (data) => {
                this.analise = data.data;
                this.updateCharts();
            },
            error: (err) => {
                console.error('Error fetching data', err);
            }
        });
    }

    updateCharts() {
        ['a', 'b', 'c', 'd'].forEach(key => {
            if (this.charts[`bigSmall${key}`]) {
                this.charts[`bigSmall${key}`].destroy();
            }
            this.createChart(`MyChartBigSmall${key}`, [this.analise[key].big, this.analise[key].small], `bigSmall${key}`);

            if (this.charts[`oddEven${key}`]) {
                this.charts[`oddEven${key}`].destroy();
            }
            this.createChart(`MyChartOddEven${key}`, [this.analise[key].odd, this.analise[key].even], `oddEven${key}`);
        });
    }

    createChart(chartId: string, data: number[], chartKey: string) {
        this.charts[chartKey] = new Chart(chartId, {
            type: "doughnut",
            data: {
                datasets: [
                    {
                        label: "Lottery Data",
                        data: data,
                        backgroundColor: ["#ff9416", "#D57300"],
                    },
                ],
            },
            options: {
                responsive: true,
                onResize: (chart, size) => {
                    if (size.width < 500) {
                        chart.options.aspectRatio = 1;
                    } else {
                        chart.options.aspectRatio = 2;
                    }
                    chart.update();
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number, context: any) => {
                            let sum = 0;
                            const dataArr = context.chart.data.datasets.data as any[];
                            if (dataArr) {
                                dataArr.forEach((data) => {
                                    if (typeof data === 'number') {
                                        sum += data;
                                    }
                                });
                            }
                            return value;
                        },
                        color: '#FFF',
                        align: 'center',
                    }
                },
                events: []
            },
            plugins: [ChartDataLabels]
        });
    }
}    
