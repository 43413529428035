import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { WalletService } from '../../_services/wallet.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { PaymentService } from '../../_services/payment.service';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-wallet',
    templateUrl: './wallet.component.html',
    styleUrls: ['./wallet.component.css'],
    template: `<button (click)="goBack()">Go Back</button>`
})
export class WalletComponent implements OnInit {
    walletBalance = "0"
    rechargeDenominations = [
        [100, 500, 1000, 2000, 5000],
        [10000, 15000, 20000, 300]
    ];
    selectedAmount = ""
    paymentMethods = [
        {
            method: 'Fast UPI',
            img: './../../assets/bhim_upi.jpeg',
            details: 'Min ₹500 Max ₹50000'
        },
        {
            method: 'SEP Pay',
            img: './../../assets/sep_pay.png',
            details: 'Min ₹500 Max ₹50000'
        },
    ];
    selectedMethod: string | null = null;
    activeButton: { row: number, col: number } | null = null;
    rechargeButtonEnabled = false

    constructor(private walletService: WalletService, private paymentService: PaymentService, private location: Location, private router: Router) { }

    ngOnInit(): void {
        this.getWalletInfo()

        const app = initializeApp(environment.firebaseConfig);
        const analytics = getAnalytics(app);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                logEvent(analytics, 'page_view', {
                    page_path: event.urlAfterRedirects,
                    page_title: document.title
                });
            }
        });
    }

    getWalletInfo() {
        this.walletService.getInfo().subscribe({
            next: data => {
                this.walletBalance = data.data.wallet.totalAmount
            },
            error: err => {
                console.log("Error" + err.message);
            }
        });
    }

    recharge() {
        if (this.selectedMethod !== null && this.selectedAmount !== '') {
            this.paymentService.getUrl(this.selectedAmount).subscribe({
                next: data => {
                    window.location.href = data.data.url
                },
                error: err => {
                }
            });
        }
    }

    handleClick(row: number, col: number, value: number) {
        this.handleActiveState(row, col)
        this.selectedAmount = this.rechargeDenominations[row][col].toString()
        this.enableOrDisableRechargeButton()
    }

    enableOrDisableRechargeButton() {
        if (this.checkSelectedAmount(this.selectedAmount) && this.selectedMethod !== null) {
            this.rechargeButtonEnabled = true
        } else {
            this.rechargeButtonEnabled = false
        }
    }

    checkInputValue() {
        this.activeButton = null
        this.enableOrDisableRechargeButton()
    }

    checkSelectedAmount(selectedAmount: String): boolean {
        const amount = Number(selectedAmount);
        return !isNaN(amount) && amount >= 50 && amount <= 50000;
    }

    private handleActiveState(row: number, col: number) {
        if (this.activeButton && this.activeButton.row === row && this.activeButton.col === col) {
            this.activeButton = null;
        } else {
            this.activeButton = { row, col };
        }
    }

    handleBackClick() {
        this.location.back()
    }

    selectMethod(method: string) {
        this.selectedMethod = method;
        this.enableOrDisableRechargeButton()
    }
}