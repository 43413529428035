<div class="flex flex-col justify-center  w-full" style="max-width:27rem;">
    <div class="flex space-x-4 h-12 p-2" style="background-color: #F7F9FF;">
        <button class="px-4 py-2 rounded-full text-white focus:outline-none "  style="background-color: #5f6975">1st-prize</button>
    </div>
    <div class="text-gray-700 flex items-center justify-center h-12 px-2 text-center space-x-2" style="background-color:#E7EAF4;">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        <div>Please select at least 1 number(s) for each digit. Max 100 bet slip count to be created each time.</div>
    </div>
    <div class="flex w-full justify-center items-center flex-col " style="background-color: rgb(223, 227, 237);">
        <div class="flex items-center p-2" style="background-color: rgb(223, 227, 237);" *ngFor="let data of colors; let i = index">
            <div class="flex flex-col items-center justify-center mr-2 " [ngStyle]="{'color': data.color}">
                <div class="font-bold text-lg ">{{data.label}}</div>
                <div class="rounded-full w-5 h-5 " [ngStyle]="{'background-color': data.color}"></div>
            </div>
            <div class="flex-1 flex items-center">
                <div class="grid grid-cols-5 grid-rows-2 gap-x-10 gap-y-2 ">
                    <ng-container *ngFor="let option of [0,1,2,3,4,5,6,7,8,9]">
                        <div class="flex items-center justify-center flex-col" (click)="onCheckboxChange(option, data.color, i, data.label)">
                            <div class="flex flex-col items-center border-2 rounded-full h-10 w-10 justify-center" 
                                [ngStyle]="{
                                    'border-color': data.color, 
                                    'background-color': isSelected(option, data.label) ? data.color : 'white'
                                }" 
                                style="box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px 0px, rgb(255, 255, 255) -4px -4px 8px 0px;">
                                <span class="font-bold text-sm text-main" [ngStyle]="{'color': isSelected(option, data.label) ? 'white' : 'black'}">{{option}}</span>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-between items-center p-2 h-16" style="background-color: #F7F9FF;">
        <div class="flex">
            <span>Bet Slip count:</span>
            <span>{{betSlipCount}}</span>
        </div>
          <!-- popup -->
      <div *ngIf="showWarningMessage" class="warning-dialog">
        <div class="dialog-container">
          <div class="dialog-icon">
            <mat-icon color="warn">error_outline</mat-icon>
          </div>
          <div class="dialog-message">
            <p>Sorry, the maximum number of bets you can place is 100</p>
          </div>
        </div>
      </div>

        <div class="flex gap-3 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" (click)="resetSelections()"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
            <button class="bg-red-600 h-11 w-36 text-white rounded-full" (click)="addToSlip()">Add to the slip</button>
        </div>
    </div>
  </div>
  