import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ball-game-results-tab',
  templateUrl: './ball-game-results-tab.component.html',
  styleUrls: ['./ball-game-results-tab.component.css']
})
export class BallGameResultsTabComponent implements OnInit {
  @Input() resultData: any[] = [];
  @Input() totalGames: number = 0;
  @Input() totalPages: number = 0;
  @Output() pageChange = new EventEmitter();
  itemsPerPage = 10

  constructor() { }

  ngOnInit(): void {

  }

  onPageChange(event: any) {
    this.pageChange.emit("")
  }
}
