<div class="grid gap-2 md:gap-4 px-1 py-2 grid-cols-4 top-contain">
  <button
    *ngFor="let button of buttons"
    [ngClass]="isButtonDisabled"
    class="flex flex-col px-3 py-2 items-center justify-center gap-1 bg-white rounded-xl"
    style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
    (click)="diceClicked(button)"
  >
    <!-- Center image and data with dynamic color -->
    <div
      class="bg-full dice-sum-big md:h-16 md:w-16 h-12 w-12 flex items-center justify-center"
      [style.backgroundImage]="'url(' + button.imageSrc + ')'"
      [ngStyle]="{ color: button.data ? button.color : 'transparent' }"
    >
      {{ button.data }}
    </div>
    <!-- Dynamic value binding -->
    <div class="bg-content">
      {{ button.value }}
    </div>
  </button>
</div>
