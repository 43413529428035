<div class="main w-full mx-auto pb-10">
  <div class="container mx-auto my-6" *ngFor="let order of orders">
    <div class="bg-white shadow-lg rounded-3xl p-6 betslip">
      <div class="text-black text-md text-center">Game ID : <span class="text-black font-bold">{{order.gameId |trimUppercase}}</span></div>
      <div class="flex justify-between items-center mt-4">
        <div class="text-black">Order ID : <span class="text-black font-semibold">{{order.betId |
            trimUppercase}}</span></div>
        <div class="flex flex-col mt-3">
          <span class="text-gray-400">Bet Amount</span>
          <span class="text-red-600 font-bold text-center">₹{{order.predictionCount*order.predictionAmount}}</span>
        </div>
      </div>
      <div class="text-gray-900 mt-2">
        <span class="text-gray-400">Betting time : </span> {{ order.created_at | date: 'yyyy-MM-dd h:mm a' }}
      </div>
      <hr class="divider" />

      <div class="mt-6">
        <div class="flex justify-between items-center">
          <div class="text-md font-semibold">Game Type</div>
          <div class="text-sm text-gray-500">{{order.predictionType| capitalize}}</div>
          <div class="text-md font-semibold">Game Status:</div>
          <div class="text-sm text-gray-500">{{ order.status | capitalize }}</div>
        </div>

        <div class="flex justify-between items-center">
        </div>
        <hr class="divider" />
        <ng-container *ngIf="order.status =='Won'">
          <div class="flex justify-between items-center py-1 mt-3 px-3 rounded-2xl bg-gradient-to-l from-green-400">
            <span class="text-base">{{ order.gameDetails.completedTime | roundTime}}</span>
            <span class="text-lg font-semibold text-green-600">won</span>
          </div>
        </ng-container>
      </div>
      <div class="rounded px-2 items-center py-1 flex mt-6 bg-gray-100" *ngIf="order.status !== 'Pending'">
        Drawn Results
        <div class="text-gray-500 flex items-center h-9 gap-2 w-full rounded-2xl justify-center relative">
          <div class="image-container">
            <img [src]="'assets/images/' + getImageForWinColor(order.gameDetails.winColor, order.gameDetails.winNumber)"
              alt="Win Color" class="win-color-image h-8 w-8" />
            <span class="win-number-overlay">{{ order.gameDetails.winNumber }}</span>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <div
          class="text-gray-500 flex items-center h-9 gap-2 w-full rounded-2xl bg-gradient-to-l from-pink-100 py-3 px-2">
          <div class="image-container">
            <img [src]="'assets/images/' + getImageForWinColor(order.predictionColor, order.predictionNumber)"
              alt="Win Color" class="win-color-image h-8 w-8" />
            <span class="win-number-overlay">{{ order.predictionNumber }}</span>
          </div>
          <div class="text-black">Bet</div>
          <div class="flex flex-1 justify-between items-center">
            <div class="text-black font-semibold">₹{{order.predictionCount*order.predictionAmount}}</div>
            <div class="text-red-600 font-bold" *ngIf="order.status !== 'Pending'">Won ₹{{order.wonAmount}}</div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="order.wonAmount > 0">
        <div class="bg-green-600 text-white text-center font-bold py-4 mt-4 rounded-lg ">
          Congratulations, You have won! Total Prize Amount ₹{{order.wonAmount}}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="paginator-container flex justify-center">
    <mat-paginator [length]="totalItems" class="bg-transparent" [pageSize]="itemsPerPage"
      (page)="onPageChange($event)" aria-label="Select page">
    </mat-paginator>
  </div>
</div>