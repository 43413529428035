import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserSessionStorageService {
    private spinKey = 'hasSpunWheel';
    private luckyWheelPromoKey = 'luckyWheelPromo';

    constructor() { }

    hasUserSpun(): boolean {
        const hasSpun = sessionStorage.getItem(this.spinKey);
        return hasSpun === 'true';
    }

    markUserAsSpun(): void {
        sessionStorage.setItem(this.spinKey, 'true');
    }

    resetSpinStatus(): void {
        sessionStorage.removeItem(this.spinKey);
    }

    saveLuckyWheelPromoCode(promo: string): void {
        sessionStorage.setItem(this.luckyWheelPromoKey, promo);
    }

    getLuckWheelPromo(): string {
        return sessionStorage.getItem(this.luckyWheelPromoKey) || '';
    }

    clearLuckyWheelPromo() :void {
        sessionStorage.removeItem(this.luckyWheelPromoKey)
    }
}
