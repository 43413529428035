import { Component, OnInit } from '@angular/core';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment'; // Firebase config

@Component({
  selector: 'app-home-game-tabs',
  templateUrl: './home-game-tabs.component.html',
  styleUrls: ['./home-game-tabs.component.css']
})
export class HomeGameTabsComponent implements OnInit {

  private analytics: any;

  constructor() {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void {}

  scrollTo(event: any): void {
    const tabLabel = event.tab.textLabel.toLowerCase().replace(' ', '');
    
    const element = document.getElementById(tabLabel);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    logEvent(this.analytics, 'home_games_tab_selection', {
      tab_name: event.tab.textLabel,
      source_page: window.location.pathname
    });

    console.log(`Tab selected: ${event.tab.textLabel}`);
  }
}
