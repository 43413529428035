<div class="main w-full mx-auto pb-10">
  <div class="container mx-auto my-6" *ngFor="let order of orders">
    <div class="bg-white shadow-lg mx-2 rounded-3xl p-6 betslip">
      <div class="text-black text-md text-center">Game ID : <span class="text-black font-bold">{{order.gameId |trimUppercase}}</span></div>
      <div class="flex justify-between items-center">
        <div class="text-gray-500">Order ID : <span class="text-black font-semibold">{{order.betId |
            trimUppercase}}</span></div>
        <div class="flex flex-col mt-3">
          <span class="text-gray-400">Bet Amount</span>
          <span class="text-red-600 font-bold text-center">₹{{order.predictionCount*order.predictionAmount}}</span>
        </div>
      </div>
      <div class="text-gray-900 mt-2">
        <span class="text-gray-400">Betting time : </span> {{ order.created_at | date: 'yyyy-MM-dd h:mm a' }}
      </div>
      <hr class="divider" />

      <div class="mt-6">
        <div class="flex justify-between items-center">
          <div class="text-md font-semibold">Game Type</div>
          <div class="text-sm text-gray-500">{{order.predictionType| capitalize}}</div>
          <div class="text-md font-semibold">Game Status:</div>
          <div class="text-sm text-gray-500">{{ order.status | capitalize }}</div>
        </div>

        <div class="flex justify-between items-center">
        </div>
        <hr class="divider" />
        <ng-container *ngIf="order.status =='Won'">
          <div class="flex justify-between items-center py-1 px-3 rounded-2xl bg-gradient-to-l from-green-400">
            <span class="text-base">{{ order.diceDetails.created_at | roundTime}}</span>
            <span class="text-lg font-semibold text-green-600">won</span>
          </div>
        </ng-container>
      </div>

      <div class="rounded px-2 items-center py-1 flex mt-6 bg-gray-100" *ngIf="order.status!='Pending'">
        Drawn Results
        <div class="text-gray-500 flex items-center h-9 gap-2 w-full rounded-2xl justify-end">
          <div *ngIf="getDiceImage(order, 'firstDiceNumber') as firstDiceImage"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + firstDiceImage?.imageSrc + ')'"
            [ngStyle]="{ color: firstDiceImage?.data ? firstDiceImage?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ firstDiceImage?.data }}</span>
          </div>
          <div *ngIf="getDiceImage(order, 'secondDiceNumber') as secondDiceImage"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + secondDiceImage?.imageSrc + ')'"
            [ngStyle]="{ color: secondDiceImage?.data ? secondDiceImage?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ secondDiceImage?.data }}</span>
          </div>
          <div *ngIf="getDiceImage(order, 'thirdDiceNumber') as thirdDiceImage"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + thirdDiceImage?.imageSrc + ')'"
            [ngStyle]="{ color: thirdDiceImage?.data ? thirdDiceImage?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ thirdDiceImage?.data }}</span>
          </div>

          <div *ngIf="getBigOrSmall(order) as bigOrSmall"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + bigOrSmall?.imageSrc + ')'"
            [ngStyle]="{ color: bigOrSmall?.data ? bigOrSmall?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ bigOrSmall?.data }}</span>
          </div>

          <div *ngIf="getEvenOrOdd(order) as evenOrOdd"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + evenOrOdd?.imageSrc + ')'"
            [ngStyle]="{ color: evenOrOdd?.data ? evenOrOdd?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ evenOrOdd?.data }}</span>
          </div>

          <div *ngIf="getTotal(order) as total"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + total?.imageSrc + ')'"
            [ngStyle]="{ color: total?.data ? total?.color : 'transparent' }">
            <span class="font-semibold my-auto mb-1">{{ total?.data }}</span>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <div
          class="text-gray-500 flex items-center h-9 gap-2 w-full rounded-2xl bg-gradient-to-l from-pink-100 py-3 px-2">
          <div *ngIf="getOrderImages(order) as orderImage"
            class="bg-full dice-sum-big h-7 w-7 flex text-xs items-center font-bold justify-center"
            [style.backgroundImage]="'url(' + orderImage?.imageSrc + ')'" [ngStyle]="{
                    color: orderImage?.data ? orderImage?.color : 'transparent'
                  }">
            <span class="font-semibold my-auto mb-1">{{ orderImage?.data }}</span>
          </div>
          <div class="text-black">Bet</div>
          <div class="flex flex-1 justify-between items-center">
            <div class="text-black font-semibold">₹{{order.predictionCount*order.predictionAmount}}</div>
            <div class="text-red-600 font-bold" *ngIf="order.status!='Pending'">Won ₹{{order.wonAmount}}</div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="order.wonAmount > 0">
        <div class="bg-green-600 text-white text-center font-bold py-4 mt-4 rounded-lg ">
          Congratulations, You have won! Total Prize Amount ₹{{order.wonAmount}}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="paginator-container flex justify-center ">
    <mat-paginator class="bg-transparent" [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="[2, 5, 10, 25, 100]"
      (page)="onPageChange($event)" aria-label="Select page">
    </mat-paginator>
  </div>
</div>