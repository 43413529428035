<div class="main-container">
    <mat-tab-group class="custom-tab-group" (selectedTabChange)="scrollTo($event)" #tabGroup>
      <mat-tab label="Dice"></mat-tab>
      <mat-tab label="Color"></mat-tab>
      <mat-tab label="Instant Lottery"></mat-tab>
      <mat-tab label="State Lottery"></mat-tab>
    </mat-tab-group>
  
    <div class="content-container" #contentContainer>
      <section id="dice" #diceSection>
        <app-dice-game></app-dice-game>
      </section>
      <section id="color" #colorSection>
        <app-color-game></app-color-game>
      </section>
      <section id="instantlottery" #instantLotterySection>
        <app-home-instant-lottery></app-home-instant-lottery>
      </section>
      <section id="statelottery" #stateLotterySection>
        <app-lottery-game></app-lottery-game>
      </section>
    </div>
  </div>
  