import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-play-popup',
  templateUrl: './play-popup.component.html',
  styleUrls: ['./play-popup.component.css']
})
export class PlayPopupComponent implements OnInit {

  constructor( private dialog:MatDialog) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialog.closeAll()
  }
}
