import { Component, OnInit } from '@angular/core';
import { LotteryService } from '../_services/lottery.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { WalletService } from '../_services/wallet.service';
import { forkJoin } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { BallGameService } from '../_services/ball.game.service';

@Component({
  selector: 'app-bet-results',
  templateUrl: './bet-results.component.html',
  styleUrls: ['./bet-results.component.css']
})
export class BetResultsComponent implements OnInit {
  resultData: any
  isLoggedIn = false
  totalWalletAmount = 0
  selctedConfigIndex = 0;
  numbersArray: number[] = Array.from({ length: 10 }, (_, i) => i);
  currentPage: number = 1;
  totalPages: number = 1;
  showLoader = false
  lotteries: any = {}
  loadedTabs: boolean[] = [true, false];
  loadedLotteryTabs: boolean[] = [];

  constructor(private lotteryService: LotteryService,
    private ballGameService: BallGameService,
    private tokenStorageService: TokenStorageService,
    private walletService: WalletService,
    private router: Router) { }


  ngOnInit(): void {
    this.selctedConfigIndex = 1
    this.getLatest(this.selctedConfigIndex, 1)
    if (this.tokenStorageService.isLoggedIn()) {
      this.isLoggedIn = true
      this.getWalletInfo();
    }

    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  loadLotteries(): void {
    forkJoin({
      instantLotteries: this.lotteryService.getActiveInstantLotteries(),
      activeLotteries: this.lotteryService.getActive()
    }).subscribe({
      next: (response) => {
        this.lotteries = [...response.instantLotteries.data, ...response.activeLotteries.data];
        this.loadedLotteryTabs = new Array(this.lotteries.length).fill(false);
      },
      error: (error) => {
        console.error('Error fetching lotteries:', error);
      }
    });
  }

  getWalletInfo() {
    this.walletService.getInfo().subscribe({
      next: data => {
        this.totalWalletAmount = data.data.wallet.totalAmount
      },
      error: err => {

      }
    })
  }

  handleBackClick() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  getLatest(configId: number, page: number) {
    this.showLoader = true
    this.ballGameService.getLatest(configId, 10, page).subscribe({
      next: data => {
        this.showLoader = false
        this.totalPages = data.totalPages
        this.resultData = data.data
      },
      error: err => {
        this.showLoader = false
      }
    });
  }

  handleTabClick(config: number, index: number): void {
    this.getLatest(config, 1)
    this.selctedConfigIndex = index;
  }


  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getLatest(this.selctedConfigIndex, this.currentPage)
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getLatest(this.selctedConfigIndex, this.currentPage)
    }
  }

  onTabChange(event: any): void {
    const index = event.index;
    if (!this.loadedTabs[index]) {
      this.loadedTabs[index] = true;

      if (index > 1) {
        this.loadLotteries();
      }
    }
  }

  onLotteryTabChange(event: any): void {
    const index = event.index;
    if (!this.loadedLotteryTabs[index]) {
      this.loadedLotteryTabs[index] = true; // Mark the nested lottery tab as loaded when clicked
    }
  }
}
