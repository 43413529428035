<div class="game-tab-container">
    <div class="header">
        <img src="../../../assets/color-game-head.webp" />
        <div>Color</div>
      </div>
    <div class="horizontal-list">
        <div class="item" *ngFor="let image of images" (click)="onImageClick(image)">
            <img [src]="image.src" [alt]="image.alt">
        </div>
    </div>
</div>