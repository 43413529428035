<div>
  <div class="header">
    <h4>Bet</h4>
    <button mat-icon-button (click)="close(0, '')">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="selected-bet">
    <!-- Check if selectedBetClass and betNumberOrColor are defined -->
    <div class="bet-identify selected-ball" [class]="selectedBetClass" *ngIf="betNumberOrColor">
      <span class="text">{{betNumberOrColor}}</span>
    </div>
    
    <!-- Check if button is defined before accessing its properties -->
    <div class="bg-full dice-sum-big md:h-16 md:w-16 font-extrabold text-lg h-12 w-12 flex items-center justify-center"
         *ngIf="button && button.sourceComponent=='sum' || button && button.sourceComponent=='single'"  
         [style.backgroundImage]="'url(' + button.imageSrc + ')'"
         [ngStyle]="{'color': button.data ? button.color : 'transparent'}">
      {{ button.data }}
    </div>
    <div class="flex flex-col" *ngIf="button && button.sourceComponent=='tripple'">
      <div class="flex items-center justify-center">
        <div class="bg-full  w-7 h-7 md:w-10 md:h-10 dice-sum-big"[style.backgroundImage]="'url(' + button.imageSrc + ')'"></div>
      </div>
      <div class="flex flex-row">
        <div class="bg-full  w-7 h-7 md:w-10 md:h-10 dice-sum-big"[style.backgroundImage]="'url(' + button.imageSrc + ')'"></div>
        <div class="bg-full  w-7 h-7 md:w-10 md:h-10 dice-sum-big"[style.backgroundImage]="'url(' + button.imageSrc + ')'"></div>
      </div>
    </div>  
    <div class="md:h-20 md:w-20 h-12 w-12 flex items-center justify-center" *ngIf="button && button.sourceComponent=='double'">
      <div class="flex flex-row">
            <div class="bg-full  w-9 h-9 md:w-10 md:h-10 dice-sum-big"[style.backgroundImage]="'url(' + button.imageSrc + ')'"></div>
            <div class="bg-full  w-9 h-9 md:w-10 md:h-10 dice-sum-big"[style.backgroundImage]="'url(' + button.imageSrc + ')'"></div>
      </div>              
    </div>            
  </div>
  <div class="topup-container">
    <table class="table table-borderless">
      <tr class="button-row">
        <td *ngFor="let value of amountToBet; let colIndex = index">
          <button [class.active]="betAmountActiveButton === colIndex" class="topup-{{value}} btn-topup"
            (click)="handleAmountClick(colIndex)">₹{{value}}</button>
        </td>
      </tr>
    </table>
  </div>
  <div class="bet-count bg-gray-50 p-2">
    <div class="flex justify-between items-center">
      <label class="font-semibold text-gray-700 mr-4 md:text-xl text-lg ">Multiplier</label>
    <div class="flex items-center" *ngIf="button">
      <button class="w-10 h-10 flex items-center justify-center rounded bg-gray-100 hover:bg-gray-200 text-gray-500 mr-2"
              (click)="handleMultiplierChange(multiplier > 1 ? multiplier - 1 : 1)">
        -
      </button>
      <input type="number"
             class="w-24 h-10 text-center border border-gray-300 rounded-md focus:outline-none"
             [(ngModel)]="multiplier"
             (change)="handleMultiplierChange(multiplier)">
      <button class="w-10 h-10 flex items-center justify-center rounded bg-gray-100 hover:bg-gray-200 text-gray-500 ml-2"
              (click)="handleMultiplierChange(multiplier + 1)">
        +
      </button>
    </div>
    </div>
    <ul class="button-row">
      <li *ngFor="let value of betCount; let colIndex = index">
        <button [class.active]="betCountActiveButton === colIndex" class="count-{{value}} btn-bet-count"
          (click)="handleBetCountClick(colIndex)">x{{value}}</button>
      </li>
    </ul>
  </div>
  <div class="agree-section">
    <form>
      <div class="agreement">
        <label>
          <input type="checkbox" [(ngModel)]="enable" name="agreement"> I agree <span (click)="presalePopup()">(pre-sale
            rules)</span>
        </label>
      </div>
      <div class="submit-bet">
        <button class="submit-button" (click)="handleBetPlaceClick()" [disabled]="!enable || isLoading">
          <div class="loader" *ngIf="isLoading"></div>
          <span *ngIf="!isLoading">Total Price ₹{{totalBetPrice}}</span>
        </button>
      </div>
    </form>
  </div>
</div>
