<div class="max-w-full w-11/12 mx-auto ">
  <!-- 2x3 Grid for Dice Progress Bars -->
  <div class="grid grid-cols-2 mt-2 w-11/12 mx-auto gap-x-1 gap-y-5">
    <div class=" flex justify-center w-full" *ngFor="let dice of selectedDiceData">
      <!-- Dice Icon -->
      <div class="icon-container mt-3">
        <img [src]="'assets/icons/' + dice.icon" alt="Dice Icon" class="dice-icon">
      </div>
      
      <!-- Progress Bar -->
      <div class="flex flex-col h-14 justify-center items-center ">
        <!-- Percentage text -->
        <span class="relative top-3 font-semibold text-gray-600">{{ dice.value.toFixed(2) }}%</span>
        <div class="w-full">
          <canvas baseChart 
          class="dice"
          [data]="getChartData(dice.value)"
          [options]="getChartOptions()"
          chartType="bar">
          </canvas> 
        </div>
      </div>
    </div>
  </div>

  <!-- Summary Bar for SMALL vs BIG -->
  <div class="summary-bar px-3">
    <div class="summary-labels">
      <span class="small-label text-base">SMALL</span>
      <span class="big-label text-base">BIG</span>
    </div>
    <span class="relative top-8 left-12  text-white">{{ProgressValue['small']}}%</span>
    <span class="relative top-8 left-48 text-white">{{ProgressValue['big']}}%</span>
    <canvas baseChart
            [data]="sumChartData"
            [options]="sumChartOptions"
            chartType="bar">
    </canvas>
  </div>
  <div class="px-3">
    <div class="summary-labels">
      <span class="small-label text-base">ODD</span>
      <span class="big-label text-base">EVEN</span>
    </div>
    <span class="relative top-8 left-12 text-white">{{ProgressValue['odd']}}%</span>
    <span class="relative top-8 left-48 text-white">{{ProgressValue['even']}}%</span>
    <canvas baseChart
            [data]="sumChartData"
            [options]="sumChartOptions"
            chartType="bar">
    </canvas>
  </div>
  <div class="mb-10"></div>
</div>
