import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dice-game',
  templateUrl: './dice-game.component.html',
  styleUrls: ['./dice-game.component.css']
})
export class HomeDiceGameComponent implements OnInit {

  private analytics: any;

  images = [
    { src: '../../../assets/dice-1-min.webp', alt: 'Dice 1', configId: '1' },
    { src: '../../../assets/dice-3-min.webp', alt: 'Dice 3', configId: '3' },
    { src: '../../../assets/dice-5-min.webp', alt: 'Dice 5', configId: '5' },
  ];

  constructor(private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void { }

  onImageClick(image: any) {
    this.router.navigate(['/dice-game'], { queryParams: { configId: image.configId } });

    logEvent(this.analytics, 'home_dice_game_image_click', {
      content_type: 'image',
      item_name: image.alt,  
      config_id: image.configId,
      source_page: this.router.url.split('?')[0]
    });

    console.log(`Image clicked: ${image.alt}, configId: ${image.configId}`);
  }
}
