<!-- lottery-game.component.html -->
<div class="game-tab-container">
    <div class="header">
        <img src="../../../assets/lottery-game-head.webp" />
        <div>State Lottery</div>
    </div>
    <div class="vertical-list">
        <div class="item" *ngFor="let image of images" (click)="onImageClick(image)" [style.background]="image.bgColor">
            <div class="lottery-item flex-col">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 124 104" class="absolute-svg"
                    style="width: 6.5rem;">
                    <g filter="url(#a)" opacity="0.7">
                        <circle cx="56.166" cy="36" r="32" fill="#fff"></circle>
                    </g>
                    <defs>
                        <filter id="a" width="132.571" height="132.571" x="-10.119" y="-30.286"
                            color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                            <feGaussianBlur result="effect1_foregroundBlur_12267_20305" stdDeviation="17.143">
                            </feGaussianBlur>
                        </filter>
                    </defs>
                </svg>
                <img [src]="image.src" [alt]="image.alt">
                <div class="win-amount font-din">{{image.amount}}</div>
                <div class="jackpot-label font-din">{{image.label}}</div>
                <div class="next-draw font-diff">Next Draw</div>
                <div class="next-draw-date font-other">{{image.nextDraw}}</div>
            </div>
        </div>
    </div>
</div>
<div class="mb-10"></div>