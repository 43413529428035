import { Component, OnInit } from '@angular/core';
import { WalletService } from '../_services/wallet.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BankService } from '../_services/bank.service';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {
  amount?: number;
  accountNumber?: any = null;
  ifscCode?: any;
  name?: any;
  upiId?: any;
  confirmAccountNumber?: any;
  bankCodes: any[] = [];
  selectedBankCode?: any;
  bankAccounts: any[] = [];
  selectedBankAccount: any = null;

  ngOnInit(): void {
    this.fetchListOfBanks()
    this.getBankAccounts()
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  getBankAccounts(): void {
    this.bankServices.getBankAccounts().subscribe({
      next: data => {
        this.bankAccounts = data.data;
      }, error: err => {
      }
    })
  }

  fetchListOfBanks() {
    this.bankServices.listBanks().subscribe({
      next: data => {
        this.bankCodes = data.data
      }, error: err => {
      }
    })
  }

  constructor(private walletService: WalletService,
    private bankServices: BankService,
    private location: Location,
    private router: Router,
    private _snackbar: MatSnackBar
  ) { } // Inject your API service

  payout() {
    const withdrawData = {
      amountToTransfer: this.amount,
      receiverName: this.name,
      bankCode: this.selectedBankCode,
      upiId: this.upiId,
      accountNumber: this.accountNumber,
      ifscCode: this.ifscCode,
    };

    this.walletService.withdrawBalance(withdrawData).subscribe({
      next: data => {
        this.location.back()
        this._snackbar.open("Withdrawal request submitted", 'Close', {
          duration: 3000
        });
      },
      error: err => {
        this._snackbar.open(err.error.message, 'Close', {
          duration: 4000
        });
      }
    })
  }

  onSelectBankAccount(): void {
    if (this.selectedBankAccount) {
      this.accountNumber = this.selectedBankAccount.accountNumber;
      this.confirmAccountNumber = this.selectedBankAccount.accountNumber;
      this.name = this.selectedBankAccount.receiverName;
      this.upiId = this.selectedBankAccount.upiId;
      this.ifscCode = this.selectedBankAccount.ifscCode;
      this.selectedBankCode = this.selectedBankAccount.bankCode;
    } else {
      this.resetForm()
    }
  }

  resetForm(): void {
    this.name = null;
    this.accountNumber = null;
    this.confirmAccountNumber = null;
    this.ifscCode = null;
    this.upiId = null;
    this.selectedBankCode = null;
  }

  removeBankAccountSelection(): void {
    this.selectedBankAccount = null;
    this.resetForm();
  }

  clearBankSelection() {
    this.selectedBankAccount = null;
  }

  handleBackClick() {
    this.location.back()
  }
}
