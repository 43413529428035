import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lottery-game',
  templateUrl: './lottery-game.component.html',
  styleUrls: ['./lottery-game.component.css']
})
export class HomeLotteryGameComponent implements OnInit {
  images: any[] = [];
  private analytics: any;

  constructor(private router: Router, private imageService: DiceBetImageService) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void {
    console.log()
    this.imageService.getUpdatedLotteryImages().subscribe(updatedImages => {
      this.images = updatedImages;
    });
  }

  onImageClick(image: any) {
    this.router.navigate(['lottery-game', image.name]);

    logEvent(this.analytics, 'home_lottery_game_click', {
      lottery_name: image.name, 
      source_page: window.location.pathname
    });
  }
}
