import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GamesBottomSheetComponent } from '../games-bottom-sheet/games-bottom-sheet.component';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LotteryService } from 'src/app/_services/lottery.service';
import { SelectedOptionService } from 'src/app/_services/selectedOption.service';
import { GameTypeSelect, LotteryType } from 'src/app/model/gameType.interface';
import { Subscription, take, timer } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { RulesPopupComponent } from './rules-popup/rules-popup.component';
import { WalletService } from 'src/app/_services/wallet.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { Location } from '@angular/common';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { MyOrdersComponent } from '../my-orders/my-orders.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottery-games',
  templateUrl: './lottery-games.component.html',
  styleUrls: ['./lottery-games.component.css']
})
export class LotteryGamesComponent implements OnInit {
  totalWalletAmount = 0
  isLoggedIn = true
  isPopupVisible = false;
  image: any = {}
  selectedTab = 0;
  selectedBottomTab = 0
  lotteryTypes: LotteryType[] = [];
  bottomSheetVisible: boolean = false;
  lotteryData: any = {}
  selectedGame: any[] = []
  count = 1
  draw: any
  countDownSubscription: any;
  counter: number = 0;  // Countdown timer
  dupliacteCombination: any[] = []
  nonDuplicateCombination: any[] = []
  duplicates: any[] = []
  noDuplicates: any[] = []
  clearSelection = false;
  timerInterval: any;
  countdownData: any;
  lotteryId: any
  showWarningMessage: boolean = false
  authentication: boolean = false
  isLoading = false;
  lotteryName: any = ""
  showWonStatusDialog = false
  blocked = false
  minRemainingTimeToComplete = ""
  wonAmount = 0
  countdownStart = false
  coundDownAudio = new Audio();
  winAudio = new Audio();
  routerSubscription?: Subscription;
  progressBar = false
  betPlaced = false
  @ViewChild('orderTab') orderTabComponent?: MyOrdersComponent;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private lotteryService: LotteryService,
    private selectedOption: SelectedOptionService,
    public dialog: MatDialog,
    private walletService: WalletService,
    private tokenStorageService: TokenStorageService,
    private authservice: AuthService,
    private router: Router,
    private imageService: DiceBetImageService,
    private location: Location
  ) { }

  colors: string[] = ['#9ca3af', '#9ca3af', '#D57300', '#0087D4', '#D50000', '#67A519'];
  images: any[] = [];
  options: AnimationOptions = {
    path: '../../../assets/countdown.json',
    loop: false,
    autoplay: true
  };
  optionsForWin1: AnimationOptions = {
    path: '../../../assets/W4n40kZKLQ.json',
    loop: true,
    autoplay: true
  };

  optionsForWin2: AnimationOptions = {
    path: '../../../assets/WvyeTtaxax.json',
    loop: false,
    autoplay: true
  };

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: Event) {
    if (!document.hidden) {
      this.getNearestLottery()
    }
  }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
    this.coundDownAudio.src = '../../../assets/count_down.mp3';
    this.coundDownAudio.load();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.stopCountDownAudio()
        this.stopWinAudio()
      }
    });
    this.images = this.imageService.getAllLotteryImages()
    this.route.paramMap.subscribe(params => {
      this.lotteryName = params.get('name')
      if (this.lotteryName) {
        this.getNearestLottery();
        this.image = this.images.find(data => data.name == this.lotteryName);
      }
    });

    if (this.tokenStorageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.getWalletInfo();
    }

    this.selectedOption.selectedOption$.subscribe((data: any) => {
      if (data) {
        const index = this.selectedGame.findIndex(item => item.id === data.id);
        if (index === -1) {
          this.selectedGame.unshift({
            id: data.id,
            value: data.value,
            color: data.color,
            selectedTab: data.selectedTab,
            count: this.count++,
            index: data.index,
            betAmount: this.getDefBetAmount(),
          });
        } else {
          this.selectedGame.splice(index, 1);
          this.count--;
        }
      }
    });

    this.lotteryService.getLotteryTypes().subscribe({
      next: (data: any) => {
        this.lotteryTypes = data.data.map((lotteryType: any) => ({
          label: lotteryType.label,
          defAmount: lotteryType.defAmount,
          minAmount: lotteryType.minAmount,
          maxAmount: lotteryType.maxAmount,
        }));
      }
    });

    this.selectedOption.currentCombinations.subscribe({
      next: (data: any) => {
        this.duplicates = [];
        this.noDuplicates = [];

        data.forEach((element: any) => {
          const index = this.selectedGame.findIndex(item => item.id === element.id);
          if (index === -1) {
            this.noDuplicates.push(element);
          } else {
            this.duplicates.push(element);
            this.dupliacteCombination = this.duplicates;  // Track duplicate combinations
          }
        });

        if (this.noDuplicates.length > 0 && this.duplicates.length > 0) {
          this.nonDuplicateCombination = this.noDuplicates;
        } else if (this.noDuplicates.length > 0) {
          this.noDuplicates.forEach(selection => {
            this.selectedGame.unshift({
              id: selection.id,
              combination: selection.combination,
              color: selection.colors,
              selectedTab: selection.selectedTab,
              count: this.count++,
              betAmount: this.getDefBetAmount(),
            });
          });
          // Clear the selection after adding to the cart
          this.clearSelection = true;
          setTimeout(() => {
            this.clearSelection = false;
          }, 100);
        }
      }
    });
  }

  onTabClick(index: number): void {
    if (index === 2) {  // Index for "My Orders" tab
      if (!this.authservice.checkLoggedIn()) {
        this.router.navigate(['/login']);
        return;
      }
    }
    this.selectedBottomTab = index;
  }
  getLotteryDetails(lotteryId: string): void {
    this.lotteryService.getLotteryDetails(lotteryId).subscribe({
      next: (response: any) => {
        this.lotteryData = response.data;
        // this.gameId = this.lotteryData.code;
        this.counter = this.calculateCountdown(this.lotteryData.gameEndTimeIST);
        this.draw = this.lotteryData.gameEndTimeIST.split(' ')[0].replace(',', '')
        this.startCountdown(this.counter);
      },
      error: (err) => {
        console.error("Error retrieving lottery details: " + err.message);
      }
    });
  }
  // Method to calculate the countdown in seconds
  calculateCountdown(endTime: string): number {
    const endDate = new Date(endTime).getTime();
    const currentDate = new Date().getTime();
    const timeDiff = endDate - currentDate;

    return Math.max(0, Math.floor(timeDiff / 1000));  // Countdown in seconds
  }

  // Start countdown and handle events when countdown reaches zero
  startCountdown(seconds: number): void {
    if (this.countDownSubscription) {
      this.countDownSubscription.unsubscribe();
    }

    this.countDownSubscription = timer(0, 1000).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
        this.updateCountdownDisplay(this.counter);
        const uiBlockTimeInSeconds = this.lotteryData.instantLottery
          ? this.lotteryData.minRemainingTime
          : this.lotteryData.minRemainingTime * 60;
        this.minRemainingTimeToComplete = this.lotteryData.instantLottery ? this.lotteryData.minRemainingTime + " Seconds"
          : this.lotteryData.minRemainingTime + " Minutes"

        if (this.counter <= uiBlockTimeInSeconds) {
          this._bottomSheet?.dismiss()
          // reset betts
          this.count = 1;
          this.selectedGame = []
          this.blocked = true;
        }
      } else {
        this.playCountdownAudio()
        this.countdownStart = true
        this.blocked = false;

        setTimeout(() => {
          if (this.authservice.checkLoggedIn()) {
            this.getWinStatus()
          }
          this.getNearestLottery();
          this.countdownStart = false
          this.countDownSubscription.unsubscribe();
        }, 3000);
      }
    });
  }

  playCountdownAudio() {
    if (!this.coundDownAudio.paused) {
      return;
    }
    if (!document.hidden) {
      this.coundDownAudio.play()
    }
  }

  stopCountDownAudio() {
    this.coundDownAudio.pause();
    this.coundDownAudio.currentTime = 0;
  }

  ngOnDestroy() {
    this.stopCountDownAudio()
    this.stopWinAudio()
    this.routerSubscription?.unsubscribe()
    this.countDownSubscription?.unsubscribe();
  }

  getWinStatus(): void {
    this.lotteryService.getLotteryWinStatus(this.lotteryData._id).subscribe({
      next: (response: any) => {
        if (this.selectedBottomTab == 2) {
          this.orderTabComponent?.getMyOrders()
        }
        if (response.totalWinAmount > 0) {
          this.stopCountDownAudio()
          this.playWinAudio()
          this.getWalletInfo()
          this.showWonStatusDialog = true
          this.wonAmount = response.totalWinAmount
          const countdownTimer = timer(1000, 1000).pipe(take(5 + 1));
          countdownTimer.subscribe({
            complete: () => {
              this.wonAmount = 0
              this.showWonStatusDialog = false
              this.stopWinAudio()
            }
          });
        }
      }, error: (err) => {
        console.error(err)
      }
    })
  }

  playWinAudio() {
    if (!this.winAudio.paused) {
      return;
    }
    if (!document.hidden) {
      this.winAudio.play();
    }
  }

  stopWinAudio() {
    this.winAudio.pause();
    this.winAudio.currentTime = 0;
  }

  // Fetch the next nearest lottery details when the timer ends
  getNearestLottery(): void {
    this.progressBar = true
    const gameName = this.lotteryName
    this.lotteryService.getNextLottery(gameName).subscribe({
      next: (response: any) => {
        this.lotteryData = response.data;
        this.lotteryId = this.lotteryData._id
        this.counter = this.calculateCountdown(this.lotteryData.gameEndTimeIST);
        this.draw = this.lotteryData.gameEndTimeIST.split(' ')[0].replace(',', '');
        this.startCountdown(this.counter);
        this.progressBar = false
      },
      error: (err) => {
        this.progressBar = false
        console.error("Error fetching nearest lottery: " + err.message);
      }
    });
  }

  updateCountdownDisplay(counter: number): void {
    const days = Math.floor(counter / (60 * 60 * 24));
    const hours = Math.floor((counter % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((counter % (60 * 60)) / 60);
    const seconds = counter % 60;

    this.countdownData = {
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  }

  togglePopup(): void {
    const bottomSheetRef = this._bottomSheet.open(GamesBottomSheetComponent, {
      data: { selectedLotteryId: this.lotteryId, instantLottery: this.lotteryData.instantLottery }
    });

    bottomSheetRef.instance.lotterySelected.subscribe((lotteryName: string) => {
      this.lotteryName = lotteryName;
      this.getNearestLottery();
    });
  }
  loadLotteryDetails() {
    this.image = this.images.find((data) => data.id == this.lotteryId);
  }
  showCart(): void {
    this.bottomSheetVisible = !this.bottomSheetVisible;
  }

  payNow(event: Event): void {
    event.stopPropagation();

    let user = this.tokenStorageService.getUser();
    if (!user) {
      this.router.navigate(['/login']);
    } else {
      this.isLoading = true;
      this.checkWalletInfoAndProceed();
    }
  }

  checkWalletInfoAndProceed(): void {
    this.walletService.getInfo().subscribe({
      next: data => {
        let totalBetAmount = this.getTotalAmount();
        if (totalBetAmount > 0) {
          if (data.data.wallet.totalAmount >= totalBetAmount) {
            this.placeBet();
          } else {
            this.router.navigate(['/wallet']);
          }
        } else {
          console.warn('No bets selected.');
          this.isLoading = false;
        }
      },
      error: err => {
        console.error("Error: " + err.message);
      }
    });
  }

  placeBet(): void {
    const payload: any = {
      gameId: this.lotteryId,
      predictionFor: 'FirstPrize',
      predictionType: []
    };

    // Iterate over selectedGame and dynamically create arrays in the payload if needed
    this.selectedGame.forEach((game) => {
      const bet = {
        prediction: game.value || game.combination?.join(''),
        betAmount: game.betAmount
      };

      // Dynamically detect the type and populate the payload accordingly
      switch (game.selectedTab) {
        case 'TwoSide':
          // Initialize arrays if they don't exist        
          if (!payload.predictionOddEven) {
            payload.predictionOddEven = { a: [], b: [], c: [], d: [] };
          }
          // Determine which array to push the bet into based on the index
          switch (game.index) {
            case 0:
              payload.predictionOddEven.a.push(bet);
              break;
            case 1:
              payload.predictionOddEven.b.push(bet);
              break;
            case 2:
              payload.predictionOddEven.c.push(bet);
              break;
            case 3:
              payload.predictionOddEven.d.push(bet);
              break;
            default:
              console.warn(`Unknown index in TwoSide: ${game.index}`);
              break;
          }
          // Add 'TwoSide' to predictionType if not already present
          if (!payload.predictionType.includes('TwoSide')) {
            payload.predictionType.push('TwoSide');
          }
          break;

        case '1Digit':
          if (!payload.prediction1DArray) {
            payload.prediction1DArray = [];
          }
          payload.prediction1DArray.push(bet);
          if (!payload.predictionType.includes('1D')) {
            payload.predictionType.push('1D');
          }
          break;

        case '2D':
          if (!payload.prediction2DArray) {
            payload.prediction2DArray = [];
          }
          payload.prediction2DArray.push(bet);
          if (!payload.predictionType.includes('2D')) {
            payload.predictionType.push('2D');
          }
          break;

        case '3D':
          if (!payload.prediction3DArray) {
            payload.prediction3DArray = [];
          }
          payload.prediction3DArray.push(bet);
          if (!payload.predictionType.includes('3D')) {
            payload.predictionType.push('3D');
          }
          break;

        case '4D':
          if (!payload.prediction4DArray) {
            payload.prediction4DArray = [];
          }
          payload.prediction4DArray.push(bet);
          if (!payload.predictionType.includes('4D')) {
            payload.predictionType.push('4D');
          }
          break;

        case 'FishPrawnCrab':
          if (!payload.fishPrawnCrabArray) {
            payload.fishPrawnCrabArray = [];
          }
          payload.fishPrawnCrabArray.push(bet);
          if (!payload.predictionType.includes('FishPrawnCrab')) {
            payload.predictionType.push('FishPrawnCrab');
          }
          break;

        default:
          console.warn(`Unknown selectedTab: ${game.selectedTab}`);
          break;
      }
    });

    // Ensure there are valid bets to send
    if (payload.predictionType.length > 0) {
      this.lotteryService.placeBet(payload).subscribe({
        next: (response) => {
          if (this.selectedBottomTab == 2) {
            this.orderTabComponent?.getMyOrders()
          }
          this.count = 1;
          this.selectedGame = []
          this.isLoading = false
          this.betPlaced = true
          setTimeout(() => {
            this.betPlaced = false
          }, 3000)
          this.getWalletInfo();
        },
        error: (error) => {
          console.error('Error sending betting data:', error);
          this.isLoading = false;
        }
      });
    } else {
      console.warn('No valid bets to send.');
      this.isLoading = false;
    }
  }

  changeTab(tabIndex: number, tabLabel: string): void {
    this.selectedTab = tabIndex;

    const analytics = getAnalytics();
    logEvent(analytics, 'lottery_select_tab', {
      tabIndex: tabIndex,
      tabLabel: tabLabel,
      timestamp: new Date().toISOString()
    });
  }

  closeBottomSheet(): void {
    this.bottomSheetVisible = false;
  }

  // spliting the lastFirstPrize
  getCharacters() {
    return this.lotteryData?.lastFirstPrize ? this.lotteryData.lastFirstPrize.split('') : [];
  }

  //disabling the firstprize list based on the selected tab 
  getDisabledIndexes(): number[] {
    switch (this.lotteryTypes[this.selectedTab]?.label) {
      case 'TwoSide':
        return this.lotteryData.instantLottery ? [] : [0, 1];
      case 'FishPrawnCrab':
        return this.lotteryData.instantLottery ? [0, 1, 2] : [0, 1, 2, 3, 4];
      case '1 Digit':
        return this.lotteryData.instantLottery ? [0, 1] : [0, 1, 2, 3];
      case '2D':
        return this.lotteryData.instantLottery ? [0, 1] : [0, 1, 2, 3];
      case '3D':
        return this.lotteryData.instantLottery ? [0] : [0, 1, 2];
      case '4D':
        return this.lotteryData.instantLottery ? [] : [0, 1];
      default:
        return [];
    }
  }

  getBackgroundColor(index: number): string {
    const colorIndex = this.lotteryData.instantLottery ? index + 2 : index;
    return this.colors[colorIndex] ? this.colors[colorIndex] : '#D5CECE';
  }

  getTotalAmount() {
    return this.selectedGame.reduce((total, game) => total + (game.betAmount ?? 0), 0);
  }
  getMaxBetAmount(): number {
    return this.lotteryTypes[this.selectedTab]?.maxAmount || 300000;
  }

  getMinBetAmount(): number {
    return this.lotteryTypes[this.selectedTab]?.minAmount || 20;
  }
  getDefBetAmount(): number {
    return this.lotteryTypes[this.selectedTab]?.defAmount || 20;
  }
  incrementBetAmount(game: GameTypeSelect, amount: number): void {
    const maxBetAmount = this.getMaxBetAmount();
    game.betAmount = Math.min(maxBetAmount, (game.betAmount ?? 0) + amount);
  }

  removeGame(index: number): void {
    this.selectedGame.splice(index, 1);
    this.count--;
  }
  removeAllGames(): void {
    this.selectedGame = [];
    this.count = 1;
  }
  scrollTo(event: any) {

    const scrollableArea = document.querySelector('.scrollable-region');
    const chartElement = document.getElementById('chart');

    if (chartElement && scrollableArea) {
      const topPosition = chartElement.offsetTop - scrollableArea.getBoundingClientRect().top;

      scrollableArea.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }

  dataRecieve(event: any) {
    event.forEach((selection: any) => {
      this.selectedGame.unshift({
        id: selection.id,
        combination: selection.combination,
        color: selection.colors,
        selectedTab: selection.selectedTab,
        count: this.count++,
        betAmount: this.getDefBetAmount()
      });
    });
    this.clearSelection = true;
    setTimeout(() => {
      this.clearSelection = false;
    }, 100);
  }
  showPopup() {
    this.dialog.open(RulesPopupComponent)
  }

  getWalletInfo() {
    this.walletService.getInfo().subscribe({
      next: data => {
        this.totalWalletAmount = data.data.wallet.totalAmount
      },
      error: err => {
      }
    })
  }

  closeWinDialog() {
    this.wonAmount = 0
    this.showWonStatusDialog = false;
    this.stopWinAudio()
  }

  closeBetPlaceDialog() {
    this.betPlaced = false;
  }

  handleBackClick() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }
}
