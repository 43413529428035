import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { LotteryService } from 'src/app/_services/lottery.service';

@Component({
  selector: 'app-games-bottom-sheet',
  templateUrl: './games-bottom-sheet.component.html',
  styleUrls: ['./games-bottom-sheet.component.css'],
})
export class GamesBottomSheetComponent implements OnInit {
  activeLottery: any[] = [];
  selectedLotteryId: string;
  instantLottery = false
  // Output event to emit the selected lottery ID
  @Output() lotterySelected = new EventEmitter<string>();

  images: any[] = [];
  constructor(
    private lotteryService: LotteryService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private imageService: DiceBetImageService
  ) {
    this.selectedLotteryId = data.selectedLotteryId;
    this.instantLottery = data.instantLottery
  }

  ngOnInit(): void {
    this.images = this.imageService.getStateLotteries()
    if (!this.instantLottery) {
      this.lotteryService.getActive().subscribe({
        next: data => {
          this.activeLottery = data.data.map((lottery: any) => {
            // Find corresponding image for the lottery
            const image = this.images.find(img => img.id === lottery._id);
            return {
              ...lottery,
              image: image ? image.src : '',
              alt: image ? image.alt : 'Lottery Image',
            };
          });
        }
      });
    } else {
      this.lotteryService.getActiveInstantLotteries().subscribe({
        next: data => {
          this.activeLottery = data.data.map((lottery: any) => {
            // Find corresponding image for the lottery
            const image = this.images.find(img => img.id === lottery._id);
            return {
              ...lottery,
              image: image ? image.src : '',
              alt: image ? image.alt : 'Lottery Image',
            };
          });
        }
      });
    }
  }

  close() {
    this.bottomSheet.dismiss();
  }
  selectLottery(lotteryName: string) {
    this.lotterySelected.emit(lotteryName);
    this.bottomSheet.dismiss();
    this.router.navigate(['lottery-game', lotteryName]);
  }
}