<div class="overflow-hidden p-2 md:p-4 rounded-3xl max-w-sm">
  <div class="flex justify-between px-1 items-center h-1 py-4">
    <span class="align-middle text-lg">Rules</span>
    <button
      type="button"
      class="text-gray-500 hover:text-gray-700 outline-none"
      (click)="closePopup()"
    >
      <span aria-hidden="true" class="text-xl">&times;</span>
    </button>
  </div>
  <div class="text-sm md:text-lg overflow-y-auto px-1" style="height: 500px">
    <!-- Title -->
    <div class="font-bold text-lg my-4">Dice Game Description</div>
    <!-- Description -->
    <div class="mb-8">
      <p class="mb-4">
        Dice Game is a game played with dice to guess the dice points or the
        total number of points summed up by one or more dice. There are many
        ways to play, and players are allowed to place multiple bets together.
      </p>
    </div>

    <!-- Big / Small Section -->
    <div class="font-bold text-lg my-4">Big / Small</div>
    <div class="mb-8">
      <p class="mb-2">
        In a 3 dice game, the point values for BIG and SMALL are as follows:
      </p>
      <p>
        <strong class="text-red-600">Big</strong> = 11, 12, 13, 14, 15, 16, and
        17
      </p>
      <p>
        <strong class="text-red-600">Small</strong> = 4, 5, 6, 7, 8, 9, and 10
      </p>
      <p class="mt-2">
        The players who bet on 'BIG' and 'SMALL' will win if points match these
        numbers after the dice draw.
      </p>
    </div>

    <!-- Odd / Even Section -->
    <div class="font-bold text-lg my-4">Odd / Even</div>
    <div class="mb-8">
      <p>
        The sum of points of 3 Dice allows players to bet on Odd or Even
        numbers.
      </p>
      <p>In a 3 dice game, the point values for Odd and Even are as follows:</p>
      <p>
        <strong class="text-red-600">Odd</strong> = 5, 7, 9, 11, 13, 15, and 17
      </p>
      <p>
        <strong class="text-red-600">Even</strong> = 4, 6, 8, 10, 12, 14, and 16
      </p>
      <p class="mt-2">
        <strong class="text-red-600">Except specific triple:</strong> 3=111,
        6=222, 9=333, 12=444, 15=555, 18=666
      </p>
    </div>
    <!-- Sum of Points Section -->
    <div class="font-bold text-lg my-4">Sum of Points</div>
    <div class="mb-8">
      <p>
        The sum of points allows players to bet on the specific total of points
        drawn from the 3 Dice. This does not include triples.
      </p>
      <p>Bet on 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17</p>
      <p class="mt-2">
        <strong class="text-red-600">Except specific triple:</strong> 3=111,
        6=222, 9=333, 12=444, 15=555, 18=666
      </p>
    </div>

    <!-- Single Dice Section -->
    <div class="font-bold text-lg my-4">Single Dice</div>
    <div class="mb-8">
      <p>
        Single Dice is the prediction of the dice point that will be drawn any 1
        out of 3 dice.
      </p>
      <p>Bet on 1, 2, 3, 4, 5, 6</p>
    </div>

    <!-- Double Dice Section -->
    <div class="font-bold text-lg my-4">Double Dice</div>
    <div class="mb-8">
      <p>
        Players can place 1 or more bets on any of the 15 different Double Dice
        Combinations available. Betting results are based on any of the 2 dice
        out of 3 dice results.
      </p>
      <p>
        Bet on 2 dice numbers: 12, 13, 14, 15, 16, 23, 24, 25, 26, 34, 35, 36,
        45, 46, 56
      </p>
    </div>

    <!-- Specific Double Section -->
    <div class="font-bold text-lg my-4">Specific Double</div>
    <div class="mb-8">
      <p>
        Specific Double allows players to bet on a specific point pair drawn on
        any of the 2 Dice out of 3.
      </p>
      <p>Bet on 11, 22, 33, 44, 55, 66</p>
    </div>

    <!-- Any Triple Section -->
    <div class="font-bold text-lg my-4">Any Triple</div>
    <div class="mb-8">
      <p>
        Any Triple allows players to place bets for any of the 6 combinations of
        triples.
      </p>
      <p>Bet on all triple: (111, 222, 333, 444, 555, 666)</p>
    </div>

    <!-- Specific Triple Section -->
    <div class="font-bold text-lg my-4">Specific Triple</div>
    <div class="mb-8">
      <p>
        Specific Triple allows players to bet on specific triple combinations
        that will be drawn from the Dice.
      </p>
      <p>Bet separately specific triple = 111/222/333/444/555/666</p>
    </div>

    <!-- Game Odds Section -->
    <div class="font-bold text-lg my-4">Game Odds</div>
    <!-- Make table scrollable on small screens -->
    <div class="p-0">
      <table
        class="w-full border-collapse border border-gray-300 text-left text-sm"
      >
        <thead>
          <tr class="bg-gray-100">
            <th class="border border-gray-300 px-2 py-2">
              <strong>Type</strong>
            </th>
            <th class="border border-gray-300 px-2 py-2">
              <strong>Description</strong>
            </th>
            <th class="border border-gray-300 px-2 py-2">
              <strong>Odds</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-gray-300 px-2 py-2">BIG</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points between 11 to 17 excluding Triples
            </td>
            <td class="border border-gray-300 px-2 py-2">1:1.95</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">SMALL</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points between 4 to 10 excluding Triples
            </td>
            <td class="border border-gray-300 px-2 py-2">1:1.95</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">Odd</td>
            <td class="border border-gray-300 px-2 py-2">
              Sum of points (Odd number)
            </td>
            <td class="border border-gray-300 px-2 py-2">1:1.95</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">Even</td>
            <td class="border border-gray-300 px-2 py-2">
              Sum of points (Even number)
            </td>
            <td class="border border-gray-300 px-2 py-2">1:1.95</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">4 or 17</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 4 or 17
            </td>
            <td class="border border-gray-300 px-2 py-2">1:50</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">5 or 16</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 5 or 16
            </td>
            <td class="border border-gray-300 px-2 py-2">1:18</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">6 or 15</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 6 or 15
            </td>
            <td class="border border-gray-300 px-2 py-2">1:14</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">7 or 14</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 7 or 14
            </td>
            <td class="border border-gray-300 px-2 py-2">1:12</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">8 or 13</td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 8 or 13
            </td>
            <td class="border border-gray-300 px-2 py-2">1:8</td>
          </tr>
          <tr>
            <td class="border border-gray-300 px-2 py-2">
              9 or 10 or 11 or 12
            </td>
            <td class="border border-gray-300 px-2 py-2">
              Total points of 9, 10, 11, or 12
            </td>
            <td class="border border-gray-300 px-2 py-2">1:6</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
