<div id="app">
  <!-- <nav class="navbar navbar-expand">
    <a href="#" class="navbar-brand">
      <div class="logo">
      <img src="../assets/logo-96.png" class="toolbar-logo" alt="logo" data-loaded="true">
    </div>

      </a>
    <ul class="navbar-nav mr-auto" routerLinkActive="active">
      <li class="nav-item">
        <a href="/home" class="nav-link" routerLink="home">Home </a>
      </li>
      <li class="nav-item" *ngIf="showAdminBoard">
        <a href="/admin" class="nav-link" routerLink="admin">Admin Board</a>
      </li>
      <li class="nav-item" *ngIf="showModeratorBoard">
        <a href="/mod" class="nav-link" routerLink="mod">Moderator Board</a>
      </li>
      <li class="nav-item">
        <a href="/user" class="nav-link" *ngIf="isLoggedIn" routerLink="user">User</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn">
      <li class="nav-item">
        <a href="/register" class="nav-link" routerLink="register">Sign Up</a>
      </li>
      <li class="nav-item">
        <a href="/login" class="nav-link" routerLink="login">Login</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
      <li class="nav-item">
        <a href="/profile" class="nav-link" routerLink="profile">{{ username }}</a>
      </li>
      <li class="nav-item">
        <a href class="nav-link" routerLink="wallet">Wallet</a>
      </li>
      <li class="nav-item">
        <a href class="nav-link" routerLink="wallet" (click)="logout()">Logout</a>
      </li>
    </ul>
  </nav> -->
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
