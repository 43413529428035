import { Component, OnInit } from '@angular/core';
import { BallGameService } from 'src/app/_services/ball.game.service';
import { Winner } from 'src/app/model/selectedDice.interface';

@Component({
  selector: 'app-ball-gamewinners',
  templateUrl: './ball-gamewinners.component.html',
  styleUrls: ['./ball-gamewinners.component.css']
})
export class BallGamewinnersComponent implements OnInit {

  constructor(private diceService:BallGameService) { }
  winners:Winner[]=[]
  
  ngOnInit(): void {
     this.diceService.getWinners().subscribe({
      next: (data) => {
        this.winners = data.data.sort((a: Winner, b: Winner) => b.wonAmount - a.wonAmount);
      },
      error: (err) => {
        console.error('Error fetching winners data:', err);
      }
    })
  }
}
