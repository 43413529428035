import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pre-sale-popup',
  templateUrl: './pre-sale-popup.component.html',
  styleUrls: ['./pre-sale-popup.component.css']
})
export class PreSalePopupComponent implements OnInit {

  constructor(private dialog:MatDialog) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialog.closeAll()
  }
}
