<div class="container">
  <div class="flex space-x-4 h-12 p-2" style="background-color: #F7F9FF;">
    <button class="px-4 py-2 bg-gray-200 rounded-full text-gray-700 focus:outline-none">1st-prize</button>
  </div>
  
  <div class="px-3 py-2 flex items-center" style="background-color: #E7EAF4;">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="w-5 h-5">
      <g clip-path="url(#a)">
        <path fill="#9fa5ac" d="M10 .474a9.526 9.526 0 1 0 .003 19.052A9.526 9.526 0 0 0 10 .474m-.888 4.562a1.1 1.1 0 0 1 .89-.424q.54 0 .889.417c.232.28.347.626.347 1.046 0 .36-.483 3.012-.644 4.94H9.43c-.14-1.928-.665-4.58-.665-4.94a1.58 1.58 0 0 1 .347-1.04m1.764 9.951a1.148 1.148 0 0 1-1.747 0 1.38 1.38 0 0 1-.366-.968q0-.567.366-.978a1.14 1.14 0 0 1 .874-.41q.506 0 .873.41.367.411.366.978a1.4 1.4 0 0 1-.366.968"></path>
      </g>
      <defs>
        <clipPath id="a">
          <rect fill="#fff" class="w-5 h-5"></rect>
        </clipPath>
      </defs>
    </svg>
    <div class="ml-2 text-sm leading-4 flex-1">
      <div class="flex">
        <div class="pr-2 mr-2 border-r border-gray-300">
          <span class="font-bold">Big:&nbsp;</span>5,6,7,8,9
        </div>
        <div class="pr-2 mr-2">
          <span class="font-bold">Small:&nbsp;</span>0,1,2,3,4
        </div>
      </div>
      <div class="flex mt-1">
        <div class="pr-2 mr-2 border-r border-gray-300">
          <span class="font-bold">Odd:&nbsp;</span>1,3,5,7,9
        </div>
        <div class="pr-2 mr-2">
          <span class="font-bold">Even:&nbsp;</span>0,2,4,6,8
        </div>
      </div>
    </div>
  </div>
  
  <div class="flex p-3 items-center bg-gray-200" *ngFor="let data of colors; let i = index">
    <div class="flex flex-col items-center justify-center mr-2" [ngStyle]="{'color':data.color}">
      <div class="font-bold text-lg">{{data.label}}</div>
      <div class="rounded-full w-5 h-5 " [ngStyle]="{'background-color':data.color}"></div>
    </div>
    
    <div class="flex-1 flex items-center">
      <div class="relative flex flex-col gap-2 flex-1">
        <div class="flex sm:flex-row gap-2" role="group">
          <ng-container *ngFor="let option of ['Odd', 'Even', 'Big', 'Small']">
            <div class="flex-1">
              <label class="block cursor-pointer opacity-100">
                <input type="checkbox" class="hidden"  [value]="option" (change)="onCheckboxChange(option,data.color, i)">
                <div class="relative flex flex-col items-center border-2 rounded-lg p-3" [ngStyle]="{'border-color': data.color, 'background-color': selectedValues.includes(option + i) ? data.color : 'white'}" style="box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px 0px, rgb(255, 255, 255) -4px -4px 8px 0px;">
                  <span class="font-bold text-sm " [ngStyle]="{'color':selectedValues.includes(option + i) ? 'white':'#1f2937'}">{{option}}</span>
                  <span class="text-xs mt-1 "  [ngStyle]="{'color': selectedValues.includes(option + i) ? 'white' : '#4b5563'}">1.95X</span>
                </div>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
