import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Color } from './../enums/color';
import { SelectedBet } from './../model/selected-bet.model';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';
import { WalletService } from '../_services/wallet.service';
import { ButtonData } from '../model/selectedDice.interface';
import { MatDialog } from '@angular/material/dialog';
import { PreSalePopupComponent } from '../games/dice-game/pre-sale-popup/pre-sale-popup.component';
import { DiceService } from '../_services/dice.service';
import { BallGameService } from '../_services/ball.game.service';

@Component({
  selector: 'app-bottom-sheet-example',
  templateUrl: './bottom-sheet-example.component.html',
  styleUrls: ['./bottom-sheet-example.component.css']
})
export class BottomSheetExampleComponent {
  calculateTotalBetPrice() {
    throw new Error('Method not implemented.');
  }
  amountToBet = [10, 100, 500, 1000];
  betCount = [1, 3, 9, 27, 81, 243, 729];
  betAmountActiveButton: number = 0;
  betCountActiveButton: number = 0;
  selectedBet: SelectedBet = {
    color: Color.NONE,
    number: -1,
    gameId: ''
  };
  selectedBetClass = '';
  betNumberOrColor = '';
  enable = true;
  isLoading = false;
  selectedAmountIndex = 0;
  selectedBetCountIndex = 0;
  multiplier = 1;  // New state variable for the multiplier
  totalBetPrice: number = 10;
  button!: ButtonData;
  selectedBetFromDice:any
  constructor(
    private tokenStorageService: TokenStorageService,
    private ballGameService: BallGameService,
    private diceService:DiceService,
    private walletService: WalletService,
    private dialogue:MatDialog,
    private router: Router,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetExampleComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: SelectedBet | ButtonData
  ) {
    this.processData(data);
  }

  private processData(data: SelectedBet | ButtonData) {
    if ('color' in data && 'number' in data) {
      // Data is of type SelectedBet
      this.selectedBet = {
        color: data.color,
        number: data.number,
        gameId: data.gameId
      };
      this.assignSelectedBetClass(this.selectedBet);
    } else if ('imageSrc' in data && 'value' in data) {
      this.selectedBetFromDice = {...data}
      // Data received from the dice
      this.button = { ...data, imageSrc: this.convertImagePath(data.imageSrc) };
    }
  }

  // Image path shortening 
  private convertImagePath(imageSrc: string): string {
    return imageSrc.replace('../../../../', '../../../');
  }

  assignSelectedBetClass(bet: SelectedBet) {
    let classMapForColorOnly: { [key in Color]?: string } = {
      [Color.GREEN]: "selected-green-ball",
      [Color.RED]: "selected-red-ball",
      [Color.VIOLET]: "selected-violet-ball",
      [Color.GREEN_VIOLET]: "selected-green-violet-ball",
      [Color.RED_VIOLET]: "selected-red-violet-ball",
    };
    if (bet.number === -1) {
      this.betNumberOrColor = bet.color;
    } else {
      this.betNumberOrColor = bet.number.toString();
    }
    this.selectedBetClass = classMapForColorOnly[bet.color] || "default-class";
  }

  close(code: number, status: string): void {
    // Determine which data to use for selectedBet
    let selectedBetData: any;
  
    if (this.selectedBetFromDice && this.selectedBetFromDice.gameId) {
      // Use data from selectedBetFromDice if it is present
      selectedBetData = {
        color: this.selectedBetFromDice.color,
        number: Number(this.selectedBetFromDice.data),  // Convert 'data' string to number if needed
        gameId: this.selectedBetFromDice.gameId,
        sumGame: this.selectedBetFromDice.sumGame !== 'null' ? this.selectedBetFromDice.sumGame : null,  // Handle 'null' string
        sumGameTotalNumber: this.selectedBetFromDice.sumGameTotalNumber,
        predictionType: this.selectedBetFromDice.predictionType,
        sourceComponent: this.selectedBetFromDice.sourceComponent,
        value: this.selectedBetFromDice.value,
        data:this.selectedBetFromDice.data,
        imageSrc: this.selectedBetFromDice.imageSrc,
        singlePair:this.selectedBetFromDice.singlePair,
        doublePair:this.selectedBetFromDice.doublePair,
        triplePair:this.selectedBetFromDice.triplePair
      };
    } else {
      // Use data from selectedBet as a fallback
      selectedBetData = {
        color: this.selectedBet.color,
        number: this.selectedBet.number,
        gameId: this.selectedBet.gameId,
      };
    }
  
    const jsonData = {
      message: "Success",
      payload: {
        code: code,
        status: status,
        type: this.selectedBetFromDice ? 'dice' : 'color',  // Determines the type based on selectedBetFromDice presence
        selectedBet: selectedBetData,  
      }
    };
  
    this._bottomSheetRef.dismiss(jsonData);
  }
  

  handleAmountClick(colIndex: number) {
    this.selectedAmountIndex = colIndex;
    this.updateTotalBetPrice();
    this.handleBetAmountActiveStatus(colIndex);
  }

  private handleBetAmountActiveStatus(colIndex: number) {
    if (this.betAmountActiveButton && this.betAmountActiveButton === colIndex) {
      this.betAmountActiveButton = -1;
    } else {
      this.betAmountActiveButton = colIndex;
    }
  }

  handleBetCountClick(colIndex: number) {
    this.selectedBetCountIndex = colIndex;
      // Set the multiplier to the selected betCount value
    this.multiplier = this.betCount[colIndex];
    this.handleBetCountActiveStatus(colIndex);
    this.updateTotalBetPrice();
  } 

  private handleBetCountActiveStatus(colIndex: number) {
    if (this.betCountActiveButton && this.betCountActiveButton === colIndex) {
      this.betCountActiveButton = -1;
    } else {
      this.betCountActiveButton = colIndex;
    }
  }

  handleMultiplierChange(newMultiplier: number) {
    if(newMultiplier>1)
    this.multiplier = newMultiplier;
    else
      this.multiplier =1
     // Automatically update the active button based on the multiplier value
     const activeIndex = this.betCount.indexOf(this.multiplier);
     this.betCountActiveButton = activeIndex !== -1 ? activeIndex : -1;
    this.updateTotalBetPrice();
  }

  updateTotalBetPrice() {
    this.totalBetPrice = this.amountToBet[this.selectedAmountIndex] * this.multiplier;
  }

  handleBetPlaceClick() {
    let user = this.tokenStorageService.getUser();
    if (!user) {
      this.close(0, "");
      this.router.navigate(['/login']);
    } else {
      this.isLoading = true;
      this.checkWalletInfoAndProceed();
    }
  }

  checkWalletInfoAndProceed() {
    this.walletService.getInfo().subscribe({
      next: data => {
        let totalBetAmount = this.amountToBet[this.selectedAmountIndex] * this.betCount[this.selectedBetCountIndex] * this.multiplier;
        if (data.data.wallet.totalAmount >= totalBetAmount) {
          this.placeBet();
        } else {
          this.close(0, "");
          this.router.navigate(['/wallet']);
        }
      },
      error: err => {
        this.isLoading = false;
        console.log("Error" + err.message);
      }
    });
  }
  placeBet() {
    if ('color' in this.data && 'number' in this.data) {
      this.placeSelectedBet();
    } else if ('imageSrc' in this.data && 'value' in this.data) {
      this.placeButtonBet();
    }
  }
  placeSelectedBet() {
    let betAmount = this.amountToBet[this.selectedAmountIndex];
    let betCount = this.betCount[this.selectedBetCountIndex];
    let colorMapUiColor: { [key in Color]?: string } = {
      [Color.GREEN]: "g",
      [Color.RED]: "r",
      [Color.VIOLET]: "v",
      [Color.GREEN_VIOLET]: "gv",
      [Color.RED_VIOLET]: "rv",
    };
    let predictionNumber = this.selectedBet.number >= 0 ? this.selectedBet.number : null;
    this.ballGameService.placeBet(
      this.selectedBet.gameId,
      betAmount,
      betCount,
      "color",
      colorMapUiColor[this.selectedBet.color] || "u",
      predictionNumber
    ).subscribe({
      next: data => {
        this.close(100, "bet placed");
      },
      error: err => {
        this.isLoading = false;
        console.log("error " + err.message);
      }
    });
  }
   // Place bet for ButtonData
   placeButtonBet() {
    let betAmount = this.amountToBet[this.selectedAmountIndex];
    let betCount = this.betCount[this.selectedBetCountIndex];
    // Add specific bet placement logic for ButtonData here
    this.diceService.placeBet(
      this.button.gameId,
      this.button.predictionType,
      this.button.sumGame,
      this.button.sumGameTotalNumber,
      betAmount,
      betCount,
      this.button.singlePair,
      this.button.doublePair,
      this.button.triplePair
    ).subscribe({
      next: data => {
        this.close(100, "bet placed");
      },
      error: err => {
        this.isLoading = false;
        console.log("error " + err.message);
      }
    });
  }
  // presale popup
  presalePopup(){
      this.dialogue.open(PreSalePopupComponent)
  } 
}