import { Component, OnInit } from '@angular/core';
import { AdminDashboardService } from '../../_services/admin.dashboard.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-board-admin',
  templateUrl: './board-admin.component.html',
  styleUrls: ['./board-admin.component.css']
})
export class BoardAdminComponent implements OnInit {
  payouts: any[] = [];
  processingPayouts: any[] = [];
  completedPayouts: any[] = [];
  totalPendingAmount: number = 0;
  totalProcessingAmount: number = 0;
  availableBalance: number = 0
  frozenAmount: number = 0
  availableAmount: number = 0

  currentPage = 1;
  itemsPerPage = 10;
  totalRecords = 0;
  totalPages = 0;

  processingCurrentPage = 1;
  processingItemsPerPage = 10;
  processingTotalRecords = 0;
  processingTotalPages = 0;

  completedCurrentPage = 1;
  completedItemsPerPage = 10;
  completedTotalRecords = 0;
  completedTotalPages = 0;

  sepPayOutFailures: any[] = [];
  sepPayFailureStartDate: string | null = null;
  sepPayFailureEndDate?: string | null = null;

  sepPayFailureCurrentPage = 1;
  sepPayFailureItemsPerPage = 10;
  sepPayFailureTotalRecords = 0;
  sepPayFailureTotalPages = 0;

  loading = false;
  selectAll: boolean = false;
  user: any;

  constructor(private adminService: AdminDashboardService,
    private tokenStorageService: TokenStorageService,
    private userService: UserService,
    private router: Router,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getUserInfo()
    this.loadPendingPayouts();
    this.loadProcessingPayouts()
    this.loadCompletedPayouts()
    this.getAccountBalance()
    this.loadSepPayOutFailures()

    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  getUserInfo() {
    this.userService.getAdminInfoInfo().subscribe({
      next: data => {
        this.user = data.data
      }, error: err => {
        this._snackbar.open("Something is wrong", 'Close', {
          duration: 3000
        });
      }
    });
  }

  getAccountBalance() {
    this.adminService.getBalance().subscribe({
      next: response => {
        this.availableBalance = response.data.amount
        this.frozenAmount = response.data.frozenAmount
        this.availableAmount = response.data.availableAmount
      },
      error: err => {
        console.error('Error fetching balance:', err);
      }
    });
  }

  loadPendingPayouts() {
    this.adminService.getPendingPayoutRequest(this.currentPage, this.itemsPerPage).subscribe({
      next: response => {
        this.totalPendingAmount = response.totalPendingAmount
        this.payouts = response.data;
        this.totalRecords = response.totalRecords;
        this.totalPages = response.totalPages;
      },
      error: err => {
        console.error('Error fetching pending payouts:', err);
      }
    });
  }

  loadProcessingPayouts() {
    this.adminService.getProcessingPayouts(this.processingCurrentPage, this.processingItemsPerPage).subscribe({
      next: response => {
        this.totalProcessingAmount = response.totalProcesingAmount
        this.processingPayouts = response.data;
        this.processingTotalRecords = response.totalRecords;
        this.processingTotalPages = response.totalPages;
      },
      error: err => {
        console.error('Error fetching processing payouts:', err);
      }
    });
  }

  loadCompletedPayouts() {
    this.adminService.getCompletedPayouts(this.completedCurrentPage, this.completedItemsPerPage).subscribe({
      next: response => {
        this.completedPayouts = response.data;
        this.completedTotalRecords = response.totalRecords;
        this.completedTotalPages = response.totalPages;
      },
      error: err => {
        console.error('Error fetching processing payouts:', err);
      }
    });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadPendingPayouts();
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadPendingPayouts();
    }
  }

  toggleAll() {
    this.payouts.forEach(payout => {
      payout.selected = this.selectAll;
    });
  }

  checkIfAllSelected() {
    this.selectAll = this.payouts.every(payout => payout.selected);
  }

  isAnySelected(): boolean {
    return this.payouts.some(payout => payout.selected);
  }

  approveSelected() {
    this.loading = true;
    const selectedPayoutIds = this.payouts
      .filter(payout => payout.selected)
      .map(payout => payout._id);
    this.adminService.approvePayouts(selectedPayoutIds).subscribe(response => {
      if (response.statusCode === 200) {
        this.loading = false;
        this.loadPendingPayouts();
        this.loadProcessingPayouts()
      } else {
        this.loading = false;
        console.error('Error approving payouts:', response.message);
      }
    });
  }

  getAmountColor(amount: number) {
    if (amount > 5000) {
      return 'red';
    } else if (amount > 1000) {
      return 'darkyellow';
    } else {
      return 'black';
    }
  }

  goToProcessingPage(page: number) {
    if (page >= 1 && page <= this.processingTotalPages) {
      this.processingCurrentPage = page;
      this.loadProcessingPayouts();
    }
  }

  goToCompletedPage(page: number) {
    if (page >= 1 && page <= this.completedTotalPages) {
      this.completedCurrentPage = page;
      this.loadCompletedPayouts();
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/login']);
  }

  loadSepPayOutFailures() {
    this.adminService.getSepPayOutFailures(this.sepPayFailureCurrentPage, this.sepPayFailureItemsPerPage, this.sepPayFailureStartDate!, this.sepPayFailureEndDate!).subscribe({
      next: data => {
        this.sepPayOutFailures = data.data.sepPayOutFailures;
        console.log(this.sepPayOutFailures)
        this.sepPayFailureTotalRecords = data.data.totalRecords;
        this.sepPayFailureTotalPages = data.data.totalPages;
      },
      error: err => {
        this._snackbar.open("Error fetching payout failures", 'Close', {
          duration: 3000
        });
      }
    });
  }

  sepPayoutDateChange() {
    this.sepPayFailureCurrentPage = 1;
    this.loadSepPayOutFailures();
  }

  sepPayoutGoToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.sepPayFailureCurrentPage = page;
      this.loadSepPayOutFailures();
    }
  }
}
