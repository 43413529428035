<div>
    <div class="header bg-gray-200 px-3 py-1 flex items-center justify-between">
        <span>ISSUE</span>
        <span>Number</span>
    </div>
    
    <div class="result-item bg-gray-50 p-3 flex items-center justify-between border-b" *ngFor="let result of resultHistory">
        <div class="flex flex-col items-start gap-y-1">
            <span class="issue-number text-acc font-medium">{{result.code}}</span>
            <span class="issue-date text-acc text-xs">{{result.resultTime}}</span>
        </div>
        
        <div class="flex flex-col gap-1 shrink-0">
            <div class="prize-info flex items-center">
                <span class="prize-label text-xs w-[4.125rem] font-bold">1st-prize:</span>
                <ng-container *ngFor="let char of result.firstPrize.split('')">
                  <div class="prize-badge w-5 h-5 rounded-full text-white text-center text-sm ml-1" 
                       [ngStyle]="{ 'background-color': getColorForChar(char) }">
                    {{ char }}
                  </div>
                </ng-container>
              </div>
              
        </div>
    </div>
    <div class="paginator-container">
        <mat-paginator [length]="totalItems"
                       [pageSize]="itemsPerPage"
                       [pageSizeOptions]="[2, 5, 10, 25, 100]"
                       (page)="onPageChange($event)"
                       aria-label="Select page">
        </mat-paginator>
    </div>
</div>