// home-banner.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment'; // Firebase config


@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css']
})
export class HomeBannerComponent {
  slides = [
    {
      image: './../../assets/banner-1.webp',
      name:"Dice Game",
      title: 'Welcome to Our Website',
      text: 'Your satisfaction is our priority',
      routerLink: ['/dice-game']
    },
    {
      image: './../../assets/banner-2.webp',
      name:"Color Game",
      title: 'Discover Our Services',
      text: 'We offer a wide range of services',
      routerLink: ['/color-game']
    },
    {
      image: './../../assets/banner-3.webp',
      name:"JackPot 2 Min",
      title: 'Join Us Today',
      text: 'Be a part of our community',
      routerLink: ['/lottery-game/JackPot 2 Min']
    },
    {
      image: './../../assets/banner-4.webp',
      name:"Color Game",
      title: 'Join Us Today',
      text: 'Be a part of our community',
      routerLink: ['/color-game']
    },
  ];

  private analytics: any;

  constructor(private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  onBannerClick(slide: any): void {
    logEvent(this.analytics, 'home_banner_click', {
      content_type: 'banner',
      item_id: slide.routerLink,
      item_name: slide.name,
    });
    console.log(`Banner clicked: ${slide.title}`);
  }
}
