
<div class="flex justify-center w-full overflow-y-hidden h-screen">
  <div class="w-full min-w-sm max-w-max mar-bottom-50">
    <header class="toolbar w-full" style="box-shadow: 2px 10px 7px -12px;">
      <div class="nav-icon" (click)="handleBackClick()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"
            style="width: 1rem; height: 1rem;">
            <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </svg>
    </div>
    <div *ngIf="isLoading" class="flex justify-center fixed inset-0 items-center h-screen">
      <svg class="w-12 h-12 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
      xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
        stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
      <path
        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
        stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-red-500">
      </path>
    </svg>
    </div>
    
    <div class="loader-overlay countdown fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" *ngIf="countdownStart">
      <ng-lottie [options]="options" class="w-80 h-80"></ng-lottie>
    </div>
      <div class="font-bold text-lg flex-1">
        {{ title }}
      </div>
      <div class="actions" *ngIf="isLoggedIn">
        <div class="wallet-container">
          <span class="wallet-display">Balance</span>
          <span class="amount-display">₹{{ totalWalletAmount }}</span>
        </div>
        <a class="action-button" routerLink="/wallet">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            fill="#ff891c"
            class="bi bi-wallet-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z"
            />
            <path
              d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z"
            />
          </svg>
        </a>
      </div>
    </header>
   <div class="overflow-y-auto max-h-full pt-1">
    <div class="game-selector scrollable-nav" #scrollContainer>
      <nav class="flex justify-center">
        <div
          class="nav nav-tabs min-tab flex-1"
          id="nav-tab"
          role="tablist"
          #itemElement
        >
          <button
            class="nav-link flex-1"
            (click)="handleTabClick(1, 0)"
            id="nav-1-min-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-1-min"
            type="button"
            role="tab"
            aria-controls="nav-1-min"
            [ngClass]="{ active: activeTab === 0 }"
          >
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">1min</span>
          </button>
          <button
            class="nav-link flex-1"
            (click)="handleTabClick(3, 1)"
            id="nav-3-min-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-3-min"
            type="button"
            role="tab"
            aria-controls="nav-3-min"
            [ngClass]="{ active: activeTab === 1 }"
          >
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">3min</span>
          </button>

          <button
            class="nav-link flex-1"
            (click)="handleTabClick(5, 2)"
            id="nav-5-min-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-5-min"
            type="button"
            role="tab"
            aria-controls="nav-5-min"
            [ngClass]="{ active: activeTab === 2 }"
          >
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">5min</span>
          </button>
        </div>
      </nav>
    </div>
    <div
      class="game-details flex items-center justify-between md:px-2 px-1 py-3"
      style="background: linear-gradient(180deg, #ffd9d9, #fff0f0)"
    >
      <div class="game-id-text w-2/3 p-2 border-r border-dashed border-black">
        <div class="flex items-center gap-4">
          <div>
            <span class="game-config font-bold">{{ selectedTime }}</span> <br />
            <div>
              <span class="font-bold text-black text-xs md:text-sm">{{
                issueNo
              }}</span>
            </div>
          </div>
          <div>
            <button
              class="bg-white text-gray-600 w-24 py-2 rounded-md"
              (click)="howToPlay()"
            >
              How to play
            </button>
          </div>
        </div>
        <div class="flex flex-row gap-x-1 mt-4 mr-2">
          <div
            class="dice h-12 md:h-14 flex flex-row rounded-lg dice-header-ani-wrap items-center"
          >

            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <!-- Dynamically display dice images using *ngFor -->
                <img
                  *ngFor="let dice of dice1Images"
                  [src]="dice.imageSrc"
                  class="dice-image"
                />
              </div>
            </div>

            <!-- Column 2 -->
            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <!-- Dynamically display dice images using *ngFor -->
                <img
                  *ngFor="let dice of dice2Images"
                  [src]="dice.imageSrc"
                  class="dice-image"
                />
              </div>
            </div>

            <!-- Column 3 -->
            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <!-- Dynamically display dice images using *ngFor -->
                <img
                  *ngFor="let dice of dice3Images"
                  [src]="dice.imageSrc"
                  class="dice-image"
                />
              </div>
            </div>
          </div>

          <div
            class="dice h-12 md:h-14 md:px-1 flex flex-row rounded-lg dice-header-ani-wrap items-center"
          >
            <!-- Column 1 -->
            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <div
                  class="bg-full w-9 h-9 flex justify-center items-center"
                  *ngFor="let dice of dice4Images"
                  [ngStyle]="{
                    'background-image': 'url(' + dice.imageSrc + ')'
                  }"
                >
                  <div class="font-bold" [ngStyle]="{ color: dice.color }">
                    {{ dice.data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="dice h-12 md:h-14 flex md:px-1 flex-row rounded-lg dice-header-ani-wrap items-center"
          >
            <!-- Column 1 -->
            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <!-- Dynamically display dice images using *ngFor -->
                <img
                  *ngFor="let dice of dice5Images"
                  [src]="dice.imageSrc"
                  class="dice-image"
                />
              </div>
            </div>

            <!-- Column 2 -->
            <div class="dice-roll">
              <div
                [ngClass]="{ 'dice-shuffle': isShuffling, stop: !isShuffling }"
              >
                <!-- Dynamically display dice images using *ngFor -->
                <img
                  *ngFor="let dice of dice6Images"
                  [src]="dice.imageSrc"
                  class="dice-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timer right flex flex-col justify-around h-28 items-center">
        <span class="font-bold">Time remaining</span>
        <div>
          <ng-container *ngFor="let char of counter | formatTime | splitToATag">
            <span class="timer-ticks">{{ char }}</span>
          </ng-container>
        </div>
        <div>
          <span class="font-bold text-black text-left">{{ issueNo }}</span>
        </div>
      </div>
    </div>
    <div class="progress2 progress-moved">
      <div class="progress-bar2" [style.width]="dynamicWidth"></div>
    </div>
    <div
      class="tab-list h-14 md:h-16 flex-nowrap bg-gray-700 w-full flex flex-row relative rounded-none p-0 gap-x-3 items-end"
      aria-label="Options"
      role="tablist"
      aria-orientation="horizontal"
    >
      <button
        *ngFor="let tab of tabs"
        [attr.data-key]="tab.key"
        [id]="'tab-' + tab.key"
        [attr.aria-selected]="selectedTab === tab.key"
        role="tab"
        class="tab-item z-0 w-full flex group relative justify-center items-center cursor-pointer tap-highlight-transparent"
        [attr.tabindex]="selectedTab === tab.key ? 0 : -1"
        [ngClass]="{
          'selected-tab': selectedTab === tab.key,
          'unselected-tab': selectedTab !== tab.key
        }"
        (click)="selectTab(tab.key)"
        type="button"
      >
        <div
          class="relative z-10 whitespace-nowrap transition-colors text-sm"
          [ngClass]="{
            'selected-text': selectedTab === tab.key,
            'unselected-text': selectedTab !== tab.key
          }"
          data-slot="tabContent"
        >
          {{ tab.label }}
        </div>
      </button>
    </div>
    <div class="flex flex-col px-1 h-auto" style="background-color: rgb(190 202 210/1)">
      <ng-container *ngIf="selectedTab === 'total'">
        <app-sum
          [isButtonDisabled]="isButtonDisabled"
          [gameId]="gameId"
          [counter]="counter"
          [minRemainingTimeToComplete]="minRemainingTimeToComplete"
          (diceData)="getSelectedBet($event)"
        ></app-sum>
      </ng-container>
      <ng-container *ngIf="selectedTab === '3same'">
        <app-tripple
          [gameId]="gameId"
          [isButtonDisabled]="isButtonDisabled"
          [counter]="counter"
          [minRemainingTimeToComplete]="minRemainingTimeToComplete"
          (diceData)="getSelectedBet($event)"
        ></app-tripple>
      </ng-container>
      <ng-container *ngIf="selectedTab === '2same'">
        <app-double
          [gameId]="gameId"
          [isButtonDisabled]="isButtonDisabled"
          [counter]="counter"
          [minRemainingTimeToComplete]="minRemainingTimeToComplete"
          (diceData)="getSelectedBet($event)"
        ></app-double>
      </ng-container>
      <ng-container *ngIf="selectedTab === 'different'">
        <app-single
          [gameId]="gameId"
          [isButtonDisabled]="isButtonDisabled"
          [counter]="counter"
          [minRemainingTimeToComplete]="minRemainingTimeToComplete"
          (diceData)="getSelectedBet($event)"
        ></app-single>
      </ng-container>
      <div
        [ngClass]="{
          'h-auto-when-filled': selectedBet.length > 0,
          'h-24-when-empty': selectedBet.length === 0
        }"
        class="min-h-full my-2 mx-3 rounded-xl bg-gray-500 flex flex-col justify-between"
      >
        <div
          class="flex justify-center items-center text-center h-8 p-2 rounded-tr-xl rounded-tl-xl"
        >
          <div class="parting-line flex-1 h-2"></div>
          <div class="flex-1 text-base font-medium mybets-text">MY BETS</div>
          <div class="parting-line flex-1 h-2"></div>
        </div>
        <div class="flex gap-1 justify-start flex-wrap max-w-md">
          <div
            class="bg-full dice-sum-big h-9 w-9 mx-1 flex items-center font-bold justify-center"
            *ngFor="let bet of selectedBet"
            [style.backgroundImage]="'url(' + bet.imageSrc + ')'"
            [ngStyle]="{
              color: bet.data ? bet.color : 'transparent'
            }"
          >
            {{ bet.data }}
          </div>
        </div>
        <div class="relative rounded-bl-xl rounded-br-xl max-w-md">
          <div>
            <img
              src="../../../assets/dice-game/dice-bets-bottom.b65a5ef9.webp "
              class="mx-auto z-10 opacity-100 shadow-none transition-opacity w-full duration-300 rounded-xl"
              alt="Dice Bets"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="betting-close-overlay" *ngIf="blocked">
      <div class="close-message flex flex-col items-center w-full">
        <img src="../../assets/error.png" />
        <h4>Betting closed in the last {{minRemainingTimeToComplete}} seconds</h4>
      </div>
    </div>
    <div class="win-full-container" *ngIf="showWonStatusDialog" (click)='closeWinDialog()'>
      <div class="win-container">
          <div class="win-animation">
            <ng-lottie [options]="optionsForWin1" class="absolute inset-0 w-full h-full z-0 opacity-50"></ng-lottie>
              <button class="close-btn" aria-label="Close" (click)='closeWinDialog()'>
                  &times;
              </button>
              <h2 class="text-center mb-4">You have won <b>₹{{wonAmount}}</b></h2>
  
              <div class="flex justify-center items-center mb-4 space-x-4">
                  <ng-lottie [options]="optionsForWin2" class="w-40 h-40"></ng-lottie>
              </div>
  
              <!-- Second line with the wallet text -->
              <span class="wallet-add-text text-center block">
                  <b>₹{{wonAmount}}</b> Has been added to your wallet
              </span>
          </div>
      </div>
  </div>

    <div class="bet-success-alert-container text-center center" *ngIf="betPlaced">
      <button class="close-btn" aria-label="Close" (click)='closeBetPlaceDialog()'>
          &times;
      </button>
      <h2><b>Paid Successfully</b></h2>
      <span class="justify-around text-center">Your bet has been placed successfully, Please wait for the result.<br/><br/>Wish you all the best!</span>
  </div>
    <div
      class="flex flex-col justify-end px-3 h-12 min-h-12 bg-white w-full"
      aria-label="Options"
    >
      <div
        class="flex-nowrap   bg-transparent w-full h-full flex items-end relative rounded-none p-0 border-b border-gray-200 overflow-auto gap-8"
        aria-label="Options"
        role="tablist"
        aria-orientation="horizontal"
      >
        <button
          *ngFor="
            let label of ['Result', 'Winners', 'Analyse', 'My Order'];
            let i = index
          "
          (click)="onTabClick(i)"
          [class.text-main]="selectedBottomTab === i"
          [class.border-b-2]="selectedBottomTab === i"
          [class.border-red-600]="selectedBottomTab === i"
          [class.font-bold]="selectedBottomTab === i"
          class="z-0 w-full py-1 flex group relative justify-center items-center cursor-pointer transition-opacity outline-none text-sm rounded-none max-w-fit px-0 h-7 text-sec"
          role="tab"
          [attr.aria-selected]="selectedBottomTab === i"
        >
          <div class="relative z-10 whitespace-nowrap transition-colors">
            {{ label }}
          </div>
        </button>
      </div>
    </div>
    <div class="w-full pb-12" [ngSwitch]="selectedBottomTab" id="chart">
      <app-result *ngSwitchCase="0" [configId]="configId" [callLatestResult] ="callLatestResult"></app-result>
      <app-winners *ngSwitchCase="1"></app-winners>
      <app-analysis *ngSwitchCase="2"></app-analysis>
      <app-my-order #orderTab *ngSwitchCase="3"></app-my-order>
    </div>
   </div>
  </div>
  <app-home-bottom-nav></app-home-bottom-nav>
</div>
