import { Component, OnInit } from '@angular/core';
import { DiceService } from 'src/app/_services/dice.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css'],
})
export class AnalysisComponent implements OnInit {
  selectedRound: number = 0; // Track selected round
  AnalysisValue: any;
  ProgressValue: any;
  rounds: any[] = [];
  sumChartData: any; // Summary chart data for "SMALL vs BIG" chart
  sumChartOptions: any; // Options for "SMALL vs BIG" chart

  constructor(private diceService: DiceService) {}

  ngOnInit(): void {
    this.lotteryAnalysis();
  }

  // Fetch lottery analysis data
  lotteryAnalysis() {
    this.diceService.getLotteryAnalysis().subscribe({
      next: (data) => {
        console.log(data);
        this.AnalysisValue = data.data.numberPercentages;
        this.ProgressValue = data.data.sumPercentages;
        console.log(this.AnalysisValue);

        // Define available rounds
        this.rounds = [
          { label: '50 Round', data: this.getRoundData() },
          { label: '100 Round', data: this.getRoundData() },
          { label: '200 Round', data: this.getRoundData() },
          { label: '600 Round', data: this.getRoundData() },
        ];

        // Update summary data for "SMALL vs BIG" chart
        this.sumChartData = this.getSumChartData();
        this.sumChartOptions = this.getSumChartOptions();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  // Function to get data for individual rounds
  getRoundData() {
    return [
      { icon: '../../../../assets/dice-game/dice-single-1.cd31c2ce.webp', value: parseFloat(this.AnalysisValue['1']), color: '#ff9800' },
      { icon: '../../../assets/dice-game/dice-single-2.ef5b7308.webp', value: parseFloat(this.AnalysisValue['2']), color: '#ff9800' },
      { icon: '../../../assets/dice-game/dice-single-3.01040bed.webp', value: parseFloat(this.AnalysisValue['3']), color: '#ff9800' },
      { icon: '../../../assets/dice-game/dice-single-4.aa0fa9c1.webp', value: parseFloat(this.AnalysisValue['4']), color: '#ff9800' },
      { icon: '../../../assets/dice-game/dice-single-5.d76c07b5.webp', value: parseFloat(this.AnalysisValue['5']), color: '#ff9800' },
      { icon: '../../../assets/dice-game/dice-single-6.3b088966.webp', value: parseFloat(this.AnalysisValue['6']), color: '#ff9800' },
    ];
  }

  // Chart data for individual dice progress bars
  getChartData(value: number) {
    return {
      labels: [''],
      datasets: [
        {
          label: '',
          data: [value],
          backgroundColor: '#ff9800', // Filled part
          barThickness: 10,
          maxBarThickness: 10,
        },
        {
          label: '',
          data: [100 - value], // Unfilled part
          backgroundColor: '#e0e0e0', // Light gray color
          barThickness: 10,
          maxBarThickness: 10,
        },
      ],
    };
  }

  // Chart options for individual dice progress bars
  getChartOptions() {
    return {
      indexAxis: 'y' as const,
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          stacked: true,
          max: 100,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        bar: {
          borderRadius: 10,
          barThickness: 10,
          maxBarThickness: 10,
        },
      },
      animation: {
        duration: 0, // Disable animation
      },
    };
  }

  // Summary data for "SMALL vs BIG" chart
  getSumChartData() {
    return {
      labels: [''],
      datasets: [
        {
          label: 'Small',
          data: [parseFloat(this.ProgressValue['small'])],
          backgroundColor: '#007bff', // Blue color for Small
          barThickness: 40,
          maxBarThickness: 40,
        },
        {
          label: 'Big',
          data: [parseFloat(this.ProgressValue['big'])],
          backgroundColor: '#ff073a', // Red color for Big
          barThickness: 40,
          maxBarThickness: 40,
        },
        {
          label: 'Odd',
          data: [parseFloat(this.ProgressValue['odd'])],
          backgroundColor: '#007bff', // Blue color for Small
          barThickness: 40,
          maxBarThickness: 40,
        },
        {
          label: 'Even',
          data: [parseFloat(this.ProgressValue['even'])],
          backgroundColor: '#ff073a', // Red color for Big
          barThickness: 40,
          maxBarThickness: 40,
        },
      ],
    };
  }

  // Chart options for "SMALL vs BIG"
  getSumChartOptions() {
    return {
      indexAxis: 'y' as const,
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          stacked: true,
          max: 100,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      elements: {
        bar: {
          borderRadius: 15,
          barThickness: 20,
          maxBarThickness: 10,
        },
      },
    };
  }

  // Dice stats for the selected round
  get selectedDiceData() {
    return this.rounds[this.selectedRound].data;
  }
}
