import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const SESSION_DURATION_IN_MS = 60 * 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }


  signOut(): void {
    window.localStorage.clear();
  }

  public removeToken(): void {
    window.localStorage.removeItem(TOKEN_KEY);
  }

  public removeUser(): void {
    window.localStorage.removeItem(USER_KEY);
  }

  public saveToken(token: string): void {
    const now = new Date().getTime();
    const tokenData = {
      token: token,
      expiry: now + SESSION_DURATION_IN_MS
    };
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenData));
  }


  public getToken(): string | null {
    const tokenData = window.localStorage.getItem(TOKEN_KEY);
    if (!tokenData) {
      return null;
    }

    const parsedToken = JSON.parse(tokenData);
    const now = new Date().getTime();

    if (now > parsedToken.expiry) {
      window.localStorage.removeItem(TOKEN_KEY);
      return null;
    }

    return parsedToken.token;
  }

  public saveUser(user: any): void {
    const now = new Date().getTime();
    const sessionData = {
      user: user,
      expiry: now + SESSION_DURATION_IN_MS
    };
    window.localStorage.setItem(USER_KEY, JSON.stringify(sessionData));
  }

  public getUser(): any {
    const userData = window.localStorage.getItem(USER_KEY);
    if (!userData) {
      return null; 
    }
  
    const parsedUserData = JSON.parse(userData);
    const now = new Date().getTime();
  
    if (now > parsedUserData.expiry) {
      window.localStorage.removeItem(USER_KEY);
      return null;
    }
  
    return parsedUserData.user;
  }

  isLoggedIn(): boolean {
    return this.getToken() != null
  }

  isAdmin(): boolean {
    return this.getUser().userType == 2
  }
}
