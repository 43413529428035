import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxWheelComponent, TextAlignment, TextOrientation } from 'ngx-wheel';
import { UserSessionStorageService } from 'src/app/_services/user.session.storage.service';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { PromoService } from 'src/app/_services/promo.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-promo-home-screen-wheel',
  templateUrl: './promo-home-screen-wheel.component.html',
  styleUrls: ['./promo-home-screen-wheel.component.css']
})
export class PromoHomeScreenWheelComponent implements OnInit {

  @ViewChild(NgxWheelComponent, { static: false }) wheel?: NgxWheelComponent;

  items: any[] = [];
  textOrientation: TextOrientation = TextOrientation.HORIZONTAL;
  textAlignment: TextAlignment = TextAlignment.OUTER;
  idToLandOn: any;
  isSpinning = false;
  pointerColor = "#00fdd300";
  isWheelVisible = false;
  isVisible = true;
  isGiftVisible = false;
  private analytics: any;
  spinAudio?: HTMLAudioElement;
  winAudio?: HTMLAudioElement;
  giftMessage = "";
  promoCodes: any[] = [];

  constructor(private sessionServices: UserSessionStorageService, private promoService: PromoService, private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
    this.spinAudio = new Audio('../../../assets/rotate.wav');
    this.winAudio = new Audio('../../../assets/win.wav');
    this.spinAudio.load();
    this.winAudio.load();
  }

  ngOnInit(): void {
    logEvent(this.analytics, 'home_wheel_component_loaded', { component: 'PromoHomeScreenWheel' });

    this.fetchPromoCodes();
  }

  async fetchPromoCodes() {
    try {
      this.promoCodes = await firstValueFrom(this.promoService.getLuckyWheelPromoCodes());
      this.setupWheelItems();
      for (let i = 0; i < 10; i++) {
        console.log("Random Id " + this.getRandomWeightedValue());
      }
      this.isWheelVisible = true
    } catch (error) {
      console.error("Error fetching promo codes:", error);
    }
  }

  setupWheelItems() {
    const gradientColors = [
      { start: '#FF9E06', end: '#FFE499' },
      { start: '#DA1815', end: '#F5B08A' },
    ];

    this.items = this.promoCodes.map((promo, index) => {
      const color = gradientColors[index % gradientColors.length];
      return {
        fillStyle: this.createGradient(color.start, color.end),
        text: promo.offerName,
        promoCode: promo.promoCode,
        id: index,
        textFillStyle: '#000000b7',
        textFontSize: '18'
      };
    });

    this.idToLandOn = this.getRandomWeightedValue();
  }

  getRandomWeightedValue(): number {
    // Create an array of objects with calculated weights
    const weightedSeed = this.promoCodes.map(promo => ({
      value: this.promoCodes.indexOf(promo), // Index of the promo code
      weight: 11 - promo.weightage // Inverted weightage for higher chance on lower numbers
    }));

    // Calculate the total weight
    const totalWeight = weightedSeed.reduce((sum, item) => sum + item.weight, 0);

    // Generate a random number from 0 to total weight
    const randomNumber = Math.random() * totalWeight;

    let cumulativeWeight = 0;
    for (const item of weightedSeed) {
      cumulativeWeight += item.weight;
      // Select the promo code if the random number is less than the cumulative weight
      if (randomNumber < cumulativeWeight) {
        return item.value; // Return the index of the selected promo code
      }
    }

    // Fallback to the last item's index in case of any anomaly
    return weightedSeed[weightedSeed.length - 1].value;
  }

  createGradient(startColor: string, endColor: string): CanvasGradient {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!!;
    const width = 300;
    const height = 300;

    canvas.width = width;
    canvas.height = height;

    const gradient = context.createRadialGradient(width / 2, height / 2, 0, width / 2, height / 1.5, width / 2);
    gradient.addColorStop(1, startColor);
    gradient.addColorStop(0, endColor);
    return gradient;
  }

  reset() {
    this.stopSpinAudio();
    this.wheel?.reset();
  }

  before() {
    this.isSpinning = true;
  }

  after() {
    this.isSpinning = false;
    this.sessionServices.markUserAsSpun();
    logEvent(this.analytics, 'home_promo_wheel_spin_ended', { prize: this.idToLandOn });
    this.stopSpinAudio();
    this.giftMessage = "You have won " + this.items[this.idToLandOn].text + " on your first recharge"; // Show the corresponding prize
    setTimeout(() => {
      this.isWheelVisible = false;
      this.isGiftVisible = true;
      this.startWinAudio();
    }, 500);
  }

  handleSpinComplete(event: any) {
    this.isSpinning = false;
  }

  async spin(prize: number) {
    this.idToLandOn = prize;
    this.wheel?.spin();
  }

  startSpin() {
    this.playSpinAudio();
    this.spin(this.idToLandOn);
    logEvent(this.analytics, 'home_promo_wheel_spin_started', { prize: this.idToLandOn });
  }

  playSpinAudio() {
    this.spinAudio?.play().catch(error => {
      console.error('Error playing spin audio:', error);
    });
  }

  startWinAudio() {
    this.winAudio?.play().catch(error => {
      console.error('Error playing win audio:', error);
    });
  }

  stopWinAudio() {
    if (this.winAudio != null) {
      if (!this.winAudio.paused) {
        this.winAudio.pause();
        this.winAudio.currentTime = 0;
      }
    }
  }

  stopSpinAudio() {
    if (this.spinAudio != null) {
      if (!this.spinAudio.paused) {
        this.spinAudio.pause();
        this.spinAudio.currentTime = 0;
      }
    }
  }

  claimGift() {
    const promoCode = this.items[this.idToLandOn]?.promoCode;
    logEvent(this.analytics, 'home_promo_wheel_gift_claim', {
      reason: 'user_click',
      promoCode: promoCode
    });
    this.close();
    this.sessionServices.saveLuckyWheelPromoCode(promoCode)
    this.router.navigate(['/register'], { replaceUrl: true });
  }

  close() {
    logEvent(this.analytics, 'home_promo_wheel_closed', {
      reason: 'user_closed'
    });
    this.sessionServices.markUserAsSpun()
    this.stopSpinAudio();
    this.stopWinAudio();
    this.isVisible = false;
    this.reset();
  }

  ngOnDestroy(): void {
    this.stopSpinAudio();
    this.stopWinAudio();
  }
}
