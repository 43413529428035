<div class=" px-4 flex flex-col text-center rounded-lg border-gray-300 w-80 justify-around" style="background-color: #eee;">
    <span class="font-medium text-base text-main">Tip</span>
    <div class=" overflow-auto  text-left py-2">
        <div>Sorry, you cannot select numbers that are repeated. The following combinations will not be added to the shopping cart:</div>
        <div class="flex flex-wrap gap-2 mt-1">
            <div class="relative inline-flex  items-center justify-between whitespace-nowrap rounded-sm bg-gray-200 h-5 p-0 text-blue-600 text-xs max-w-fit" *ngFor="let combo of datas.duplicateCombination">
                <span class="flex-1 font-normal px-2" >{{ combo.combination.join("")}}</span>
            </div>
        </div>
    </div>
</div>
<div class="flex w-full pt-2" style="background-color: #eee;">
    <button class="w-1/2 border-gray-300 border-r border-t py-2" (click)="cancel()">Cancel</button>
    <button class="w-1/2 text-red-600 border-gray-300  border-t py-2" (click)="confirm()">Confirm</button>
</div>
