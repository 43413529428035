import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { BottomSheetExampleComponent } from 'src/app/bottom-sheet-example/bottom-sheet-example.component';

@Component({
  selector: 'app-sum',
  templateUrl: './sum.component.html',
  styleUrls: ['./sum.component.css']
})
export class SumComponent implements OnInit, OnChanges {
  buttons:any[] = [];

  constructor(private bottomSheet: MatBottomSheet,private betImages:DiceBetImageService) { }
  bottomSheetRef!: MatBottomSheetRef<any>; // Ensure type safety for bottom sheet reference

  @Input() isButtonDisabled: string = '';
  @Input() minRemainingTimeToComplete = 0;
  @Input() counter = 0;  // This input will be monitored via ngOnChanges
  @Input() gameId: string = '';
  @Output() diceData  = new EventEmitter()
  ngOnInit(): void { 
    this.buttons = this.betImages.getSumImages()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['counter'] && changes['counter'].currentValue <= this.minRemainingTimeToComplete) {
      this.closeBottomSheet();
    }
  }

  diceClicked(data: any) {
    this.openBetCountWindow(data);
  }

  openBetCountWindow(data: any) {
    const dataToSend = { ...data, sourceComponent: 'sum', predictionType: 'sum', gameId: this.gameId };
    this.bottomSheetRef = this.bottomSheet.open(BottomSheetExampleComponent, {
      data: dataToSend
    });
   
    this.bottomSheetRef.afterDismissed().subscribe((result: any) => { // Correct usage of subscribe here
      if (result?.payload?.code === 100 && result.payload.type === 'dice') { // Ensure proper null checks
        this.sendDiceData(result.payload.selectedBet); // Emit the data using EventEmitter
      }
    });
  }

  closeBottomSheet() {
    if (this.bottomSheetRef) {  
      this.bottomSheetRef.dismiss();
    }
  }
  sendDiceData(data: any) {
    this.diceData.emit(data); // Correctly emit the event with data
  }
}
