import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL_PATH = 'api/v1/lottery/';
const API_URL_PATH_USER = 'api/v1/users/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'channel': 'lottopi', 'version': environment.apiVersion })
};


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getUserInfo(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH_USER + 'info', httpOptions)
  }

  getAdminInfoInfo(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH_USER + 'adminInfo', httpOptions)
  }

  getPublicContent(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(this.baseUrl + API_URL_PATH + 'admin', { responseType: 'text' });
  }
}
