import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { LotteryService } from "src/app/_services/lottery.service";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: "app-my-orders",
  templateUrl: "./my-orders.component.html",
  styleUrls: ["./my-orders.component.css"],
})
export class MyOrdersComponent implements OnInit {
  predictionSets: any[] = [];
  myOrders: any[] = [];
  currentPage = 1;
  itemsPerPage = 5;
  totalItems = 0;
  totalPages = 0;
  private analytics: any;
  predictionStyle = [
    {
      predictionType: "TwoSide",
      prdictionOddEven: {
        a: { bgColor: "rgb(213, 115, 0)", textColor: "white", brs: "6px" },
        b: { bgColor: "rgb(0, 135, 212)", textColor: "white", brs: "6px" },
        c: { bgColor: "rgb(213, 0, 0)", textColor: "white", brs: "6px" },
        d: { bgColor: "rgb(103, 165, 25)", textColor: "white", brs: "6px" },
      },
    },
    {
      predictionType: "1D",
      style: [
        { bgColor: "rgb(223, 227, 237)", textColor: "black", brs: "100%" },
        { bgColor: "rgb(223, 227, 237)", textColor: "black", brs: "100%" },
      ],
    },
    {
      predictionType: "2D",
      style: [
        { bgColor: "rgb(213, 0, 0)", textColor: "white", brs: "100%" },
        { bgColor: "rgb(103, 165, 25)", textColor: "white", brs: "100%" },
      ],
    },
    {
      predictionType: "3D",
      style: [
        { bgColor: "rgb(0, 135, 212)", textColor: "white", brs: "100%" },
        { bgColor: "rgb(213, 0, 0)", textColor: "white", brs: "100%" },
        { bgColor: "rgb(103, 165, 25)", textColor: "white", brs: "100%" },
      ],
    },
    {
      predictionType: "4D",
      style: [
        { bgColor: "rgb(213, 115, 0)", textColor: "white", brs: "1px" },
        { bgColor: "rgb(0, 135, 212)", textColor: "white", brs: "100%" },
        { bgColor: "rgb(213, 0, 0)", textColor: "white", brs: "100%" },
        { bgColor: "rgb(103, 165, 25)", textColor: "white", brs: "100%" },
      ],
    },
    {
      predictionType: "FishPrawnCrab",
      style: { bgColor: "rgb(103, 165, 25)", textColor: "white", brs: "6px" },
    },
  ];

  constructor(private lotteryservice: LotteryService) { 
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void {
    this.getMyOrders();
  }

  getMyOrders(): void {
    this.lotteryservice
      .getMyOrders(this.currentPage, this.itemsPerPage)
      .subscribe({
        next: (data) => {
          this.myOrders = data.data;
          this.totalItems = data.totalCount;
          this.totalPages = data.totalPages;
          this.currentPage = data.currentPage;
        },
        error: (error) => {
          console.error("Error status:", error.status);
          console.error("Error message:", error.message);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.itemsPerPage = event.pageSize;

    logEvent(this.analytics, 'lottery_orders_pagination_change', {
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage,
      totalItems: this.totalItems
    });

    console.log('Pagination event logged:', {
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage
    });

    this.getMyOrders();
  }

  getPredictionStyle(prediction: string, type: string): any {
    let style = { bgColor: "transparent", textColor: "black", brs: "" };

    const styleConfig = this.predictionStyle.find(
      (p) => p.predictionType === type
    );

    if (styleConfig) {
      if (type === "TwoSide" && styleConfig.prdictionOddEven) {
        let key = prediction.toLowerCase();
        if (key in styleConfig.prdictionOddEven) {
          style =
            styleConfig.prdictionOddEven[
            key as keyof typeof styleConfig.prdictionOddEven
            ] || style;
        }
      } else if (Array.isArray(styleConfig.style)) {
        console.log("tupe", type, styleConfig);

        style = styleConfig.style[0] || style; // Use the first style as a fallback
      } else if (styleConfig.style) {
        style = styleConfig.style;
        // console.log('tupe',type,styleConfig);
      }
    }

    return style;
  }

  getDigitStyle(digit: string, type: string, index: number): any {
    let colors = [
      "rgb(213, 0, 0)",
      "rgb(103, 165, 25)",
      "rgb(0, 135, 212)",
      "rgb(223, 227, 237)",
    ];
    let style = {
      bgColor: colors[index % colors.length],
      textColor: "white",
      brs: "full",
    };

    return style;
  }
  getObjectKeys(obj: any): string[] {
    if (obj === null || obj === undefined) {
      return [];
    }
    return Object.keys(obj);
  }

}
