import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();

  openModal() {
    this.display = true;
    this.displayChange.emit(this.display);
  }

  closeModal() {
    this.display = false;
    this.displayChange.emit(this.display);
  }
}
