import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicate-popup',
  templateUrl: './duplicate-popup.component.html',
  styleUrls: ['./duplicate-popup.component.css']
})
export class DuplicatePopupComponent implements OnInit {
  datas:any

  constructor(public dialogRef: MatDialogRef<DuplicatePopupComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {  
  }
  
  ngOnInit(): void { 
    this.datas =  this.data
  }
  // confirm 
  confirm(){
    this.dialogRef.close(this.datas.nonDuplicate)
  }
  // closing the popup
  cancel(){
    this.dialogRef.close()
  }
}
