import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Subscription, take, timer } from "rxjs";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { WalletService } from "src/app/_services/wallet.service";
import { PlayPopupComponent } from "./play-popup/play-popup.component";
import { DiceService } from "src/app/_services/dice.service";
import { DiceBetImageService } from "src/app/_services/dice-bet-image.service";
import { AuthService } from "src/app/_services/auth.service";

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { MyOrderComponent } from "./my-order/my-order.component";
import { AnimationOptions } from "ngx-lottie";

interface Tab {
  title: string;
  selected: boolean;
}

@Component({
  selector: "app-dice-games",
  templateUrl: "./dice-game.component.html",
  styleUrls: ["./dice-game.component.css"],
})
// dice-game.component.ts
export class DiceGameComponent implements OnInit {
  isLoggedIn = true;
  isShuffling = false; // State to control dice shuffling
  shuffleTimeout: any; // Variable to store timeout reference
  diceImages: any[] = [];
  diceImages1: any[] = [];
  diceImages2: any[] = [];
  diceImages3: any[] = [];
  dice1Images: any[] = [];
  dice2Images: any[] = [];
  dice3Images: any[] = [];
  dice4Images: any[] = [];
  dice5Images: any[] = [];
  dice6Images: any[] = [];
  tabs = [
    { key: "total", label: "Sum", disabled: false },
    { key: "3same", label: "Triple", disabled: false },
    { key: "2same", label: "Double", disabled: false },
    { key: "different", label: "Single", disabled: false },
  ];
  activeTab: number = 1;
  private countDown: Subscription = new Subscription();
  blocked = false;
  title: string = "Dice-1minutes";
  selectedTime: string = "1min";
  totalWalletAmount = 0;
  dynamicWidth = "0%";
  bottomSheetRef!: MatBottomSheetRef;
  counter: number = 0;
  timer = "00:00";
  isButtonDisabled = "";
  minRemainingTimeToComplete = 0;
  issueNo = "";
  tick = 1000;
  selectedBottomTab = 0;
  selectedTab: string = "total";
  configId: number = 1;
  gameId: string = "";
  selectedBet: any[] = [];
  showWonStatusDialog = false
  wonAmount = 0
  callLatestResult: boolean = false
  countdownStart = false
  countDownAudio = new Audio();
  private countDownPlaying: boolean = false;
  winAudio = new Audio();
  private winAudioPlaying = false
  shuffleAudio = new Audio();
  private shuffleAudioPlaying = false
  routerSubscription?: Subscription;
  isLoading = false;
  betPlaced = false
  @ViewChild('orderTab') orderTabComponent?: MyOrderComponent;
  options: AnimationOptions = {
    path: '../../../assets/countdown.json',
    loop: false,
    autoplay: true
  };
  optionsForWin1: AnimationOptions = {
    path: '../../../assets/W4n40kZKLQ.json',
    loop: true,
    autoplay: true
  };

  optionsForWin2: AnimationOptions = {
    path: '../../../assets/WvyeTtaxax.json',
    loop: false,
    autoplay: true
  };

  private countDownEndedHandler?: () => void;
  private shuffleEndedHandler?: () => void;
  private winEndedHandler?: () => void;

  constructor(
    private tokenStorageService: TokenStorageService,
    private walletService: WalletService,
    private route: ActivatedRoute,
    private router: Router,
    private diceService: DiceService,
    private dialog: MatDialog,
    private diceBetImages: DiceBetImageService,
    private authservice: AuthService
  ) { }


  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: Event) {
    if (!document.hidden) {
      //window.location.reload();
      this.getNearestDice(this.configId)
    }
  }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
    this.initializeAudio();
    this.diceImages = this.diceBetImages.getDiceImages();
    this.diceImages1 = this.diceBetImages.getSumImages().slice(0, 2);
    this.diceImages2 = this.diceBetImages.getSumImages().slice(2, 4);
    this.diceImages3 = this.diceBetImages.getSumImages().slice(4);
    if (this.tokenStorageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.getWalletInfo();
    }
    this.route.queryParams.subscribe((params) => {
      const configId = +params["configId"];
      this.getNearestDice(configId);
      if ([1, 3, 5].includes(configId)) {
        this.getWinStatusIfAny(1)
        this.setTab(configId, this.getTabIndex(configId));
      } else {
        this.setTab(1, 0);
        this.getNearestDice(1);
        this.getWinStatusIfAny(1)
      }
    });
    this.shuffleDiceImages();
  }

  setupRouterSubscription(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.stopAllAudio();
      }
    });
  }

  stopAllAudio(): void {
    this.stopCounDownAudio()
    this.stopWinAudio()
    this.stopShuffleAudio()
  }

  initializeAudio(): void {
    if (!this.countDownAudio.src) {
      this.countDownAudio.src = '../../../assets/count_down.mp3';
      this.countDownAudio.load();
      this.countDownEndedHandler = () => {
        this.countDownPlaying = false;
      };
      this.countDownAudio.addEventListener('ended', this.countDownEndedHandler);
    }

    if (!this.shuffleAudio.src) {
      this.shuffleAudio.src = '../../../assets/rolling.mp3';
      this.shuffleAudio.load();
      this.shuffleEndedHandler = () => {
        this.shuffleAudioPlaying = false;
      };
      this.shuffleAudio.addEventListener('ended', this.shuffleEndedHandler);
    }

    if (!this.winAudio.src) {
      this.winAudio.src = '../../../assets/win_sound.mp3';
      this.winAudio.load();
      this.winEndedHandler = () => {
        this.winAudioPlaying = false;
      };
      this.winAudio.addEventListener('ended', this.winEndedHandler);
    }
  }

  handleTabClick(config: number, index: number): void {
    this.callLatestResult = true
    this.setTab(config, index);
    this.getNearestDice(config);
    this.blocked = false
    this.router.navigate([], {
      queryParams: { configId: config },
      queryParamsHandling: "merge",
    });
  }

  setTab(config: number, index: number): void {
    this.activeTab = index;
    this.configId = config;
    this.selectedTime = `${config}min`;
    this.title = `Dice ${config} minutes`;
    this.clearBets();
    this.countDown.unsubscribe();
    this.blocked = false;
    this.triggerShuffleAnimation(1);
  }
  shuffleDiceImages(): void {
    const availableImages = [...this.diceImages];
    const availableImage1 = [...this.diceImages1];
    const availableImage2 = [...this.diceImages2];
    const availableImage3 = [...this.diceImages3];

    // Ensure we always shuffle from a fresh copy
    this.dice1Images = this.getShuffledImages(availableImages);
    this.dice2Images = this.getShuffledImages(availableImages);
    this.dice3Images = this.getShuffledImages(availableImages);
    this.dice5Images = this.getShuffledImages(availableImage1);
    this.dice6Images = this.getShuffledImages(availableImage2);
    this.dice4Images = this.getShuffledImages(availableImage3);
  }

  getShuffledImages(images: string[]): string[] {
    if (images.length === 0) return [];
    const shuffled = [...images]
      .map((image) => ({ image, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ image }) => image);
    return shuffled;
  }
  setFinalDiceImages(configId: number): void {
    this.diceService.getLastWon(configId).subscribe({
      next: (data) => {
        const result = data.data;

        const firstDice = result.firstDiceNumber;
        const secondDice = result.secondDiceNumber;
        const thirdDice = result.thirdDiceNumber;
        const sumOfDice = result.sumOfDice;
        const isSmall = result.isSmall;
        const isOdd = result.isOdd;

        // Set dice images based on the dice numbers
        this.dice1Images = [this.getDiceImageForNumber(firstDice)];
        this.dice2Images = [this.getDiceImageForNumber(secondDice)];
        this.dice3Images = [this.getDiceImageForNumber(thirdDice)];

        // Set the sumOfDice as the 4th dice image
        this.dice4Images = [this.getSumDiceImage(sumOfDice)];

        // Set the 5th dice image based on isSmall (big/small)
        this.dice5Images = [this.getSmallBigImage(isSmall)];

        // Set the 6th dice image based on isOdd (odd/even)
        this.dice6Images = [this.getOddEvenImage(isOdd)];
      },
      error: (error) => {
        console.error("Error fetching last won dice data:", error);
      }
    });
  }

  // Function to get the image for individual dice numbers
  getDiceImageForNumber(diceNumber: number): any {
    return this.diceBetImages.getDiceImages().find((img) => img.singlePair === diceNumber);
  }

  // Function to get the image for sum of dice
  getSumDiceImage(sum: number): any {
    return this.diceBetImages.getSumImages().find((img) => img.sumGameTotalNumber === sum);
  }

  // Function to get the image for small/big
  getSmallBigImage(isSmall: boolean): any {
    return this.diceBetImages.getSumImages().find((img) => img.sumGame === (isSmall ? 'small' : 'big'));
  }

  // Function to get the image for odd/even
  getOddEvenImage(isOdd: boolean): any {
    return this.diceBetImages.getSumImages().find((img) => img.sumGame === (isOdd ? 'odd' : 'even'));
  }

  triggerShuffleAnimation(source: number): void {
    if (source != 1) {
      this.playShuffleAudio()
    }
    this.isShuffling = true; // Start shuffling
    clearTimeout(this.shuffleTimeout); // Clear any previous timeout
    this.shuffleTimeout = setTimeout(() => {
      this.isShuffling = false; // Stop shuffling after 2 seconds
    }, 1000);
    this.setFinalDiceImages(this.configId); // Set the final images
  }

  clearBets() {
    this.selectedBet = [];
  }

  getWalletInfo() {
    this.walletService.getInfo().subscribe({
      next: (data) => {
        this.totalWalletAmount = data.data.wallet.totalAmount;
      },
      error: (err) => {
        console.error("Error retrieving wallet info: " + err.message);
      },
    });
  }

  getNearestDice(configId: number): void {
    this.isLoading = true
    this.diceService.getNearestDice(configId).subscribe({
      next: (data) => {
        this.isLoading = false
        this.gameId = data.data._id;

        if (this.tokenStorageService.isLoggedIn()) {
          this.getMyCurrentBetIfAny(data.data._id);
        }

        // Clear any existing countdown before starting a new one
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
        // Update UI with new data
        this.issueNo = data.data.issueNo;
        this.counter = data.data.countdownTime + 2;
        this.minRemainingTimeToComplete = data.data.minRemainingSecond;

        // Start the countdown timer
        this.countDown = timer(0, this.tick).subscribe(() => {
          this.isButtonDisabled = "";
          --this.counter;

          this.dynamicWidth = this.getProgressFrom(configId, this.counter);

          // Block betting if countdown reaches minRemainingSecond
          if (this.counter <= data.data.minRemainingSecond) {
            this.bottomSheetRef?.dismiss();
            this.clearBets();
            this.isButtonDisabled = "disabled";
            if (this.counter > 3) {
              this.blocked = true;
            }
          }

          if (this.counter == 0) {
            this.blocked = false;
            this.countDown.unsubscribe();
          }

          if (this.counter === 3) {
            this.blocked = false;
            this.countdownStart = true
            this.playCoundDownAudio()
            setTimeout(() => {
              this.callLatestResult = true;
              this.countdownStart = false
              this.getNearestDice(configId);
              if (this.authservice.checkLoggedIn()) {
                this.getWinStatusIfAny(2);
              }
              this.triggerShuffleAnimation(2);
            }, 3000);
          }
        });
      },
      error: (err) => {
        this.isLoading = false
        console.error("Error retrieving nearest dice data: " + err.message);
      },
    });
  }

  getMyCurrentBetIfAny(gameId: string) {
    this.diceService.myBets(gameId).subscribe({
      next: (data) => {
        if (data.data.length > 0) {
          this.getMatchingButton(data.data);
        } else {
          this.clearBets();
        }
      },
      error: (err) => { },
    });
  }
  getMatchingButton(data: any) {
    this.selectedBet = []
    data.forEach((bet: any) => {
      if (bet.predictionType == "sum") {
        if (bet.sumGameTotalNumber) {
          // Find by sumGameTotalNumber if present
          this.selectedBet.push(
            this.diceBetImages
              .getSumImages()
              .find((btn) => btn.sumGameTotalNumber === bet.sumGameTotalNumber)
          );
        } else if (bet.sumGame !== null) {
          // Find by sumGame if sumGameTotalNumber is not present
          this.selectedBet.push(
            this.diceBetImages
              .getSumImages()
              .find((btn) => btn.sumGame == bet.sumGame)
          );
        }
      } else if (bet.predictionType == "single") {
        this.selectedBet.push(
          this.diceBetImages
            .getDiceImages()
            .find((btn) => btn.singlePair === bet.singlePair)
        );
      } else if (bet.predictionType == "double") {
        this.selectedBet.push(
          this.diceBetImages
            .getDiceImages()
            .find((btn) => btn.doublePair === bet.doublePair)
        );
      } else if (bet.predictionType == "triple") {

        const image = this.diceBetImages
          .getDiceImages()
          .find((btn) => btn.triplePair === bet.triplePair)
        if (image) {
          this.selectedBet.push(image)
        } else {
          this.selectedBet.push(this.diceBetImages.getQuestionImage())
        }

      }
    });
  }
  getProgressFrom(configId: number, remaining: number): string {
    let duration;
    switch (configId) {
      case 1:
        duration = 1;
        break;
      case 3:
        duration = 3;
        break;
      case 5:
        duration = 5;
        break;
      default:
        duration = 1;
    }
    let progress = (remaining / (duration * 60)) * 100;
    progress = Math.max(0, Math.min(100, progress));
    return `${progress}%`;
  }

  getTabIndex(configId: number): number {
    switch (configId) {
      case 1:
        return 0;
      case 3:
        return 1;
      case 5:
        return 2;
      default:
        return 0;
    }
  }

  onTabClick(index: number): void {
    if (index === 3) {
      if (!this.authservice.checkLoggedIn()) {
        this.router.navigate(['/login']);
        return;
      }
    }
    this.selectedBottomTab = index;
  }

  selectTab(tabKey: string): void {
    this.selectedTab = tabKey;
  }

  howToPlay() {
    this.dialog.open(PlayPopupComponent);
  }
  getSelectedBet(data: any) {
    this.getWalletInfo()
    this.selectedBet.push(data);
    this.betPlaced = true
    setTimeout(() => {
      this.betPlaced = false
    }, 3000)
    if (this.selectedBottomTab == 3) {
      this.orderTabComponent?.getMyOrders()
    }
  }
  closeWinDialog() {
    this.showWonStatusDialog = false;
    this.stopWinAudio()
  }

  playCoundDownAudio() {
    if (this.countDownPlaying) {
      return;
    }
    if (!document.hidden) {
      this.countDownPlaying = true
      this.countDownAudio.play();
    }
  }

  stopCounDownAudio() {
    this.countDownPlaying = false
    this.countDownAudio.pause();
    this.countDownAudio.currentTime = 0;
  }

  playShuffleAudio() {
    if (this.shuffleAudioPlaying) {
      return;
    }
    if (!document.hidden) {
      this.shuffleAudioPlaying = true
      this.shuffleAudio.play();
    }
  }

  stopShuffleAudio() {
    this.shuffleAudioPlaying = false
    this.shuffleAudio.pause();
    this.shuffleAudio.currentTime = 0;
  }


  ngOnDestroy(): void {
    this.stopAllAudio();

    if (this.countDownEndedHandler) {
      this.countDownAudio.removeEventListener('ended', this.countDownEndedHandler);
    }
    if (this.shuffleEndedHandler) {
      this.shuffleAudio.removeEventListener('ended', this.shuffleEndedHandler);
    }
    if (this.winEndedHandler) {
      this.winAudio.removeEventListener('ended', this.winEndedHandler);
    }
    this.routerSubscription?.unsubscribe();
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  getWinStatusIfAny(source: number) {
    if (this.tokenStorageService.isLoggedIn()) {
      this.diceService.getWinStatus().subscribe({
        next: data => {

          if (source != 1) {
            if (data.totalWinAmount > 0) {
              this.getWalletInfo()
            }
            this.clearBets()
          }
          if (source === 2 && data.totalWinAmount > 0) {
            if (this.selectedBottomTab == 3) {
              this.orderTabComponent?.getMyOrders()
            }
            this.stopCounDownAudio()
            this.playWinAudio()
            this.wonAmount = data.totalWinAmount
            this.getWalletInfo()
            this.showWonStatusDialog = true
            const countdownTimer = timer(1000, 1000).pipe(take(5 + 1));
            countdownTimer.subscribe({
              complete: () => {
                this.stopWinAudio()
                this.showWonStatusDialog = false
              }
            });
          }
        }
      })
    }
  }

  playWinAudio() {
    if (this.winAudioPlaying) {
      return;
    }
    if (!document.hidden) {
      this.winAudioPlaying = true
      this.winAudio.play();
    }
  }

  stopWinAudio() {
    this.winAudio.pause();
    this.winAudioPlaying = false
    this.winAudio.currentTime = 0;
  }

  handleBackClick() {
    this.stopCounDownAudio()
    this.stopShuffleAudio()
    this.stopWinAudio()
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  closeBetPlaceDialog() {
    this.betPlaced = false;
  }
}
