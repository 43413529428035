import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { ColorHomeComponent } from './games/color-game/color-home.component';
import { GamesHomeComponent } from './home/games-home/games-home.component';
import { WalletComponent } from './user/wallet/wallet.component';
import { ProfileComponent } from './user/profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardAdminComponent } from './admin/board-admin/board-admin.component';
import { AuthGuard } from './auth-guard/authguard';
import { AdminAuthGuard } from './auth-guard/admin-authguard'
import { PaymentComponent } from './payment/payment.component';
import { DummyComponent } from './dummy/dummy.component';
import { BetResultsComponent } from './bet-results/bet-results.component';
import { PayoutComponent } from './payout/payout.component';
import { LotteryGamesComponent } from './games/lottery-games/lottery-games.component';
import { DiceGameComponent } from './games/dice-game/dice-game.component';

const routes: Routes = [
  { path: 'dice-game', component: DiceGameComponent },
  { path: 'color-game', component: ColorHomeComponent },
  { path: 'lottery-game/:name', component: LotteryGamesComponent },
  { path: 'home', component: GamesHomeComponent },
  { path: 'wallet', component: WalletComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'dummy', component: DummyComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'user', component: BoardUserComponent, canActivate: [AuthGuard] },
  { path: 'mod', component: BoardModeratorComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: BoardAdminComponent, canActivate: [AuthGuard] },
  { path: 'account', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'paymentSuccess', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'withdraw', component: PayoutComponent, canActivate: [AuthGuard] },
  { path: 'results', component: BetResultsComponent },
  { path: 'admin-dashboard', component: BoardAdminComponent, canActivate: [AdminAuthGuard] },
  
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'timer', component: TimerComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
