<div class="h-auto">
    <div
      class="h-12 flex flex-row justify-between items-center px-3 "
      *ngFor="let winner of winners; let i = index"
    >
      <div class="flex flex-row items-center gap-2">
        <ng-container *ngIf="i < 3; else numberedWinner">
          <div
            class="w-7 h-7 flex items-center justify-center"
            [ngStyle]="{
              'background-image': 'url(../../../assets/dice-game/' + (i + 1) + 'winner.webp)',
              'background-size': 'cover'
            }"
          ></div>
        </ng-container>
        <ng-template #numberedWinner>
          <div class="w-7 h-7 flex items-center justify-center bg-gray-200 text-base text-gray-600 font-bold rounded">
            {{ i + 1 }}
          </div>
        </ng-template>
        <div class="text-gray-600 text-base">{{ winner.maskedContactNumber }}</div>
      </div>
      <div class="flex flex-row items-center text-gray-800 gap-1 w-20 justify-start font-bold">
        <div class="text-base mr-1">Won</div>
        <div class="text-base">₹{{ winner.wonAmount }}</div>
      </div>
    </div>
    <div class="mb-12"></div>
  </div>
  