import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BALL_GAME_URL_PATH = 'api/v1/color-game/';

@Injectable({
    providedIn: 'root'
})
export class BallGameService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    private getHttpOptions() {
        const token = localStorage.getItem('Authorization');

        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'channel': 'lottopi',
                'version': environment.apiVersion,
                'Authorization': `${token}`
            })
        };
    }

    getNearestLottery(gameConfig: number): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'getNearestLottery?configId=' + gameConfig, this.getHttpOptions());
    }

    getResult(gameId: string): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'getResult?gameId=' + gameId, this.getHttpOptions());
    }

    getLatest(configId: number, limit: number, page: number): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'getLatest?configId=' + configId + "&page=" + page + "&limit=" + limit, this.getHttpOptions());
    }

    getWinStatus(): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'winStatus', this.getHttpOptions());
    }

    placeBet(gameId: string,
        betAmount: number,
        betCount: number,
        predictionType: string,
        predictionColor: string,
        predictionNumber: number | null): Observable<any> {
        return this.http.post(this.baseUrl + API_BALL_GAME_URL_PATH + 'placeBet', {
            gameId,
            betAmount,
            betCount,
            predictionType,
            predictionColor,
            predictionNumber,
        }, this.getHttpOptions());
    }

    myBets(gameId: string): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'myBets?gameId=' + gameId, this.getHttpOptions())
    }

    getWinners(): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'topWinners', this.getHttpOptions())
    }

    getMyOrders(pageNumber: number, itemsPerPage: number): Observable<any> {
        return this.http.get(this.baseUrl + API_BALL_GAME_URL_PATH + 'orders' + "?page=" + pageNumber + "&pageSize=" + itemsPerPage, this.getHttpOptions())
    }
}
