import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DiceService } from 'src/app/_services/dice.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  currentPage = 0; // Start at the first page
  itemsPerPage = 10; // Set the default items per page
  totalItems = 0; // Total number of items
  totalPages = 0; // Total number of pages
  resultHistory: any[] = []; // Store the paginated result
  @Input()configId:number = 1
  @Input()callLatestResult :boolean =false
  buttons = [
    {id:1, imageSrc: '../../../../assets/dice-game/dice-single-1.cd31c2ce.webp', value: '150x', data: '', color: '' },
    {id:2, imageSrc: '../../../assets/dice-game/dice-single-2.ef5b7308.webp', value: '150x', data: '', color: '' },
    {id:3, imageSrc: '../../../assets/dice-game/dice-single-3.01040bed.webp', value: '150x', data: '', color: '' },
    {id:4, imageSrc: '../../../assets/dice-game/dice-single-4.aa0fa9c1.webp', value: '150x', data: '', color: '' },
    {id:5, imageSrc: '../../../assets/dice-game/dice-single-5.d76c07b5.webp', value: '150x', data: '', color: '' },
    {id:6, imageSrc: '../../../assets/dice-game/dice-single-6.3b088966.webp', value: '150x', data: '', color: '' },
  ];
    // Image sources for isOdd and isSmall conditions
  oddImageSrc = '../../../../assets/dice-game/dice-odd.8bffffcd.webp';
  evenImageSrc = '../../../../assets/dice-game/dice-even.6053bf51.webp';
  smallImageSrc = '../../../../assets/dice-game/dice-small.e17da5f9.webp';
  bigImageSrc = '../../../../assets/dice-game/dice-big.5586b309.webp';
  constructor(private diceService:DiceService) { }

  ngOnInit(): void {
    this.fetchResults()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configId'] || changes['callLatestResult']) {
      this.fetchResults();
    }
  }
  fetchResults(): void {    
    this.diceService.getDiceResults(this.configId, this.currentPage, this.itemsPerPage).subscribe({
      next: (response) => {
        this.resultHistory = response.data; 
        this.currentPage = response.pageNumber;
        this.totalItems = response.totalCount;
        this.totalPages = response.totalPages;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.fetchResults(); // Fetch the results for the new page
  }
  getImageSrcByDicenumber(diceNo:number):string{
    const button = this.buttons.find(btn => btn.id == diceNo)
    return button ? button.imageSrc:''
  }
  getOddImgSrc(isOdd:boolean){
    return isOdd ? this.oddImageSrc:this.evenImageSrc
  }
  getSmallImgsrc(isSmall:boolean){
    return isSmall ? this.smallImageSrc:this.bigImageSrc
  }
}