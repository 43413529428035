import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../../_services/token-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../_services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: any;
  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getUserInfo()
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  getUserInfo() {
    this.userService.getUserInfo().subscribe({
      next: data => {
        this.user = data.data
      }, error: err => {
        this._snackbar.open("Something is wrong", 'Close', {
          duration: 3000
        });
      }
    });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/home']);
  }

  handleBackClick() {
    this.location.back()
  }

  handleRechargeClick() {
    this.router.navigate(['/wallet']);
  }

  handleWithdrawClick() {
    this.router.navigate(['/withdraw']);
  }

}
