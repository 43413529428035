import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from './../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    
    // Check if the user is logged in
    const isLoggedIn = this.authService.checkLoggedIn();
    
    // Log the authentication status
    console.log('AuthGuard canActivate called. User is logged in:', isLoggedIn);
    
    // If not logged in, redirect to the login page
    if (!isLoggedIn) {
      console.log('User is not authenticated. Redirecting to login page.');
      this.router.navigate(['/login']);
      return false;
    }
    
    // If logged in, allow access
    console.log('User is authenticated. Access granted.');
    return true;
  }
}
