import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {

  transform(value: string): any {
    const now = new Date().getTime();
    const targetTime = new Date(value).getTime();
    const remainingTime = targetTime - now;

    if (remainingTime <= 0) {
      return { expired: true, days: 0, hours: '00', minutes: '00', seconds: '00' };
    }

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    return { days, hours: this.pad(hours), minutes: this.pad(minutes), seconds: this.pad(seconds) };
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
