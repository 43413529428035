<div class="flex justify-center w-full overflow-y-hidden h-screen">
    <div class="w-full min-w-sm max-w-md">
        <header class="toolbar header-row w-full z-50 top-0 left-0" style="box-shadow: 6px 4px 8px -8px ; ">
            <div class="nav-icon" (click)="handleBackClick()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"
                    style="width: 1rem; height: 1rem;">
                    <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </div>
            <div class="title cursor-pointer" (click)="togglePopup()">
                <span class="  font-medium text-base">{{lotteryData?.name}}</span>
                <mat-icon class="arrow" [class.rotated]="isPopupVisible">expand_more</mat-icon>
            </div>
            <div class="actions" *ngIf="isLoggedIn">
                <div class="wallet-container">
                    <span class="wallet-display">Balance</span>
                    <span class="amount-display">₹{{ totalWalletAmount }}</span>
                </div>
                <a class="action-button" routerLink="/wallet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c"
                        class="bi bi-wallet-fill" viewBox="0 0 16 16">
                        <path
                            d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
                        <path
                            d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
                    </svg>
                </a>
            </div>
        </header>

        <div *ngIf="progressBar" class="flex justify-center fixed inset-0 items-center h-screen">
            <svg class="w-12 h-12 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
                xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path
                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                    stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                    stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"
                    class="text-red-500">
                </path>
            </svg>
        </div>

        <div class="betting-close-overlay" *ngIf="blocked">
            <div class="close-message flex flex-col items-center w-full">
                <img src="../../assets/error.png" />
                <h4>Betting closed in the last {{minRemainingTimeToComplete}}</h4>
            </div>
        </div>

        <div class="loader-overlay countdown fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            *ngIf="countdownStart">
            <ng-lottie [options]="options" class="w-80 h-80"></ng-lottie>
        </div>

        <div class="bet-success-alert-container text-center center" *ngIf="betPlaced">
            <button class="close-btn" aria-label="Close" (click)='closeBetPlaceDialog()'>
                &times;
            </button>
            <h2><b>Paid Successfully</b></h2>
            <span class="justify-around text-center">Your tickets have been successfully purchased. Please take a
                    note of the draw time and check the results promptly.</span>
        </div>

        <div class="win-full-container" *ngIf="showWonStatusDialog" (click)='closeWinDialog()'>
            <div class="win-container">
                <div class="win-animation">
                  <ng-lottie [options]="optionsForWin1" class="absolute inset-0 w-full h-full z-0 opacity-50"></ng-lottie>
                    <button class="close-btn" aria-label="Close" (click)='closeWinDialog()'>
                        &times;
                    </button>
                    <h2 class="text-center mb-4">You have won <b>₹{{wonAmount}}</b></h2>
        
                    <div class="flex justify-center items-center mb-4 space-x-4">
                        <ng-lottie [options]="optionsForWin2" class="w-40 h-40"></ng-lottie>
                    </div>
        
                    <!-- Second line with the wallet text -->
                    <span class="wallet-add-text text-center block">
                        <b>₹{{wonAmount}}</b> Has been added to your wallet
                    </span>
                </div>
            </div>
        </div>    

        <div class="scrollable-region overflow-y-auto max-h-full pt-1">
            <div class="w-full min-w-max padd-20-ver"
                style=" background:linear-gradient(180deg, #B5AEAE 0%, #CFC7C7 100%)">
                <div class="flex items-center pb-2 mb-2 border-b border-[#9E9696] mx-3 mar-bot-10">
                    <div
                        class="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 transition-background !duration-300 before:transition-opacity before:!duration-300 size-11 rounded-full">
                        <div>
                            <div class="rounded-large w-12 md:w-20">
                                <img [src]=image?.src class="" />
                            </div>
                        </div>
                    </div>
                    <div class="ml-2 text-sm flex flex-col gap-1">
                        <span class="text-xs md:text-base font-bold">{{lotteryData?.name}}</span>
                        <span class="text-acc font-medium text-xs md:text-lg">Draw <span
                                class="text-gray-500">{{draw}}</span></span>
                    </div>
                    <div class="flex-1"></div>
                    <div class="flex items-center font-bold text-white" style="font-size:1rem;line-height:1rem">
                        <div class="flex items-center justify-center rounded mr-2 text-black"
                            *ngIf="countdownData?.days > 0">
                            <span class="font-semibold">{{ countdownData.days }} Days</span>
                        </div>
                        <div class="flex items-center justify-center rounded"
                            style="height:1.75rem;width:1.625rem;color:white; background-color:black">
                            {{ countdownData?.hours }}
                        </div>
                        <span class="text-black mx-0.5" style="color:black">:</span>
                        <div class="flex items-center justify-center rounded"
                            style="height:1.75rem;width:1.625rem;color:white; background-color:black">
                            {{ countdownData?.minutes }}
                        </div>
                        <span class="text-black mx-0.5" style="color:black">:</span>
                        <div class="flex items-center justify-center rounded"
                            style="height:1.75rem;width:1.625rem;color:white; background-color:black">
                            {{ countdownData?.seconds }}
                        </div>
                    </div>
                </div>
                <div class="flex mx-3 mar-top-10 ">
                    <div class="flex flex-col gap-1 min-w-[12.5rem]">
                        <div class="flex flex-row text-xs md:text-lg text-acc justify-between">
                            <span>Previous Result</span>
                        </div>
                        <div class="text-xs md:text-lg flex justify-end pr-0.5" style="background-color: #D5CECE;">
                            <span class="w-6 text-center" style="color:#D57300">A</span>
                            <span class="w-6 text-center" style="color:#0087D4">B</span>
                            <span class="w-6 text-center" style="color:#D50000">C</span>
                            <span class="w-6 text-center" style="color:#67A519">D</span>
                        </div>
                        <div class="flex flex-col text-xs md:text-lg text-white font-bold">
                            <div class="flex justify-between mt-1 items-center">
                                <span class="text-sec w-[4.125rem]">1st Prize:</span>
                                <div class="flex ml-1">
                                    <div *ngFor="let prize of getCharacters(); let i = index"
                                        class="w-5 h-5 rounded-full mr-1 text-sm flex items-center justify-center"
                                        [ngClass]="{'disabled': getDisabledIndexes().includes(i)}"
                                        [ngStyle]="{'background-color': getBackgroundColor(i)}">
                                        {{ prize }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 flex">
                        <div class="flex-1 border-r border-gray-500"></div>
                        <div class="flex-1"></div>
                    </div>
                    <div class="flex flex-col items-end justify-center">
                        <div class="flex items-center text-xs md:text-lg w-[3.75rem] justify-between p-0.5 gap-1"
                            (click)="showPopup()">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" style="height:1.25rem;width:1.25rem"
                                viewBox="0 0 20 20">
                                <circle cx="10" cy="10" r="8.75" fill="#000"></circle>
                                <path fill="#fff"
                                    d="M8.73 11.34c-.239-1.783 2.182-2.18 2.182-3.283 0-.693-.546-1.08-1.443-1.08-.693 0-1.262.318-1.818.898l-.932-.852c.727-.841 1.727-1.398 2.943-1.398 1.648 0 2.852.773 2.852 2.284 0 1.66-2.488 1.864-2.307 3.432zm.75 3.035c-.59 0-1.011-.42-1.011-1.011s.432-1 1.011-1c.568 0 1 .409 1 1 0 .59-.432 1.011-1 1.011">
                                </path>
                            </svg>
                            Rules
                        </div>
                        <!-- POPUP -->

                        <button (click)="scrollTo($event)"
                            class="z-0 group w-16 relative inline-flex items-center justify-around box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-small gap-unit-2 [&amp;&gt;svg]:max-w-[theme(spacing.unit-8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover px-1.5 mt-4 rounded h-7 min-h-7 min-w-7 bg-gray-300"
                            type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" style="height:1.25rem;width:1.25rem"
                                viewBox="0 0 20 20" class="mr-1.5">
                                <g clip-path="url(#a)">
                                    <path stroke="#31373D" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" d="M1.666 18.334h16.667"></path>
                                    <path fill="#31373D"
                                        d="m1.666 10.834 3.333.833v4.167H1.666zM8.334 10l3.333-1.666v7.5H8.334zM15 6.667 18.333 5v10.833H15z">
                                    </path>
                                    <path stroke="#31373D" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" d="m1.666 7.5 3.333.833 13.334-6.667h-4.167"></path>
                                </g>
                                <defs>
                                    <clipPath id="a">
                                        <rect fill="#fff" style="height:1.25rem;width:1.25rem"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" style="width:0.875rem;height:0.875rem"
                                viewBox="0 0 12 12" fill="none">
                                <path d="M2.77778 5L5.88889 8.11111L9 5" stroke="#0303" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col justify-end px-3 h-12 min-h-12 bg-white w-full" aria-label="Options">
                <div class="flex-nowrap scrollbar-hide bg-transparent w-full h-full flex items-end relative rounded-none p-0 border-b border-gray-200 overflow-auto gap-8"
                    aria-label="Options" role="tablist" aria-orientation="horizontal">
                    <button *ngFor="let lotteryType of lotteryTypes; let i = index"
                        (click)="changeTab(i, lotteryType.label)" [class.text-main]="selectedTab === i"
                        [class.border-b-2]="selectedTab === i" [class.border-red-600]="selectedTab === i"
                        [class.font-bold]="selectedTab === i"
                        class="z-0 w-full py-1 flex group relative justify-center items-center cursor-pointer transition-opacity outline-none text-sm rounded-none max-w-fit px-0 h-7 text-sec"
                        role="tab" [attr.aria-selected]="selectedTab === i">
                        <div class="relative z-10 whitespace-nowrap transition-colors game-label">{{ lotteryType.label
                            }}</div>
                    </button>
                </div>
            </div>

            <div class="w-full " [ngSwitch]="selectedTab">
                <app-twosides *ngSwitchCase="0" [selectedGame]="selectedGame"></app-twosides>
                <app-fish-prawn *ngSwitchCase="1" [selectedGame]="selectedGame"></app-fish-prawn>
                <app-one-d *ngSwitchCase="2" [selectedGame]="selectedGame"></app-one-d>
                <app-two-d *ngSwitchCase="3" [duplicateCombination]="dupliacteCombination"
                    [nonDuplicates]="nonDuplicateCombination" (dataBack)="dataRecieve($event)"
                    [clearSelection]="clearSelection"></app-two-d>
                <app-three-d *ngSwitchCase="4" [duplicateCombination]="dupliacteCombination"
                    [nonDuplicates]="nonDuplicateCombination" (dataBack)="dataRecieve($event)"
                    [clearSelection]="clearSelection"></app-three-d>
                <app-four-d *ngSwitchCase="5"></app-four-d>
            </div>
            <div class=" flex flex-col justify-end  px-3 h-12  min-h-12 bg-white w-full" aria-label="Options">
                <div class="flex-nowrap scrollbar-hide bg-transparent w-full h-full flex items-end relative rounded-none p-0 border-b border-gray-200 overflow-auto gap-8"
                    aria-label="Options" role="tablist" aria-orientation="horizontal">
                    <button *ngFor="let label of ['Result History' ,'Analyse', 'My Orders']; let i = index"
                        (click)="onTabClick(i)" [class.text-main]="selectedBottomTab === i"
                        [class.border-b-2]="selectedBottomTab === i" [class.border-red-600]="selectedBottomTab === i"
                        [class.font-bold]="selectedBottomTab === i"
                        class="z-0 w-full py-1 flex group relative justify-center items-center cursor-pointer transition-opacity outline-none text-sm rounded-none max-w-fit px-0 h-7 text-sec"
                        role="tab" [attr.aria-selected]="selectedBottomTab === i">
                        <div class="relative z-10 whitespace-nowrap transition-colors">{{ label }}</div>
                    </button>
                </div>
            </div>
            <div class="w-full pb-60" [ngSwitch]="selectedBottomTab" id="chart">
                <app-result-history *ngSwitchCase="0" [lotteryName]="lotteryData?.name"></app-result-history>
                <app-chart2 *ngSwitchCase="1" [selectedGame]="lotteryData.name"></app-chart2>
                <app-my-orders #orderTab *ngSwitchCase="2"></app-my-orders>
            </div>

            <div class="flex p-3 justify-between max-w-md  w-full cart fixed left-auto bottom-0 bg-white"
                (click)="showCart()">
                <div class="flex items-center">
                    <div class="w-9 mr-2">
                        <div class="rounded-lg" style="max-width: fit-content;">
                            <img src="../../../assets/shop-car.webp" class="h-8 w-8 rounded-none" alt="cart">
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span class="text-main font-bold text-xl leading-6"
                            *ngIf="!isLoading">₹{{getTotalAmount()}}</span>
                        <span class="text-acc text-xs">{{count -1}} number</span>
                    </div>
                </div>
                <button class="h-11 w-36 text-white rounded-full flex items-center justify-center"
                    (click)="payNow($event)" [disabled]="getTotalAmount() <= 0 || isLoading"
                    [ngClass]="{ 'bg-red-600': getTotalAmount() > 0 && !isLoading, 'bg-gray-300': getTotalAmount() <= 0 || isLoading }">

                    <ng-container *ngIf="isLoading; else payNowText">
                        <div class="loader"></div>
                    </ng-container>

                    <ng-template #payNowText>
                        Pay Now
                    </ng-template>

                </button>
            </div>
            <!-- Custom Bottom Sheet -->
            <div class="bottom-sheet max-w-md mx-auto" [class.visible]="bottomSheetVisible">
                <header class="flex items-center justify-between font-semibold p-1 text-sm">
                    <div class="flex items-center cursor-pointer  h-7" (click)="closeBottomSheet()">
                        <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="24px"
                            fill="#5f6368">
                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                        </svg>
                        <span class="ml-1 text-black font-bold text-lg">Bet Slip</span>
                    </div>
                    <div class="w-6 h-6" (click)="removeAllGames()">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                            fill="#5f6368">
                            <path
                                d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                        </svg>
                    </div>
                </header>
                <div class="overflow-y-scroll pb-5" style="height: 90%;">
                    <div class="p-3 rounded-xl bg-gray-100 font-bold mb-2 "
                        *ngFor="let item of selectedGame;let i =index">
                        <div class="flex items-center mb-3">
                            <span class="border-r-2 border-gray-400 pr-1">1st-prize</span>
                            <span class="mx-1">{{item.selectedTab}}</span>
                            <ng-container *ngIf="item.value; else combinationVal">
                                <div class="px-1 h-5 flex items-center rounded-sm text-white mr-1"
                                    [ngStyle]="{'background-color':item.color}">{{item.value}}</div>
                            </ng-container>
                            <ng-template #combinationVal>
                                <ng-container *ngFor="let value of item.combination;let j =index">
                                    <div class="px-1 h-5 flex items-center  text-white mr-1"
                                        [ngStyle]="{'background-color':item.color[j]}" style="border-radius: 100%;">
                                        {{value}}</div>
                                </ng-container>
                            </ng-template>

                            <span class="font-medium">@<span class="text-gray-700">1.95</span></span>
                            <div class="flex flex-1 justify-end">
                                <div (click)="removeGame(i)">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        class="w-6 h-6">
                                        <path d="M5 7.80005H6.55556H19" stroke="currentColor" stroke-width="1.55556"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path
                                            d="M17.4446 7.8V17.6C17.4446 17.9713 17.2807 18.3274 16.9889 18.5899C16.6972 18.8525 16.3016 19 15.889 19H8.11122C7.69866 19 7.303 18.8525 7.01128 18.5899C6.71955 18.3274 6.55566 17.9713 6.55566 17.6V7.8M8.889 7.8V6.4C8.889 6.0287 9.05289 5.6726 9.34461 5.41005C9.63633 5.1475 10.032 5 10.4446 5H13.5557C13.9682 5 14.3639 5.1475 14.6556 5.41005C14.9473 5.6726 15.1112 6.0287 15.1112 6.4V7.8"
                                            stroke="currentColor" stroke-width="1.55556" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path d="M10.4443 11.3V15.5" stroke="currentColor" stroke-width="1.55556"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M13.5557 11.3V15.5" stroke="currentColor" stroke-width="1.55556"
                                            stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="group flex flex-col w-40 bg-white rounded-lg">
                                <div class="h-full">
                                    <div
                                        class="relative w-full inline-flex shadow-sm min-h-10 flex-col items-start justify-center gap-0 transition duration-150 border border-gray-300 py-0 px-3 h-10 rounded-lg cursor-text">
                                        <div class="inline-flex w-full items-center h-full box-border">
                                            <input class="w-full bg-transparent outline-none text-sm font-bold"
                                                aria-label="Betting amount" placeholder="Betting amount"
                                                max={{getMaxBetAmount()}} min={{getMinBetAmount()}} type="number"
                                                [(ngModel)]="item.betAmount">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col text-xs text-gray-500 font-medium ml-3">
                                <span>Min Bet:<span class="ml-1">{{ getMinBetAmount() }}</span></span>
                                <span>Max Bet:<span class="ml-1">{{ getMaxBetAmount() }}</span></span>
                            </div>
                        </div>
                        <div class="text-xs text-gray-500 font-medium my-2">Est. winning:{{((item.betAmount??0) *
                            1.95).toFixed(2)}} </div>
                        <div class="flex gap-4 text-white text-sm font-medium">
                            <button
                                class=" flex-1 py-2 items-center justify-center  whitespace-nowrap font-normal outline-none transition-transform-colors-opacity bg-green-700 text-white hover:opacity-90  rounded shadow-md"
                                type="button" style="box-shadow: rgb(16, 121, 58) 0px 2px 0px 0px;"
                                (click)="incrementBetAmount(item, 20)">+20</button>
                            <button
                                class=" flex-1 py-2 items-center justify-center  whitespace-nowrap font-normal outline-none transition-transform-colors-opacity  text-white hover:opacity-90  rounded shadow-md"
                                type="button"
                                style="box-shadow: rgb(25, 105, 123) 0px 2px 0px 0px; background-color: rgb(34, 139, 163);"
                                (click)="incrementBetAmount(item, 50)">+50</button>
                            <button
                                class="flex-1 py-2 items-center justify-center  whitespace-nowrap font-normal outline-none transition-transform-colors-opacity bg-blue-700 text-white hover:opacity-90  rounded shadow-md"
                                type="button" style="box-shadow: rgb(23, 52, 125) 0px 2px 0px 0px;"
                                (click)="incrementBetAmount(item, 100)">+100</button>
                            <button
                                class="flex-1 py-2 items-center justify-center  whitespace-nowrap font-normal outline-none transition-transform-colors-opacity bg-purple-700 text-white hover:opacity-90  rounded shadow-md"
                                type="button" style="box-shadow: rgb(102, 36, 113) 0px 2px 0px 0px;"
                                (click)="incrementBetAmount(item, 500)">+500</button>
                            <button
                                class="flex-1 py-2 items-center justify-center  whitespace-nowrap font-normal outline-none transition-transform-colors-opacity  text-white hover:opacity-90  rounded shadow-md"
                                type="button"
                                style="box-shadow: rgb(42, 46, 52) 0px 2px 0px 0px;background-color: #5f6975;"
                                (click)="incrementBetAmount(item, getMaxBetAmount()  - (item.betAmount??0))">Max</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-home-bottom-nav></app-home-bottom-nav>
</div>