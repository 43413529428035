import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL_PATH = 'api/v1/admin/payment/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'channel': 'lottopi', 'version': environment.apiVersion })
};

@Injectable({
    providedIn: 'root'
})
export class AdminDashboardService {
    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    myBets(gameId: string): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'myBets?gameId=' + gameId, httpOptions)
    }

    getPendingPayoutRequest(page: number, totalItems: number): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getPendingPayouts?page=${page}&totalItems=${totalItems}', httpOptions)
    }

    approvePayouts(selectedPayoutIds: string[]): Observable<any> {
        return this.http.post(this.baseUrl + API_URL_PATH + 'approvePayouts', { payoutIds: selectedPayoutIds }, httpOptions)
    }

    getProcessingPayouts(page: number, totalItems: number): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getProcessingPayouts?page=${page}&totalItems=${totalItems}', httpOptions)
    }

    getBalance(): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getBalance', httpOptions)
    }

    getCompletedPayouts(page: number, totalItems: number): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getCompletedPayouts?page=${page}&totalItems=${totalItems}', httpOptions)
    }

    getSepPayOutFailures(page: number, itemsPerPage: number, startDate: string, endDate?: string): Observable<any> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('itemsPerPage', itemsPerPage.toString());

        if (startDate) {
            params = params.set('startDate', startDate);
        }
        if (endDate) {
            params = params.set('endDate', endDate);
        }

        return this.http.get(`${this.baseUrl}${API_URL_PATH}payoutFailures`, { params, ...httpOptions });
    }
}