<div class="popular-games-container">
    <carousel [itemsPerSlide]="2" [noWrap]="false" [singleSlideOffset]="true" [showIndicators]="false">
      <slide>
        <img class="slide-image" src="../../assets/popular-banner-1.jpeg" alt="Game 1">
      </slide>
      <slide>
        <img class="slide-image" src="../../assets/popular-banner-2.jpeg" alt="Game 2">
        </slide>
      <slide>
        <img class="slide-image" src="../../assets/popular-banner-3.jpeg" alt="Game 3">
      </slide>
    </carousel>
  </div>
  