<div class=" mt-6 p-4"*ngIf="modalType === 'first'">
    <div>
        <div class="w-full h-38 flex justify-center">
            <img src="../../../assets/dice-game/dice-single-6.3b088966.webp" style="width: 80px;" alt="">
        </div>
        <div class="w-full h-38 flex justify-center">
            <div>
                <img src="../../../assets/dice-game/dice-single-6.3b088966.webp" style="width: 80px;" alt="">
            </div>
            <div class="">
                <img src="../../../assets/dice-game/dice-single-6.3b088966.webp"  style="width: 80px;" alt="">
            </div>
        </div>
    </div>
    <div class="mt-3">
    <p  class="text">Choose 3 same numbers. lf the <br> <span class="mt-3 block"> drawresults match your selection,</span><br> <span class="block"> you win. </span> </p>
</div>
      
  </div> 


<!-- second modal -->

  <div class=" mt-6 p-4" *ngIf="modalType === 'second'">
    <div>
        <div class="w-full h-38 flex justify-center">
            <img src="../../../../assets/dice-game/dice-single-question.b690ec8b.webp" style="width: 80px;" alt="">
        </div>
        <div class="w-full h-38 flex justify-center">
            <div>
                <img src="../../../../assets/dice-game/dice-single-question.b690ec8b.webp" style="width: 80px;" alt="">
            </div>
            <div class="">
                <img src="../../../../assets/dice-game/dice-single-question.b690ec8b.webp"  style="width: 80px;" alt="">
            </div>
        </div>
    </div>
    <div class="mt-3">
    <p  class="text">Choose 3 same numbers. lf the <br> <span class="mt-3 block"> drawresults match your selection,</span><br> <span class="block"> you win. </span> </p>
</div>
      
  </div> 

  <!-- thirdModal -->

  
  <div class=" mt-6 p-4" *ngIf="modalType === 'double'">
    <div>
        
        <div class="w-full h-38 flex justify-center">
            <div>
                <img src="../../../../assets/dice-game/dice-single-4.aa0fa9c1.webp" style="width: 80px;" alt="">
            </div>
            <div class="">
                <img src="../../../../assets/dice-game/dice-single-4.aa0fa9c1.webp"  style="width: 80px;" alt="">
            </div>
        </div>
    </div>
    <div class="mt-3">
    <p  class="text">Choose 2 same numbers. lf the <br>
    <span class="mt-3 block"> drawresults match your selection,</span><br> 
    <span class="block">you win(unless 3 numbers are the. </span><br>
    <span>same)</span>
    </p>
</div>
      
  </div> 

  <!-- fourthModal -->

  <div class=" mt-6 p-4" *ngIf="modalType === 'single'">
    <div>
        
        <div class="w-full h-38 flex justify-center">
            <div>
                <img src="../../../../assets/dice-game/dice-single-1.cd31c2ce.webp" style="width: 80px;" alt="">
            </div>
            <div>
                <img src="../../../../assets/dice-game/dice-single-2.ef5b7308.webp" style="width: 80px;" alt="">
            </div>
            <div class="">
                <img src="../../../../assets/dice-game/dice-single-4.aa0fa9c1.webp"  style="width: 80px;" alt="">
            </div>
        </div>
    </div>
    <div class="mt-3">
    <p  class="text">Choose 3 or more different <br>
    <span class="mt-3 block"> numbers. If thedraw results match</span><br> 
    <span class="block">your selection, you win. </span><br>
    </p>
</div>
      
  </div> 