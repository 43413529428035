<div class="main w-full mx-auto">
  <div class="flex flex-col gap-4 bg-white">
    <div class="betting-slip w-full" *ngFor="let order of myOrders">
      <div class="header px-4 py-2">
        <div class="game-info">
          <h2>{{ order?.gameId?.name | uppercase }}</h2>
          <!-- <p>Draw time<br><span>17/08/2024 02:56 PM</span></p> -->
        </div>
      </div>

      <div class="flex order-details">
        <div class=" mx-auto font-semibold text-base">
          <span>Game Id: &nbsp;</span>
          <span>{{order.gameId._id |trimUppercase}}</span>
        </div>
        <div class="mx-auto font-semibold text-base">
          <span>Order Id:&nbsp;</span>
          <span>{{ order._id | trimUppercase }}</span>
        </div>
      </div>

      <div class="flex">
        <div class=" mx-auto font-semibold text-base">
          <span>1st price:</span>
          <span>{{ order?.gameId?.luckyDraw?.firstPrize}}</span>
        </div>
        <div class="mx-auto font-semibold text-base">
          <span>Total Won:</span>
          <span>₹{{ order?.wonAmount}}</span>
        </div>
      </div>

      <!-- <div class="betting-time">
            <p><strong>Betting time</strong> 17/08/2024 02:55 PM</p>
            <p *ngIf="wonStatus == 'InProgress'" class="font-bold">To be Drawn</p>
            <p *ngIf="wonStatus !== 'InProgress'" class="font-bold">Result published</p>
            <p class="payment"><strong>₹{{totalAmount}}</strong></p>
           
        </div> -->
      <!-- Responsive Horizontal Line -->
      <hr class="divider" />
      <div class="my-numbers ">
        <!-- Header Row -->
        <div class="grid grid-cols-3 text-center py-2 border-b border-gray-300 bg-gray-100">
          <div class="font-semibold">NUMBERS</div>
          <div class="font-semibold">PAYMENT</div>
          <div class="font-semibold">RESULT</div>
        </div>

        <!-- Table Rows -->
        <div class="divide-y divide-gray-300">
          <!-- Loop through TwoSide predictions -->
          <div *ngFor="let key of getObjectKeys(order.predictionOddEven)">
            <div *ngIf="order.predictionOddEven[key].length > 0">
              <div *ngFor="let predictionObj of order.predictionOddEven[key]">
                <div class="grid grid-cols-3 text-center py-4">
                  <div [ngStyle]="{
                      'background-color': getPredictionStyle(key, 'TwoSide')
                        .bgColor,
                      color: getPredictionStyle(key, 'TwoSide').textColor,
                      'border-radius': getPredictionStyle(key, 'TwoSide').brs
                    }">
                    <span class="inline-block py-2 rounded-full">
                      {{ predictionObj.prediction }}
                    </span>
                  </div>
                  <div class="text-gray-700 font-semibold mt-2">
                    ₹{{ predictionObj.betAmount }}
                  </div>
                  <div class="font-semibold flex flex-col items-center"
                    *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                    <span>Won</span>
                    <span>...</span>
                  </div>

                  <ng-template #wonAmount>
                    <div class="font-semibold flex flex-col items-center">
                      <span [ngClass]="{ 'text-green-400': predictionObj.winAmount > 0 }">Won</span>
                      <span [ngClass]="{ 'text-green-400 font-bold': predictionObj.winAmount > 0 }">₹{{
                        predictionObj.winAmount }}</span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let predictionObj of order.fishPrawnCrabArray">
            <div class="grid grid-cols-3 text-center py-4">
              <div [ngStyle]="{
                  'background-color': getPredictionStyle(
                    predictionObj.prediction,
                    'FishPrawnCrab'
                  ).bgColor,
                  color: getPredictionStyle(
                    predictionObj.prediction,
                    'FishPrawnCrab'
                  ).textColor,
                  'border-radius': getPredictionStyle(
                    predictionObj.prediction,
                    'FishPrawnCrab'
                  ).brs
                }">
                <span class="inline-block px-2 py-2 rounded-full">
                  {{ predictionObj.prediction }}
                </span>
              </div>
              <div class="text-gray-700 font-semibold">
                ₹{{ predictionObj.betAmount }}
              </div>
              <div class="font-semibold flex flex-col items-center"
                *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                <span>Won</span>
                <span>...</span>
              </div>

              <ng-template #wonAmount>
                <div class="font-semibold flex flex-col items-center">
                  <span [ngClass]="{ 'text-red-500': predictionObj.winAmount > 0 }">Won</span>
                  <span [ngClass]="{ 'text-red-500 font-bold': predictionObj.winAmount > 0 }">₹{{
                    predictionObj.winAmount }}</span>
                </div>
              </ng-template>
            </div>
          </div>

          <!-- Loop through 1D predictions -->
          <div *ngFor="let predictionObj of order.prediction1DArray">
            <div class="grid grid-cols-3 text-center py-4">
              <div>
                <span *ngFor="
                    let digit of predictionObj.prediction.split('');
                    let i = index
                  " [ngStyle]="{
                    'background-color': getDigitStyle(digit, '1D', i).bgColor,
                    color: getDigitStyle(digit, '1D', i).textColor,
                    'border-radius': '100%'
                  }" class="inline-block px-2 py-1 rounded-full mx-1">
                  {{ digit }}
                </span>
              </div>
              <div class="text-gray-700 font-semibold">
                ₹{{ predictionObj.betAmount }}
              </div>
              <div class="font-semibold flex flex-col items-center"
                *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                <span>Won</span>
                <span>...</span>
              </div>

              <ng-template #wonAmount>
                <div class="font-semibold flex flex-col items-center">
                  <span [ngClass]="{ 'text-red-500': predictionObj.winAmount > 0 }">Won</span>
                  <span [ngClass]="{ 'text-red-500 font-bold': predictionObj.winAmount > 0 }">₹{{
                    predictionObj.winAmount }}</span>
                </div>
              </ng-template>
            </div>
          </div>

          <!-- Loop through 2D predictions -->
          <div *ngFor="let predictionObj of order.prediction2DArray">
            <div class="grid grid-cols-3 text-center py-4">
              <div>
                <span *ngFor="
                    let digit of predictionObj.prediction.split('');
                    let i = index
                  " [ngStyle]="{
                    'background-color': getDigitStyle(digit, '2D', i).bgColor,
                    color: getDigitStyle(digit, '2D', i).textColor,
                    'border-radius': '100%'
                  }" class="inline-block px-2 py-1 rounded-full mx-1">
                  {{ digit }}
                </span>
              </div>
              <div class="text-gray-700 font-semibold">
                ₹{{ predictionObj.betAmount }}
              </div>
              <div class="font-semibold flex flex-col items-center"
                *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                <span>Won</span>
                <span>...</span>
              </div>

              <ng-template #wonAmount>
                <div class="font-semibold flex flex-col items-center">
                  <span [ngClass]="{ 'text-red-500': predictionObj.winAmount > 0 }">Won</span>
                  <span [ngClass]="{ 'text-red-500 font-bold': predictionObj.winAmount > 0 }">₹{{
                    predictionObj.winAmount }}</span>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngFor="let predictionObj of order.prediction3DArray">
            <div class="grid grid-cols-3 text-center py-4">
              <div>
                <span *ngFor="
                    let digit of predictionObj.prediction.split('');
                    let i = index
                  " [ngStyle]="{
                    'background-color': getDigitStyle(digit, '3D', i).bgColor,
                    color: getDigitStyle(digit, '2D', i).textColor,
                    'border-radius': '100%'
                  }" class="inline-block px-2 py-1 rounded-full mx-1">
                  {{ digit }}
                </span>
              </div>
              <div class="text-gray-700 font-semibold">
                ₹{{ predictionObj.betAmount }}
              </div>
              <div class="font-semibold flex flex-col items-center"
                *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                <span>Won</span>
                <span>...</span>
              </div>

              <ng-template #wonAmount>
                <div class="font-semibold flex flex-col items-center">
                  <span [ngClass]="{ 'text-red-500': predictionObj.winAmount > 0 }">Won</span>
                  <span [ngClass]="{ 'text-red-500 font-bold': predictionObj.winAmount > 0 }">₹{{
                    predictionObj.winAmount }}</span>
                </div>
              </ng-template>
            </div>
          </div>

          <div *ngFor="let predictionObj of order.prediction4DArray">
            <div class="grid grid-cols-3 text-center py-4">
              <div>
                <span *ngFor="
                    let digit of predictionObj.prediction.split('');
                    let i = index
                  " [ngStyle]="{
                    'background-color': getDigitStyle(digit, '4D', i).bgColor,
                    color: getDigitStyle(digit, '2D', i).textColor,
                    'border-radius': '100%'
                  }" class="inline-block px-2 py-1 rounded-full mx-1">
                  {{ digit }}
                </span>
              </div>
              <div class="text-gray-700 font-semibold">
                ₹{{ predictionObj.betAmount }}
              </div>
              <div class="font-semibold flex flex-col items-center"
                *ngIf="order.wonStatus == 'InProgress'; else wonAmount">
                <span>Won</span>
                <span>...</span>
              </div>

              <ng-template #wonAmount>
                <div class="font-semibold flex flex-col items-center">
                  <span [ngClass]="{ 'text-red-500': predictionObj.winAmount > 0 }">Won</span>
                  <span [ngClass]="{ 'text-red-500 font-bold': predictionObj.winAmount > 0 }">₹{{
                    predictionObj.winAmount }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paginator-container flex justify-center ">
      <mat-paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="[2, 5, 10, 25, 50]"
        (page)="onPageChange($event)" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>