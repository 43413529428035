<div class="flex w-full justify-center overflow-y-hidden overflow-x-hidden">
    <div class="container mar-bottom-50  max-w-md">
        <header class="toolbar header-row overflow-hidden">
            <div class="nav-icon" (click)="handleBackClick()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"
                    style="width: 1rem; height: 1rem;">
                    <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </div>
            <div class="title">Results</div>
            <div class="actions" *ngIf="isLoggedIn">
                <div class="wallet-container">
                    <span class="wallet-display">Balance</span>
                    <span class="amount-display">₹{{ totalWalletAmount }}</span>
                </div>
                <a class="action-button" routerLink="/wallet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c"
                        class="bi bi-wallet-fill" viewBox="0 0 16 16">
                        <path
                            d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
                        <path
                            d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
                    </svg>
                </a>
            </div>
        </header>
        <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Color">
                <div class="game-selector scrollable-nav" #scrollContainer>
                    <nav class="navbar">
                        <div class="nav nav-tabs min-tab" id="nav-tab" role="tablist" #itemElement>
                            <button class="nav-link active" (click)="handleTabClick(1,0)" id="nav-1-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-1-min" type="button" role="tab"
                                aria-controls="nav-1-min" aria-selected="true">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">1min</span></button>

                            <button class="nav-link " (click)="handleTabClick(2,1)" id="nav-2-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-2-min" type="button" role="tab"
                                aria-controls="nav-2-min" aria-selected="false">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">2min</span></button>

                            <button class="nav-link " (click)="handleTabClick(3,2)" id="nav-3-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-3-min" type="button" role="tab"
                                aria-controls="nav-3-min" aria-selected="false">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">3min</span></button>

                            <button class="nav-link " (click)="handleTabClick(4,3)" id="nav-5-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-5-min" type="button" role="tab"
                                aria-controls="nav-5-min" aria-selected="false">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">5min</span></button>


                            <button class="nav-link " (click)="handleTabClick(5,4)" id="nav-10-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-10-min" type="button" role="tab"
                                aria-controls="nav-10-min" aria-selected="false">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">10min</span></button>

                            <button class="nav-link " (click)="handleTabClick(6,5)" id="nav-15-min-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-15-min" type="button" role="tab"
                                aria-controls="nav-15-min" aria-selected="false">
                                <img class="nav-icon" src="../../assets/clock.webp" />
                                <span class="game-time">15min
                                </span></button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-1-min" role="tabpanel"
                            aria-labelledby="nav-1-min-tab"></div>
                        <div class="tab-pane fade show" id="nav-2-min" role="tabpanel" aria-labelledby="nav-2-min-tab">
                        </div>
                        <div class="tab-pane fade show" id="nav-3-min" role="tabpanel" aria-labelledby="nav-3-min-tab">
                        </div>
                        <div class="tab-pane fade show" id="nav-5-min" role="tabpanel" aria-labelledby="nav-5-min-tab">
                        </div>
                        <div class="tab-pane fade show" id="nav-10-min" role="tabpanel"
                            aria-labelledby="nav-10-min-tab">
                        </div>
                        <div class="tab-pane fade show" id="nav-15-min" role="tabpanel"
                            aria-labelledby="nav-15-min-tab">
                        </div>
                    </div>
                </div>
                <div id="result">
                    <table class="table table-striped result-table">
                        <thead>
                            <tr>
                                <th scope="col">ISSUE</th>
                                <th scope="col">COLOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of resultData">
                                <th scope="row">{{item.gameId.gameId}}</th>
                                <td class="color-result"><ng-container *ngFor="let number of numbersArray">
                                        <span
                                            [ngClass]="number === item.winNumber ? 'color-' + item.winColor : 'not-win'">{{number}}</span>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="text-align: center; margin-top: 10px;">
                    <mat-spinner class="loader" diameter="50" *ngIf="showLoader"></mat-spinner>
                    <button mat-button color="primary" (click)="goToPreviousPage()"
                        [disabled]="currentPage === 1">Previous</button>
                    <span>{{currentPage}} / {{totalPages}}</span>
                    <button mat-button color="primary" (click)="goToNextPage()"
                        [disabled]="currentPage === totalPages">Next</button>
                </div>
            </mat-tab>
            <mat-tab label="Dice">
                <ng-container *ngIf="loadedTabs[0]">
                  <app-result></app-result>
                </ng-container>
              </mat-tab>
            <mat-tab label="Lottery">
                <ng-container *ngIf="loadedTabs[1] || loadedTabs[2]">
                    <mat-tab-group (selectedTabChange)="onLotteryTabChange($event)">
                        <mat-tab *ngFor="let lottery of lotteries; let i = index" [label]="lottery.name">
                            <ng-container *ngIf="loadedLotteryTabs[i]">
                                <app-result-history [lotteryName]="lottery.name"></app-result-history>
                            </ng-container>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
    <app-home-bottom-nav></app-home-bottom-nav>
</div>