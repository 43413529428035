import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SelectedOptionService {
    private selectedOptionSource = new BehaviorSubject<{
        id: string;
        value: string;
        color: string;
        selectedTab: string | null;
        index: number;
    } | null>(null);
    selectedOption$ = this.selectedOptionSource.asObservable();

    updateSelectedOption(option: {
        id: string;
        value: string;
        color: string;
        selectedTab: string;
        index: number;
    }) {
        this.selectedOptionSource.next(option);
    }

    // to pass combination to another
    private combinationsSource = new BehaviorSubject<
        {
            combination: number[];
            colors: string[];
            selectedTab: string;
            id: string;
        }[]
    >([]);
    currentCombinations = this.combinationsSource.asObservable();

    constructor() {}

    updateCombinations(
        combinations: {
            combination: number[];
            colors: string[];
            selectedTab: string;
            id: string;
        }[]
    ) {
        this.combinationsSource.next(combinations);
    }

    // private multipleSelet = new BehaviorSubject<any>(null);
    // multipleSelect$ = this.selectedOptionSource.asObservable();

    // multipleSelect(option: any) {
    //     this.selectedOptionSource.next(option);
    // }
}
