import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { BottomSheetExampleComponent } from 'src/app/bottom-sheet-example/bottom-sheet-example.component';
import { TriplemodalComponent } from '../triplemodal/triplemodal.component';

@Component({
  selector: 'app-tripple',
  templateUrl: './tripple.component.html',
  styleUrls: ['./tripple.component.css']
})
export class TrippleComponent implements OnInit {
  buttons:any[] = [];
  bottomButton =[
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '',sumGame: 'null', color: '',triplePair:0 },
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '', color: '' ,sumGame: 'null',triplePair:0},
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '', color: '',sumGame: 'null',triplePair:0 },
  ]
  constructor(private bottomSheet:MatBottomSheet,private diceBetImages:DiceBetImageService,
    private dialog:MatDialog
  ) { }
  bottomSheetRef!: MatBottomSheetRef
  @Input()isButtonDisabled:string =''
  @Input()minRemainingTimeToComplete=0
  @Input()counter = 0;
  @Input()gameId:string = '';
  @Output() diceData  = new EventEmitter()
  ngOnInit(): void {
    this.buttons = this.diceBetImages.getDiceImages()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['counter'] && changes['counter'].currentValue <= this.minRemainingTimeToComplete) {
      this.closeBottomSheet();
    }
  }
  //dice clicked popup function
  diceClicked(data:any){
    console.log(data);
    this.openBetCountWindow(data)
  }
  openBetCountWindow(data:any) {
    if (this.counter > this.minRemainingTimeToComplete) {
      const dataToSend = {...data,sourceComponent: 'tripple',predictionType: 'triple', gameId: this.gameId};
      this.bottomSheetRef = this.bottomSheet.open(BottomSheetExampleComponent, {
        data: dataToSend
      });
      this.bottomSheetRef.afterDismissed().subscribe((result: any) => { // Correct usage of subscribe here
        if (result?.payload?.code === 100 && result.payload.type === 'dice') { // Ensure proper null checks
          this.sendDiceData(result.payload.selectedBet); // Emit the data using EventEmitter
        }
      });
    }
  }
  closeBottomSheet() {
    if (this.bottomSheetRef) {  
      this.bottomSheetRef.dismiss();
    }
  }
  sendDiceData(data: any) {
    this.diceData.emit(data); 
  }
  firststModal(){
    const dialogRef = this.dialog.open(TriplemodalComponent, {
      data:{modalType : 'first'}
    
    });
  }
  secondModal(){
    const dialogRef = this.dialog.open(TriplemodalComponent, {
      data:{modalType : 'second'}
    
    });
  }
}
