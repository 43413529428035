<div>
  <!-- Header Section -->
  <div
    class="header bg-gray-200 px-3 py-2 flex items-center justify-between rounded-md"
  >
    <span class="font-bold text-gray-700">Issue</span>
    <span class="font-bold text-gray-700 ml-14">Result</span>
    <span class="font-bold text-gray-700 ml-16">Sum</span>
    <span class="font-bold text-gray-700">Value</span>
    <span class="font-bold text-gray-700">Number</span>
  </div>
  <div
    class="result-item bg-white p-3 flex items-center justify-between border-b"
    *ngFor="let result of resultHistory"
  >
    <!-- Issue Column -->
    <div class="flex flex-col items-start w-12 mr-4 flex-wrap">
      <span class="issue-number text-gray-700 ">{{result.gameId}}</span>
      <span class="issue-date text-gray-500 text-xs"></span>
    </div>

    <!-- Result Column -->
    <div class="flex items-center space-x-1">
      <img [src]="getImageSrcByDicenumber(result.firstDiceNumber)" alt="Dice 1" class="w-8 h-8" />
      <img [src]="getImageSrcByDicenumber(result.secondDiceNumber)" alt="Dice 2" class="w-8 h-8" />
      <img [src]="getImageSrcByDicenumber(result.thirdDiceNumber)" alt="Dice 3" class="w-8 h-8" />
    </div>

    <!-- Sum Column -->
    <div class="text-center font-bold text-lg text-gray-800">{{result.sum}}</div>

    <!-- Value Column -->
    <div class="flex justify-center">
      <img [src]="  getSmallImgsrc(result.isSmall)" alt="Dice 1" class="w-8 h-8" />
    </div>

    <!-- Number Column -->
    <div class="flex justify-center">
      <img [src]="getOddImgSrc(result.isOdd)" alt="Dice 1" class="w-8 h-8" />
    </div>
  </div>
  <div class="paginator-container pb-4">
    <mat-paginator [length]="totalItems" 
                   [pageSize]="itemsPerPage"
                   [pageSizeOptions]="[2, 5, 10, 25, 50]"
                   (page)="onPageChange($event)"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
