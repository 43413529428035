
<div class="grid grid-cols-1 grid-cols-2 gap-4">
    <div class="chart-container flex flex-col items-center" *ngFor="let key of ['a', 'b', 'c', 'd']">
        <div class="font-semibold self-center">{{ key.toUpperCase() }} - Big/Small</div>
        <div class="flex flex-row">
            <canvas [id]="'MyChartBigSmall' + key"></canvas>
            <div class="flex flex-col justify-center text-xs ml-2">
                <div class="flex items-center mb-2">
                    <div class="w-3 h-3 mr-1" style="background-color:#D57300;"></div>Small
                </div>
                <div class="flex items-center">
                    <div class="w-3 h-3 mr-1" style="background-color:#ff9416;"></div>Big
                </div>
            </div>
        </div>
    </div>
    <div class="chart-container flex flex-col items-center" *ngFor="let key of ['a', 'b', 'c', 'd']">
        <div class="font-semibold self-center">{{ key.toUpperCase() }} - Odd/Even</div>
        <div class="flex">
            <canvas [id]="'MyChartOddEven' + key"></canvas>
            <div class="flex flex-col justify-center text-xs ml-2">
                <div class="flex flex-row items-center mb-2">
                    <div class="w-3 h-3 mr-1" style="background-color:#D57300;"></div>Even
                </div>
                <div class="flex items-center">
                    <div class="w-3 h-3 mr-1" style="background-color:#ff9416;"></div>Odd
                </div>
            </div>
        </div>
    </div>
</div>
