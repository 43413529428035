<div class="top-contain">
  <div class="dice-select-top h-10 flex items-center px-3 text-sm">
    3 Different odd numbers: odds(1.9)
    <button (click)="singleModal()"
      class="flex items-center justify-center w-5 h-5 ml-2.5 rounded-full text-sm font-bold text-white focus:outline-none"
      style="background-color: #ff7a00"
    >
      ?
    </button>
  </div>
  <div class="grid px-3 gap-2 grid-cols-3">
    <button
      *ngFor="let button of buttons"
      [ngClass]="isButtonDisabled"
      class="flex flex-col px-3 py-2 items-center justify-center gap-1 bg-white rounded-xl"
      style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
      (click)="diceClicked(button)"
    >
      <!-- Center image and data with dynamic color -->
      <div class="md:h-20 md:w-20 h-11 w-11 flex items-center justify-center">
        <div class="flex flex-row">
          <div
            class="bg-full w-11 h-11 md:w-14 md:h-14 dice-sum-big"
            [style.backgroundImage]="'url(' + button.imageSrc + ')'"
          ></div>
        </div>
      </div>
      <!-- Dynamic value binding -->
      <div class="bg-content">
        {{ button.value }}
      </div>
    </button>
  </div>
  <!-- <div class="py-5 px-3" style="background-color: #BECAD2;">
        <div class="relative rounded-lg">
          <div class="flex justify-between items-center pt-2 pb-1 px-3">
            <div class="bg-full h-px flex-grow dice-parting"></div>
            <p class="din text-sm font-bold  mx-2" style="color:#A8C3D2 ;">MY BETS</p>
            <div class="bg-full h-px flex-grow dice-parting"></div>
          </div>
          <div class="flex overflow-x-auto w-full h-10 items-center px-3 gap-x-1"></div>
          <div class="absolute bottom-0 shadow-lg rounded-lg" style="max-width: fit-content;">
            <img src="../../../../assets/dice-game/dice-bets-bottom.b65a5ef9.webp" class="relative z-10 opacity-0 shadow-lg transition-opacity duration-300 rounded-lg data-[loaded=true]:opacity-100" alt="">
          </div>
        </div>
    </div>
       -->
</div>
