import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, Subscription } from 'rxjs';
import { Location } from '@angular/common';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { UserSessionStorageService } from 'src/app/_services/user.session.storage.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage: string | null = null;
  phone = '';
  otp = '';
  password = '';
  referralCode = ''
  countryCode = "+91"
  countdown: number = 0;
  countdownSubscription!: Subscription;
  otpButtonText: string = 'Get OTP';

  constructor(private authService: AuthService, private router: Router, private _snackBar: MatSnackBar, private location: Location,
    private sessionServices: UserSessionStorageService) { }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  onSubmit(): void {
  }

  getOtp(): void {
    this.authService.getOtp(this.countryCode, this.phone, "signup").subscribe({
      next: data => {
        this.errorMessage = ""
        this._snackBar.open("OTP has been sent", 'Close', {
          duration: 3000
        });
        this.startRegenerateOTPTimer()
      },
      error: err => {
        this.errorMessage = err.error.Error;
        this._snackBar.open(err.error.Error, 'Close', {
          duration: 3000
        });
      }
    });
  }

  startRegenerateOTPTimer() {
    this.countdown = 60;
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.otpButtonText = this.countdown > 0 ? `Get OTP ${this.countdown} s` : 'Get OTP';
      } else {
        this.countdownSubscription.unsubscribe();
        this.otpButtonText = 'Get OTP';
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  signup(): void {
    const promoCode = this.sessionServices.getLuckWheelPromo()
    const promoSource = "HomeLuckyWheel";
    this.authService.register(this.phone, this.password, this.otp, this.referralCode, promoCode, promoSource).subscribe({
      next: data => {
        this.sessionServices.clearLuckyWheelPromo()
        this._snackBar.open("Successfully registered, Please login", 'Close', {
          duration: 3000
        });
        this.errorMessage = ""
        this.router.navigate(['/login']);
      },
      error: err => {
        this.errorMessage = err.error.Error;
        this._snackBar.open(err.error.Error, 'Close', {
          duration: 3000
        });
      }
    });
  }
}
