import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, Subscription } from 'rxjs';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    phone: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  username = '';
  activeTab = 'otp';
  phone = '';
  otp = '';
  password = '';
  loginActive = false
  countdown: number = 0;
  countdownSubscription!: Subscription;
  otpButtonText: string = 'Get OTP';
  countryCode = "+91"


  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private location: Location,
    private _snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.username = this.tokenStorage.getUser().userId;
    }
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  verifyOtp() {
    this.authService.loginViaOtp(this.phone, this.otp.toString()).subscribe({
      next: data => {
        this.tokenStorage.saveToken(data.data.authToken);
        this.tokenStorage.saveUser(data.data.user);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.username = this.tokenStorage.getUser().userId;
        this.router.navigate(['/home']);
      }, error: err => {
        this.errorMessage = "Invalid credentials";
        this._snackbar.open("Invalid credentials", 'Close', {
          duration: 3000
        });
        this.isLoginFailed = true;
      }
    });
  }

  loginWithPassword() {
    this.authService.login(this.phone, this.password).subscribe({
      next: data => {
        this.tokenStorage.saveToken(data.data.authToken);
        this.tokenStorage.saveUser(data.data.user);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.username = this.tokenStorage.getUser().userId;
        if (this.tokenStorage.isAdmin()) {
          this.router.navigate(['/admin-dashboard']);
        } else {
          this.router.navigate(['/home']);
        }
      }, error: err => {
        this.errorMessage = "Invalid credentials";
        this._snackbar.open("Invalid credentials", 'Close', {
          duration: 3000
        });
        this.isLoginFailed = true;
      }
    });
  }

  getOtp() {
    this.authService.getOtp(this.countryCode, this.phone, "login").subscribe({
      next: data => {
        this.errorMessage = ""
        this._snackbar.open("OTP has been sent", 'Close', {
          duration: 3000
        });
        this.startRegenerateOTPTimer()
      },
      error: err => {
        this.errorMessage = err.error.Error;
        this._snackbar.open(err.error.Error, 'Close', {
          duration: 3000
        });
      }
    });
  }

  startRegenerateOTPTimer() {
    this.countdown = 60;
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.otpButtonText = this.countdown > 0 ? `Get OTP ${this.countdown} s` : 'Get OTP';
      } else {
        this.countdownSubscription.unsubscribe();
        this.otpButtonText = 'Get OTP';
      }
    });
  }
}
