import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DiceBetImageService } from "src/app/_services/dice-bet-image.service";
import { DiceService } from "src/app/_services/dice.service";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from "src/environments/environment";

@Component({
    selector: "app-my-order",
    templateUrl: "./my-order.component.html",
    styleUrls: ["./my-order.component.css"],
})
export class MyOrderComponent implements OnInit {
    constructor(
        private diceService: DiceService,
        private betImages: DiceBetImageService
    ) { }

    currentPage = 1;
    itemsPerPage = 5;
    totalItems = 0;
    totalPages = 0;
    private analytics: any;

    pageSize: number = 10;
    orders: any[] = [];
    sumImages: any[] = [];
    myOrder: any[] = [];
    firstImageSrc? = ""

    ngOnInit(): void {
        this.getMyOrders();
        const app = initializeApp(environment.firebaseConfig);
        this.analytics = getAnalytics(app);
        this.sumImages = this.betImages.getSumImages();
    }

    getMyOrders() {
        this.diceService.getMyOrders(this.currentPage, this.itemsPerPage).subscribe({
            next: (data) => {
                this.orders = data.data;
                this.totalItems = data.totalBets;
                this.totalPages = data.totalPages;
                this.currentPage = data.currentPage;
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    onPageChange(event: PageEvent): void {
        this.currentPage = event.pageIndex + 1;
        this.itemsPerPage = event.pageSize;

        logEvent(this.analytics, 'dice_orders_pagination_change', {
            currentPage: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            totalItems: this.totalItems
        });
        this.getMyOrders();
    }

    getOrderImages(order: any) {
        if (order.predictionType === "sum") {
            if (order.sumGameTotalNumber) {
                return this.betImages
                    .getSumImages()
                    .find((btn) => btn.sumGameTotalNumber === order.sumGameTotalNumber);
            } else if (order.sumGame) {
                return this.betImages
                    .getSumImages()
                    .find((btn) => btn.sumGame === order.sumGame);
            }
        } else if (order.predictionType === "single") {
            return this.betImages
                .getDiceImages()
                .find((btn) => btn.singlePair === order.singlePair);
        } else if (order.predictionType === "double") {
            return this.betImages
                .getDiceImages()
                .find((btn) => btn.doublePair === order.doublePair);
        } else if (order.predictionType === "triple") {
            return this.betImages
                .getDiceImages()
                .find((btn) => btn.triplePair === order.triplePair);
        }
        return undefined;
    }

    getDiceImage(order: any, diceType: string) {
        let diceNumber = 1;
        switch (diceType) {
            case 'firstDiceNumber':
                diceNumber = order.diceDetails.firstDiceNumber;
                break;
            case 'secondDiceNumber':
                diceNumber = order.diceDetails.secondDiceNumber;
                break;
            case 'thirdDiceNumber':
                diceNumber = order.diceDetails.thirdDiceNumber;
                break;
        }
        return this.betImages.getDiceImages().find((dice) => dice.singlePair == diceNumber);
    }


    getBigOrSmall(order: any) {
        let total = order.diceDetails.firstDiceNumber + order.diceDetails.secondDiceNumber + order.diceDetails.thirdDiceNumber
        let bigOrSmall = "big"
        if (total > 10) {
            bigOrSmall = "big"
        } else {
            bigOrSmall = "small"
        }
        return this.betImages.getSumImages().find((dice) => dice.sumGame == bigOrSmall);
    }

    getEvenOrOdd(order: any) {
        let total = order.diceDetails.firstDiceNumber + order.diceDetails.secondDiceNumber + order.diceDetails.thirdDiceNumber
        let evenOrOdd = "even"
        if (total % 10 == 0) {
            evenOrOdd = "even"
        } else {
            evenOrOdd = "odd"
        }
        return this.betImages.getSumImages().find((dice) => dice.sumGame == evenOrOdd);
    }

    getTotal(order: any) {
        let total = order.diceDetails.firstDiceNumber + order.diceDetails.secondDiceNumber + order.diceDetails.thirdDiceNumber
        return this.betImages.getSumImages().find((dice) => dice.sumGameTotalNumber == total);
    }

}
