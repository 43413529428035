import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TokenStorageService } from '../_services/token-storage.service';
import { WalletService } from '../_services/wallet.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  @ViewChild('paymentForm') paymentForm!: NgForm;
  isLoggedIn = false
  totalWalletAmount = 0
  countdown: number = 3;
  constructor(private tokenStorageService: TokenStorageService, private walletService: WalletService, private location: Location, private router: Router) { }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
    if (this.tokenStorageService.isLoggedIn()) {
      this.isLoggedIn = true
      this.getWalletInfo()
      this.startTimer()
    }
  }

  startTimer() {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval);
        this.redirectToHome();
      }
    }, 1000);
  }

  redirectToHome(): void {
    this.router.navigate(['/dummy']).then(() => {
      this.router.navigate(['/home']).then(() => {
        this.location.replaceState('/home');
      });
    });
  }

  getWalletInfo() {
    this.walletService.getInfo().subscribe({
      next: data => {
        this.totalWalletAmount = data.data.wallet.totalAmount
      },
      error: err => {

      }
    })
  }

  handleBackClick() {
    this.location.back()
  }
}
