
<div class="flex  space-x-4 w-full   h-12 py-2 " style="background-color: #F7F9FF;">
    <button class="px-4 py-2 bg-gray-200 rounded-full text-gray-700  focus:outline-none">1st-prize</button>
</div>
<div class="text-gray-700  flex items-center h-12 px-2 text-center space-x-2" style="background-color:#E7EAF4 ;">
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
    <span><span class="font-semibold">Fish</span> : 1,2,3 | <span class="font-semibold">Prawn</span> : 4,5,6 | <span class="font-semibold">Crab</span> : 7,8,9</span>
</div> 
<div class="flex py-3 items-center bg-gray-200">
    <!-- Side Indicator -->
    <div class="flex flex-col items-center justify-center m-2 " style="color: rgb(103, 165, 25);">
      <div class="font-bold">D</div>
      <div class="rounded-full w-5 h-5 " style="background-color: rgb(103, 165, 25);"></div>
    </div>
  
    <!-- Main Content -->
    <div class="flex-1 flex items-center">
      <div class="relative flex flex-col gap-2 flex-1">
        <div class="flex  sm:flex-row gap-2" role="group">
            <ng-container *ngFor="let option of ['Fish', 'Prawn', 'Crab']">
              <div class="flex-1 px-2">
                <label class="block cursor-pointer opacity-100">
                  <input type="checkbox" class="hidden"  [value]="option" (change)="onCheckboxChange(option,'#67a519', 0)">
                  <div class="relative flex flex-col items-center border-2 rounded-lg p-3" [ngStyle]="{'border-color': '#67a519', 'background-color': selectedValues.includes(option + 0) ? '#67a519' : 'white'}" style="box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px 0px, rgb(255, 255, 255) -4px -4px 8px 0px;">
                    <span class="font-bold text-sm " [ngStyle]="{'color':selectedValues.includes(option + 0) ? 'white':'#1f2937'}">{{option}}</span>
                    <span class="text-xs mt-1 "  [ngStyle]="{'color': selectedValues.includes(option + 0) ? 'white' : '#4b5563'}">3.2X</span>
                  </div>
                </label>
              </div>
            </ng-container>
        </div>
      </div>
    </div>
  </div>