import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-triplemodal',
  templateUrl: './triplemodal.component.html',
  styleUrls: ['./triplemodal.component.css']
})
export class TriplemodalComponent implements OnInit {
  modalType!:string

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalType = data.modalType;
    console.log(this.modalType);
  }


  ngOnInit(): void {
  }

}
