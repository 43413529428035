import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimUppercase'
})
export class TrimUppercasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    return value.slice(-8).toUpperCase();
  }

}
