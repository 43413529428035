<div>
  <div class="flex justify-between px-3 items-center h-1 py-4">
    <span class="align-middle text-lg">Sale Rules</span>
    <button
      type="button"
      class="text-gray-500 hover:text-gray-700 outline-none"
      (click)="closePopup()"
    >
      <span aria-hidden="true" class="text-xl">&times;</span>
    </button>
  </div>
  <div
    class="flex flex-col flex-1 overflow-y-auto text-sm text-gray-700 gap-y-10 p-3 max-w-md " style="height: 500px;" >
    <p>
      In order to protect the legitimate rights and interests of users
      participating in the pre-sale and maintain the normal operating order of
      the pre-sale, these rules are formulated in accordance with relevant
      agreements and laws and regulations. Country Chapter 1 Definition.
    </p>
    <p>
      <strong>1.1 Pre-sale definition:</strong> refers to a sales model in which
      a seller offers a bundle of a product or service, collects consumer orders
      through product tools before selling, and makes it available to customers.
      Consumers of goods and/or services by prior agreement.
    </p>
    <p>
      <strong>1.2 Presale mode is "deposit" mode:</strong> "Consignment" refers
      to the pre-delivery of a fixed number of items prior to sale. "Deposit"
      Scam Join mini games for a chance to win more deposits. Deposits can be
      exchanged directly for goods. Deposit is not refundable.
    </p>
    <p>
      <strong>1.3 Pre-sale product:</strong> A product that is shipped by the
      seller using the pre-sale product tool. Only highlight the word presale on
      the product name or product detail page, and products that do not use the
      presale product tool are not presale.
    </p>
    <p>
      <strong>1.4 Pre-sale system:</strong> refers to the system product tool
      that helps sellers to sell samples before selling.
    </p>
    <p>
      <strong>1.5 Product price before selling:</strong> is the selling price of
      the product before selling. The price of pre-sale items consists of two
      parts: deposit and final payment.
    </p>
  </div>
</div>
