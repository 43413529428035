import { Injectable } from "@angular/core";
import { SelectedOptionService } from "./selectedOption.service";

@Injectable({
    providedIn:"root"
})

export class sharedService{
    constructor(private selectedOption: SelectedOptionService) {}

    handleCheckboxChange(selectedValues: string[], value: string, color: string, index: number, selectedTab: string ): string[] {
        const option = value + index;
        const valueIndex = selectedValues.indexOf(option);
        const id = option + selectedTab;
    
        if (valueIndex === -1) {
          this.selectedOption.updateSelectedOption({ id, value, color, selectedTab, index });
          selectedValues.push(option);
        } else {
          this.selectedOption.updateSelectedOption({ id, value, color, selectedTab, index });
          selectedValues.splice(valueIndex, 1);
        }
        return selectedValues;
    }
    
    handleSelectedGameChange(selectedGame: any[], selectedTab: string): string[] {
        const selectedValues: string[] = [];
        selectedGame.forEach((element: any) => {
          if (element.selectedTab === selectedTab) {
            selectedValues.push(element.value + element.index);
          }
        });
        return selectedValues;
      }
}