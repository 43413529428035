// popular-games.component.ts
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popular-games',
  templateUrl: './popular-games.component.html',
  styleUrls: ['./popular-games.component.css']
})
export class PopularGamesComponent {

  constructor(private router: Router) {}

  onGameDetails(id: number) {
    this.router.navigate(['/details', id]);
  }
}
