<div class="flex w-full justify-center">
  <div class="container max-w-md">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Login</div>
      <button class="close-button" (click)="goBack()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style="width: 1.5rem; height: 1.5rem;">
          <path stroke="#48505D" d="m16.8 7.2-9.6 9.6M7.2 7.2l9.6 9.6"></path>
        </svg>
      </button>
    </header>
    <div class="content">
      <div class="login-container">
        <div class="tabs">
          <div class="tab-link" [class.active]="activeTab === 'otp'" (click)="setActiveTab('otp')">OTP Login</div>
          <div class="tab-link" [class.active]="activeTab === 'password'" (click)="setActiveTab('password')">Password
            Login
          </div>
        </div>
        <div *ngIf="activeTab === 'otp'" class="tab-content">
          <div class="phone-container">
            <div class="country-code">+91</div>
            <input type="tel" maxlength="10" placeholder="Enter Phone Number" [(ngModel)]="phone">
          </div>
          <div class="otp-container">
            <input type="number" maxlength="4" placeholder="OTP" [(ngModel)]="otp">
            <button class="get-otp" (click)="getOtp()" [disabled]="!phone || countdown > 0"> {{ otpButtonText }}
            </button>
          </div>
          <button class="submit" [class.active]="otp && phone" (click)="verifyOtp()">VERIFY OTP</button>
        </div>
        <div *ngIf="activeTab === 'password'" class="tab-content">
          <div class="phone-container">
            <div class="country-code">+91</div>
            <input type="tel" maxlength="10" minlength="10" placeholder="Enter Phone Number" [(ngModel)]="phone">
          </div>
          <input type="password" placeholder="Password" [(ngModel)]="password">
          <div class="otp-container">
            <button class="submit" [class.active]="password && phone" (click)="loginWithPassword()">LOGIN</button>
          </div>
        </div>
        <div class="signup-container"><span class="new-text">New to Lottopi Game?</span><a [routerLink]="'/register'"
            class="signup">Create account</a></div>
      </div>
    </div>
  </div>
</div>