export const environment = {
  production: true,
  apiUrl: 'https://singamlotto.com/',
  apiVersion: '1.0',
  firebaseConfig: {
    apiKey: "AIzaSyBTkqC9Fy-aKzQiocn-5I7kl0aJaymMhgM",
    authDomain: "singam-prod.firebaseapp.com",
    projectId: "singam-prod",
    storageBucket: "singam-prod.appspot.com",
    messagingSenderId: "515725309622",
    appId: "1:515725309622:web:9e73c4bf95bf625a945817",
    measurementId: "G-Y0XQHHNGWP"
  }
};
