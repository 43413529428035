import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundTime'
})
export class RoundTimePipe implements PipeTransform {

  transform(value: Date | string): string {
    const date = new Date(value);
    const minutes = date.getMinutes();
    const roundedMinutes = Math.round(minutes / 1);
    date.setMinutes(roundedMinutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
  }

}
