import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home-instant-lottery',
  templateUrl: './home-instant-lottery.component.html',
  styleUrls: ['./home-instant-lottery.component.css']
})
export class HomeInstantLotteryComponent implements OnInit {
  images: any[] = [];
  private analytics: any;

  constructor(private router: Router, private imageService: DiceBetImageService) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void {
    this.images = this.imageService.getInstantLotteries();
  }

  onImageClick(image: any) {
    this.router.navigate(['lottery-game', image.name]);

    logEvent(this.analytics, 'home_instant_lottery_click', {
      lottery_name: image.name,
      source_page: window.location.pathname
    });

    console.log(`Lottery game clicked: ${image.name}`);
  }
}
