<div class="top-contain">
  <div class="dice-select-top h-10 flex items-center px-3 text-sm">
    2 matching numbers : odds(8)
    <button (click)="doubleModal()"
      class="flex items-center justify-center w-5 h-5 ml-2.5 rounded-full text-sm font-bold text-white focus:outline-none"
      style="background-color: #ff7a00"
    >
      ?
    </button>
  </div>
  <div class="grid px-3 gap-2 grid-cols-3">
    <button
      *ngFor="let button of buttons"
      [ngClass]="isButtonDisabled"
      class="flex flex-col px-3 py-2 items-center justify-center gap-1 bg-white rounded-xl"
      style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
      (click)="diceClicked(button)"
    >
      <!-- Center image and data with dynamic color -->
      <div class=" h-20 w-24 flex items-center justify-center">
        <div class="flex flex-row">
          <div
            class="bg-full w-11 h-11 md:w-14 md:h-14 dice-sum-big"
            [style.backgroundImage]="'url(' + button.imageSrc + ')'"
          ></div>
          <div
            class="bg-full w-11 h-11 md:w-14 md:h-14 dice-sum-big"
            [style.backgroundImage]="'url(' + button.imageSrc + ')'"
          ></div>
        </div>
      </div>
      <!-- Dynamic value binding -->
      <div class="bg-content">
        {{ button.value }}
      </div>
    </button>
  </div>
</div>
