import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DiceBetImageService } from 'src/app/_services/dice-bet-image.service';
import { BottomSheetExampleComponent } from 'src/app/bottom-sheet-example/bottom-sheet-example.component';
import { TriplemodalComponent } from '../triplemodal/triplemodal.component';

@Component({
  selector: 'app-double',
  templateUrl: './double.component.html',
  styleUrls: ['./double.component.css']
})
export class DoubleComponent implements OnInit {

  
  constructor(
    private bottomSheet:MatBottomSheet,
    private dialog:MatDialog,
    private diceBetImages:DiceBetImageService
  ) { }
  buttons:any[] = [];
  

  bottomSheetRef!: MatBottomSheetRef
  @Input()isButtonDisabled:string =''
  @Input()minRemainingTimeToComplete=0
  @Input()counter = 0;
  @Input()gameId:string = '';
  @Output() diceData  = new EventEmitter()
  ngOnInit(): void {
    this.buttons =  this.diceBetImages.getDiceImages()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['counter'] && changes['counter'].currentValue <= this.minRemainingTimeToComplete) {
      this.closeBottomSheet();
    }
  }
  //dice clicked popup function
  diceClicked(data:any){
    console.log(data);
    this.openBetCountWindow(data)
  }
  openBetCountWindow(data:any) {
    console.log('dgs');
    
    if (this.counter > this.minRemainingTimeToComplete) {
      const dataToSend = {...data ,sourceComponent:'double',predictionType: 'double', gameId: this.gameId};
      this.bottomSheetRef = this.bottomSheet.open(BottomSheetExampleComponent, {
        data: dataToSend
      });
      this.bottomSheetRef.afterDismissed().subscribe((result: any) => { // Correct usage of subscribe here
        if (result?.payload?.code === 100 && result.payload.type === 'dice') { // Ensure proper null checks
          this.sendDiceData(result.payload.selectedBet); // Emit the data using EventEmitter
        }
      });
    }
  }
  closeBottomSheet() {
    if (this.bottomSheetRef) {  
      this.bottomSheetRef.dismiss();
    }
  }
  sendDiceData(data: any) {
    this.diceData.emit(data); // Correctly emit the event with data
  }
  doubleModal(){
    this.dialog.open(TriplemodalComponent,{
      data:{modalType:'double'}
    })
  }
}
