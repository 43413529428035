// File: src/app/twosides/twosides.component.ts

import { Component, OnInit, Output, EventEmitter, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { SelectedOptionService } from 'src/app/_services/selectedOption.service';
import { sharedService } from 'src/app/_services/shared.service';
import { GameTypeSelect } from 'src/app/model/gameType.interface';

@Component({
  selector: 'app-twosides',
  templateUrl: './twosides.component.html',
  styleUrls: ['./twosides.component.css']
})
export class TwosidesComponent implements OnInit {
  colors = [
    { label: 'A', color: 'rgb(213, 115, 0)' },
    { label: 'B', color: 'rgb(0, 135, 212)' },
    { label: 'C', color: 'rgb(213, 0, 0)' },
    { label: 'D', color: 'rgb(103, 165, 25)' },
  ];
  selectedValues: string[] = [];

  constructor(private sharedService: sharedService) { }
  @Input() selectedGame: GameTypeSelect[] = []
  ngOnInit(): void { }

  // checking the datas in the cart 
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedGame']) {
      this.selectedValues = this.sharedService.handleSelectedGameChange(this.selectedGame, 'TwoSide');
    }
  }
  onCheckboxChange(value: string, color: string, index: number): void {
    this.selectedValues = this.sharedService.handleCheckboxChange(this.selectedValues, value, color, index, 'TwoSide');
  }
}
