import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-color-game',
  templateUrl: './color-game.component.html',
  styleUrls: ['./color-game.component.css']
})
export class HomeColorGameComponent implements OnInit {

  private analytics: any;

  images = [
    { src: '../../../assets/color-1-min.webp', alt: 'Color 1', cycle: 1 },
    { src: '../../../assets/color-2-min.webp', alt: 'Color 2', cycle: 2 },
    { src: '../../../assets/color-3-min.webp', alt: 'Color 3', cycle: 3 },
    { src: '../../../assets/color-5-min.webp', alt: 'Color 5', cycle: 4 },
    { src: '../../../assets/color-10-min.webp', alt: 'Color 10', cycle: 5 },
    { src: '../../../assets/color-15-min.webp', alt: 'Color 15', cycle: 6 },
  ];

  constructor(private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void { }

  onImageClick(image: any) {
    this.router.navigate(['/color-game'], { queryParams: { cycle: image.cycle } });

    logEvent(this.analytics, 'home_color_game_click', {
      content_type: 'image',
      item_name: image.alt,
      cycle: image.cycle,  
      source_page: this.router.url.split('?')[0]
    });
  }
}
