import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment'; // Firebase config

@Component({
  selector: 'app-home-bottom-nav',
  templateUrl: './home-bottom-nav.component.html',
  styleUrls: ['./home-bottom-nav.component.css']
})
export class HomeBottomNavComponent implements OnInit {

  private analytics: any;

  constructor(private router: Router) {
    const app = initializeApp(environment.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  ngOnInit(): void { }

  onNavClick(section: string): void {
    const currentPage = this.router.url.split('?')[0];
    logEvent(this.analytics, 'bottom_navigation_click', {
      content_type: 'navigation',
      item_name: section,
      source_page: currentPage
    });
    console.log(`Navigation clicked: ${section}, from page: ${currentPage}`);
  }
}
