import { Component, OnInit } from '@angular/core';
import { DiceService } from 'src/app/_services/dice.service';
import { Winner } from 'src/app/model/selectedDice.interface';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.css']
})
export class WinnersComponent implements OnInit {

  constructor(private diceService:DiceService) { }
  winners:Winner[]=[]
  ngOnInit(): void {
     this.diceService.getWinners().subscribe({
      next: (data) => {
        this.winners = data.data.sort((a: Winner, b: Winner) => b.wonAmount - a.wonAmount);
      },
      error: (err) => {
        console.error('Error fetching winners data:', err);
      }
    })
  }
}
