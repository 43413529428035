import { Injectable } from '@angular/core';
import { LotteryService } from './lottery.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiceBetImageService {
  constructor(private lotteryService: LotteryService) { }

  sumImages = [
    { imageSrc: '../../../../assets/dice-game/dice-big.5586b309.webp', value: '1.95x', data: '', color: '', sumGame: 'big', sumGameTotalNumber: null },
    { imageSrc: '../../../../assets/dice-game/dice-small.e17da5f9.webp', value: '1.95x', data: '', color: '', sumGame: 'small', sumGameTotalNumber: null },
    { imageSrc: '../../../assets/dice-game/dice-odd.8bffffcd.webp', value: '1.95x', data: '', color: '', sumGame: 'odd', sumGameTotalNumber: null },
    { imageSrc: '../../../assets/dice-game/dice-even.6053bf51.webp', value: '1.95x', data: '', color: '', sumGame: 'even', sumGameTotalNumber: null },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '150x', data: '3', color: 'red', sumGame: 'null', sumGameTotalNumber: 3 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '50x', data: '4', color: 'green', sumGame: 'null', sumGameTotalNumber: 4 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '18x', data: '5', color: 'red', sumGame: 'null', sumGameTotalNumber: 5 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '14x', data: '6', color: 'green', sumGame: 'null', sumGameTotalNumber: 6 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '12x', data: '7', color: 'red', sumGame: 'null', sumGameTotalNumber: 7 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '8x', data: '8', color: 'green', sumGame: 'null', sumGameTotalNumber: 8 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '6x', data: '9', color: 'red', sumGame: 'null', sumGameTotalNumber: 9 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '6x', data: '10', color: 'green', sumGame: 'null', sumGameTotalNumber: 10 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '6x', data: '11', color: 'red', sumGame: 'null', sumGameTotalNumber: 11 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '6x', data: '12', color: 'green', sumGame: 'null', sumGameTotalNumber: 12 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '8x', data: '13', color: 'red', sumGame: 'null', sumGameTotalNumber: 13 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '12x', data: '14', color: 'green', sumGame: 'null', sumGameTotalNumber: 14 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '14x', data: '15', color: 'red', sumGame: 'null', sumGameTotalNumber: 15 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '18x', data: '16', color: 'green', sumGame: 'null', sumGameTotalNumber: 16 },
    { imageSrc: '../../../assets/dice-game/dice-num-red.dd4a7224.webp', value: '50x', data: '17', color: 'red', sumGame: 'null', sumGameTotalNumber: 17 },
    { imageSrc: '../../../assets/dice-game/dice-num-green.72368397.webp', value: '150x', data: '18', color: 'green', sumGame: 'null', sumGameTotalNumber: 18 },
  ];

  diceImages = [
    { imageSrc: '../../../../assets/dice-game/dice-single-1.cd31c2ce.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 1, doublePair: 1, triplePair: 1 },
    { imageSrc: '../../../assets/dice-game/dice-single-2.ef5b7308.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 2, doublePair: 2, triplePair: 2 },
    { imageSrc: '../../../assets/dice-game/dice-single-3.01040bed.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 3, doublePair: 3, triplePair: 3 },
    { imageSrc: '../../../assets/dice-game/dice-single-4.aa0fa9c1.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 4, doublePair: 4, triplePair: 4 },
    { imageSrc: '../../../assets/dice-game/dice-single-5.d76c07b5.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 5, doublePair: 5, triplePair: 5 },
    { imageSrc: '../../../assets/dice-game/dice-single-6.3b088966.webp', value: '8x', data: '', color: '', sumGame: 'null', singlePair: 6, doublePair: 6, triplePair: 6 },
  ];

  lottryImages = [
    { id: 1, src: '../../../assets/lottery-1.5-quick.webp', alt: 'JackPot 2 Min', bgColor: "#00796B", amount: "₹9,60,000", label: "Jackpot", code: 'JK2-0203', nextDraw: 'null', name: 'JackPot 2 Min' },
    { id: 2, src: '../../../assets/lottery-3-min.webp', alt: 'JackPot 3 Min', bgColor: "#8E24AA", amount: "₹9,60,000", label: "Jackpot", code: 'JackPot 3 Min', nextDraw: 'null', name: 'JackPot 3 Min' },
    { id: 3, src: '../../../assets/lottery-5-min.webp', alt: 'JackPot 5 Min', bgColor: "#E53935", amount: "₹9,60,000", label: "Jackpot", code: 'JackPot 5 Min', nextDraw: 'null', name: 'JackPot 5 Min' },
    { id: '66e3e9f8aa145959ed216273', src: '../../../assets/lottery-naga-1-pm.webp', alt: 'FORTUNE 1PM', bgColor: "#FB8C00", amount: "₹9,60,000", label: "Jackpot", code: 'FN-161', nextDraw: 'null', name: 'FORTUNE 1PM' },
    { id: '66e011997553cd8d14c51519', src: '../../../assets/lottery-sthree-sakthi.webp', alt: 'STHREE-SAKTHI', bgColor: "#FDD835", amount: "₹9,60,000", label: "Jackpot", code: 'SS-434', nextDraw: 'null', name: 'STHREE-SAKTHI' },
    { id: '66e43048aa145959ed217ec4', src: '../../../assets/lottery-naga-6-pm.webp', alt: 'LUCKY 6PM', bgColor: "#43A047", amount: "₹9,60,000", label: "Jackpot", code: 'LK-163', nextDraw: 'null', name: 'LUCKY 6PM' },
    { id: '66e44c68aa145959ed218a1f', src: '../../../assets/lottery-naga-8-pm.webp', alt: 'JACKPOT 8PM', bgColor: "#6D4C41", amount: "₹9,60,000", label: "Jackpot", code: 'JP-163', nextDraw: 'null', name: 'JACKPOT 8PM' },
    { id: '66e16319aa145959ed2030a9', src: '../../../assets/lottery-fifty-fifty.webp', alt: 'FIFTY-FIFTY', bgColor: "#00ACC1", amount: "₹9,60,000", label: "Jackpot", code: 'FF-112', nextDraw: 'null', name: 'FIFTY-FIFTY' },
    { id: '66e2b49aaa145959ed20bf3f', src: '../../../assets/lottery-karunya-plus.webp', alt: 'KARUNYA PLUS', bgColor: "#D81B60", amount: "₹9,60,000", label: "Jackpot", code: 'KN-540', nextDraw: 'null', name: 'KARUNYA PLUS' },
    { id: '66e40619aa145959ed216df7', src: '../../../assets/lottery-nirmal.webp', alt: 'NIRMAL', bgColor: "#3949AB", amount: "₹9,60,000", label: "Jackpot", code: 'NR-398', nextDraw: 'null', name: 'NIRMAL' },
    { id: '66dc1d197553cd8d14c2ad8a', src: '../../../assets/lottery-karunya.webp', alt: 'KARUNYA', bgColor: "#8E24AA", amount: "₹9,60,000", label: "Jackpot", code: 'KN-539', nextDraw: 'null', name: 'KARUNYA' },
    { id: '66dd6e997553cd8d14c37d8b', src: '../../../assets/lottery-akshaya.webp', alt: 'AKSHAYA', bgColor: "#009688", amount: "₹9,60,000", label: "Jackpot", code: 'AK-670', nextDraw: 'null', name: 'AKSHAYA' },
    { id: '66dec01a7553cd8d14c4491b', src: '../../../assets/lottery-winwin.webp', alt: 'WIN-WIN', bgColor: "#E91E63", amount: "₹9,60,000", label: "Jackpot", code: 'W-788', nextDraw: 'null', name: 'WIN-WIN' },
  ];

  stateLotteries = [
    { id: "4", src: '../../../assets/lottery-naga-1-pm.webp', alt: 'FORTUNE 1PM', bgColor: "#FB8C00", amount: "₹9,60,000", label: "Jackpot", code: 'FN-161', nextDraw: 'null', name: 'FORTUNE 1PM' },
    { id: "5", src: '../../../assets/lottery-sthree-sakthi.webp', alt: 'STHREE-SAKTHI', bgColor: "#FDD835", amount: "₹9,60,000", label: "Jackpot", code: 'SS-434', nextDraw: 'null', name: 'STHREE-SAKTHI' },
    { id: "6", src: '../../../assets/lottery-naga-6-pm.webp', alt: 'LUCKY 6PM', bgColor: "#43A047", amount: "₹9,60,000", label: "Jackpot", code: 'LK-163', nextDraw: 'null', name: 'LUCKY 6PM' },
    { id: "7", src: '../../../assets/lottery-naga-8-pm.webp', alt: 'JACKPOT 8PM', bgColor: "#6D4C41", amount: "₹9,60,000", label: "Jackpot", code: 'JP-163', nextDraw: 'null', name: 'JACKPOT 8PM' },
    { id: "8", src: '../../../assets/lottery-fifty-fifty.webp', alt: 'FIFTY-FIFTY', bgColor: "#00ACC1", amount: "₹9,60,000", label: "Jackpot", code: 'FF-112', nextDraw: 'null', name: 'FIFTY-FIFTY' },
    { id: "9", src: '../../../assets/lottery-karunya-plus.webp', alt: 'KARUNYA PLUS', bgColor: "#D81B60", amount: "₹9,60,000", label: "Jackpot", code: 'KN-540', nextDraw: 'null', name: 'KARUNYA PLUS' },
    { id: "10", src: '../../../assets/lottery-nirmal.webp', alt: 'NIRMAL', bgColor: "#3949AB", amount: "₹9,60,000", label: "Jackpot", code: 'NR-398', nextDraw: 'null', name: 'NIRMAL' },
    { id: "11", src: '../../../assets/lottery-karunya.webp', alt: 'KARUNYA', bgColor: "#8E24AA", amount: "₹9,60,000", label: "Jackpot", code: 'KN-539', nextDraw: 'null', name: 'KARUNYA' },
    { id: "12", src: '../../../assets/lottery-akshaya.webp', alt: 'AKSHAYA', bgColor: "#009688", amount: "₹9,60,000", label: "Jackpot", code: 'AK-670', nextDraw: 'null', name: 'AKSHAYA' },
    { id: "13", src: '../../../assets/lottery-winwin.webp', alt: 'WIN-WIN', bgColor: "#E91E63", amount: "₹9,60,000", label: "Jackpot", code: 'W-788', nextDraw: 'null', name: 'WIN-WIN' },
  ]

  instantLotteries = [
    { id: 1, src: '../../../assets/lottery-1.5-quick.webp', alt: 'JackPot 2 Min', bgColor: "#00796B", amount: "₹9,60,000", label: "Jackpot", code: 'JK2-0203', nextDraw: '2 Minute', name: 'JackPot 2 Min' },
    { id: 2, src: '../../../assets/lottery-3-min.webp', alt: 'JackPot 3 Min', bgColor: "#8E24AA", amount: "₹9,60,000", label: "Jackpot", code: 'JackPot 3 Min', nextDraw: '3 Minute', name: 'JackPot 3 Min' },
    { id: 3, src: '../../../assets/lottery-5-min.webp', alt: 'JackPot 5 Min', bgColor: "#E53935", amount: "₹9,60,000", label: "Jackpot", code: 'JackPot 5 Min', nextDraw: '5 Minute', name: 'JackPot 5 Min' },
  ];

  bottomButton = [
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '', sumGame: 'null', color: '', triplePair: 0 },
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '', color: '', sumGame: 'null', triplePair: 0 },
    { imageSrc: '../../../../assets/dice-game/dice-single-question.b690ec8b.webp', value: '24x', data: '', color: '', sumGame: 'null', triplePair: 0 },
  ];

  getSumImages() {
    return this.sumImages;
  }

  getDiceImages() {
    return this.diceImages;
  }

  getQuestionImage() {
    return this.bottomButton[0];
  }

  updateLotteryImages(): Observable<any> {
    return new Observable(observer => {
      this.lotteryService.getActive().subscribe((activeLotteriesResponse: any) => {
        let activeLotteriesData = activeLotteriesResponse.data;
  
        // Sort the active lotteries based on gameEndTimeIST (ascending)
        activeLotteriesData = activeLotteriesData.sort((a: any, b: any) => {
          return new Date(a.gameEndTimeIST).getTime() - new Date(b.gameEndTimeIST).getTime();
        });
  
        this.stateLotteries.forEach(image => {
          // Find the lottery based on code or name
          const matchedLottery = activeLotteriesData.find((lottery: any) => 
            lottery.code === image.id || lottery.name === image.alt
          );
          if (matchedLottery) {
            image.id = matchedLottery._id;
            image.nextDraw = matchedLottery.gameEndTimeIST;
          }
        });
        this.stateLotteries.sort((a: any, b: any) => {
          return new Date(a.nextDraw).getTime() - new Date(b.nextDraw).getTime();
        });
        observer.next(this.stateLotteries);
        observer.complete();
      });
    });
  }

  // This method returns lottryImages after updating them
  getUpdatedLotteryImages(): Observable<any> {
    return this.updateLotteryImages();
  }

  getStateLotteries(): any[] {
    return this.stateLotteries
  }

  getAllLotteryImages(): any[] {
      return this.lottryImages
  }

  getInstantLotteries(): any[] {
    return this.instantLotteries;
  }
}
