<div class="flex w-full justify-center">
    <div class="max-w-md container">
        <header class="toolbar header-row overflow-hidden">
            <div class="nav-icon" (click)="handleBackClick()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"
                    style="width: 1rem; height: 1rem;">
                    <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </div>
            <div class="title">Withdraw Request</div>
            <div class="actions">
                <a class="action-button" href="./wallet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c"
                        class="bi bi-wallet-fill" viewBox="0 0 16 16">
                        <path
                            d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
                        <path
                            d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
                    </svg>
                </a>
            </div>
        </header>

        <form class="payout-form" #payoutForm="ngForm" (ngSubmit)="payout()">
            <div class="form-group">
                <label for="amount">Amount:</label>
                <input type="number" maxlength="10" minlength="3" min="100" id="amount" name="amount"
                    [(ngModel)]="amount" required>
            </div>

            <div class="form-group" *ngIf="bankAccounts.length > 0">
                <label for="bankAccount">Select Existing Account :</label>
                <ng-select [items]="bankAccounts" name="account" [(ngModel)]="selectedBankAccount"
                    bindLabel="accountNumber" [hideSelected]="true" (click)="onSelectBankAccount()"
                    (clear)="removeBankAccountSelection()">
                </ng-select>
            </div>

            <div class="form-group">
                <label for="accountNumber">Account Number:</label>
                <input type="number" maxlength="30" id="accountNumber" bindValue="code" name="accountNumber"
                    [(ngModel)]="accountNumber" required (ngModelChange)="clearBankSelection()">
            </div>
            <div class="form-group">
                <label for="confirmAccountNumber">Confirm Account Number:</label>
                <input type="number" maxlength="30" id="confirmAccountNumber" name="confirmAccountNumber"
                    [(ngModel)]="confirmAccountNumber" required (ngModelChange)="clearBankSelection()">
            </div>
            <div class="form-group">
                <label for="ifscCode">IFSC Code:</label>
                <input type="text" maxlength="11" minlength="11" id="ifscCode" name="ifscCode" [(ngModel)]="ifscCode"
                    required (ngModelChange)="clearBankSelection()">
            </div>

            <div class="form-group">
                <label for="bankCode">Bank:</label>
                <ng-select [items]="bankCodes" bindLabel="name" bindValue="code" [(ngModel)]="selectedBankCode"
                    name="bankCode" [hideSelected]="true" required (ngModelChange)="clearBankSelection()">
                </ng-select>
            </div>

            <div class="form-group">
                <label for="name">Account holder name:</label>
                <input type="text" minlength="3" maxlength="30" id="name" name="name" [(ngModel)]="name" required
                    (ngModelChange)="clearBankSelection()">
            </div>

            <div class="form-group">
                <label for="upi_id">UPI Id:</label>
                <input type="text" maxlength="30" id="upi_id" name="upiId" [(ngModel)]="upiId"
                    (ngModelChange)="clearBankSelection()">
            </div>

            <div class="form-group">
                <button type="submit"
                    [disabled]="!payoutForm.valid|| accountNumber !== confirmAccountNumber">Payout</button>
            </div>
        </form>
    </div>
</div>