<div class="flex w-full justify-center">
  <div class="container max-w-md">
    <header class="toolbar header-row overflow-hidden">
      <div class="title">Signup</div>
      <button class="close-button" (click)="goBack()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style="width: 1.5rem; height: 1.5rem;">
          <path stroke="#48505D" d="m16.8 7.2-9.6 9.6M7.2 7.2l9.6 9.6"></path>
        </svg>
      </button>
    </header>
    <div class="content">
      <div class="login-container">
        <div class="tab-content">
          <div class="phone-container">
            <div class="country-code">+91</div><input type="tel" maxlength="10" placeholder="Enter Phone Number"
              [(ngModel)]="phone">
          </div>
          <input type="password" placeholder="Password" [(ngModel)]="password">
          <div class="otp-container">
            <input type="number" maxlength="4" placeholder="OTP" [(ngModel)]="otp">
            <button class="get-otp" (click)="getOtp()" [disabled]="!phone || countdown > 0"> {{ otpButtonText }}
            </button>
          </div>
          <input type="text" placeholder="Referral code" [(ngModel)]="referralCode">
          <button class="submit" [class.active]="otp && phone && password" (click)="signup()">Sign up</button>
        </div>
        <div class="signup-container"><span class="new-text">Already have an account? </span><a [routerLink]="'/login'"
            class="signup">Login</a></div>
      </div>
    </div>
  </div>
</div>