import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { timer, Subscription } from "rxjs";
import { Location } from '@angular/common';
import { TokenStorageService } from '../../_services/token-storage.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { take } from 'rxjs/operators';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetExampleComponent } from '../../bottom-sheet-example/bottom-sheet-example.component';
import { WalletService } from '../../_services/wallet.service';
import { Color } from '../../enums/color'
import { SelectedBet } from '../../model/selected-bet.model'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { BallGameService } from 'src/app/_services/ball.game.service';
import { AuthService } from 'src/app/_services/auth.service';
import { BallGameOrdersComponent } from './ball-game-orders/ball-game-orders.component';
import { AnimationOptions } from 'ngx-lottie';

interface Tab {
  title: string;
  selected: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './color-home.component.html',
  styleUrls: ['./color-home.component.css'],
  animations: [
    trigger('slideInOut', [
      state('void', style({
        transform: 'translateY(-100%)'
      })),
      state('*', style({
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        animate('.5s ease-in')
      ]),
      transition('* => void', [
        animate('.5s ease-out', style({
          transform: 'translateY(100%)'
        }))
      ])
    ])
  ]
})
export class ColorHomeComponent implements OnInit {
  @ViewChild('itemElement') itemElement!: ElementRef;
  @ViewChild('orderTab') orderTabComponent?: BallGameOrdersComponent;
  content?: string;
  gameId = "";
  timer = "00:00";
  private countDown: Subscription = new Subscription();
  counter = 0;
  minRemainingTimeToComplete = 0;
  tick = 1000;
  selectedBottomTab = 0;
  blocked = false
  lastWonPrediction = ""
  wonNumber = ""
  predictionWonClass = ""
  dynamicWidth = '0%';
  resultData: any
  totalResultPages = 0
  totalResults = 0
  displayModal: boolean = false;
  itemLength = 6;
  selctedConfigIndex = 0;
  gameConfig = [1, 2, 3, 5, 10, 15];
  wonAmount = 0
  showWonStatusDialog = false
  totalWalletAmount = 0
  isLoggedIn = false
  betItems: { myBetClass: string, myBetNumber: string }[] = [];
  isButtonDisabled = ""
  bottomSheetRef!: MatBottomSheetRef
  tabs: any[] = [];
  selectedTab: any
  winners: any
  activeTab: number = 1;
  countdownStart = false
  countDownAudio = new Audio();
  winAudio = new Audio();
  currentGame = 1
  isLoading = false
  currentPage = 1
  options: AnimationOptions = {
    path: '../../../assets/countdown.json',
    loop: false,
    autoplay: true
  };
  optionsForWin1: AnimationOptions = {
    path: '../../../assets/W4n40kZKLQ.json',
    loop: true,
    autoplay: true
  };

  optionsForWin2: AnimationOptions = {
    path: '../../../assets/WvyeTtaxax.json',
    loop: false,
    autoplay: true
  };

  constructor(private ballGameService: BallGameService, private tokenStorageService: TokenStorageService,
    private authservice: AuthService,
    private bottomSheet: MatBottomSheet, private walletService: WalletService, private lotteryServices: BallGameService,
    private route: ActivatedRoute, private router: Router, private location: Location) { }

  onTabSelected(event: Event): void {
    const selectedTab = (event.target as HTMLAnchorElement).innerText;
    const tab = this.tabs.find(t => t.title === selectedTab);
    if (tab) {
      this.selectTab(tab);
    }
  }

  selectTab(tab: Tab): void {
    this.tabs.forEach(t => t.selected = false);
    tab.selected = true;
  }


  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: Event) {
    if (!document.hidden) {
      if (this.currentGame) {
        this.makeTabActive(Number(this.currentGame))
      } else {
        this.getNearestLottery(1)
      }
    }
  }

  ngOnInit(): void {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'page_view', {
          page_path: event.urlAfterRedirects,
          page_title: document.title
        });
      }
    });
    this.countDownAudio.src = '../../../assets/count_down.mp3';
    this.countDownAudio.load();
    this.winAudio.src = '../../../assets/win_sound.mp3';

    if (this.tokenStorageService.isLoggedIn()) {
      this.isLoggedIn = true
      this.getWalletInfo();
    }
    this.initTabs()
    this.route.queryParams.subscribe(params => {
      this.currentGame = params['cycle'];
      if (this.currentGame) {
        this.makeTabActive(Number(this.currentGame))
      } else {
        this.getNearestLottery(1)
        this.getLatest(1, 1)
        this.getWinStatusIfAny(1)
        this.getWinners()
      }
    });
  }

  makeTabActive(cycle: number) {
    const configIndex = this.gameConfig[cycle - 1];
    const tabId = `nav-${configIndex}-min-tab`;
    const tabElement = document.getElementById(tabId);

    if (tabElement) {
      tabElement?.click();
    }
  }

  isActiveTab(tabNumber: number): boolean {
    return this.activeTab === tabNumber;
  }

  initTabs() {
    this.tabs = [
      { title: 'Results', selected: true },
      { title: 'Winners', selected: false }
    ];

    if (this.isLoggedIn) {
      this.tabs.push({ title: 'My Orders', selected: false });
    }
  }

  getWinStatusIfAny(source: number) {
    if (this.tokenStorageService.isLoggedIn()) {
      this.ballGameService.getWinStatus().subscribe({
        next: data => {
          if (source != 1) {
            this.clearBets()
          }
          if (source === 2 && data.totalWinAmount > 0) {

            if (this.selectedBottomTab == 2) {
              this.orderTabComponent?.getMyOrders()
            }
            this.playWinAudio()
            this.wonAmount = data.totalWinAmount
            this.getWalletInfo()
            this.showWonStatusDialog = true
            const countdownTimer = timer(1000, 1000).pipe(take(5 + 1));
            countdownTimer.subscribe({
              complete: () => {
                this.stopWinAudio()
                this.showWonStatusDialog = false
              }
            });
          }
        }
      })
    }
  }

  playWinAudio() {
    this.winAudio.play();
  }

  stopWinAudio() {
    if (!this.winAudio.paused) {
      return;
    }
    if (!document.hidden) {
      this.winAudio.pause();
      this.winAudio.currentTime = 0;
    }
  }

  clearBets() {
    this.betItems = [];
  }

  closeWinDialog() {
    this.showWonStatusDialog = false;
    this.stopWinAudio()
  }

  getWinners() {

  }

  getLatest(configId: number, page: number) {
    this.ballGameService.getLatest(configId, 10, page).subscribe({
      next: data => {
        if (page == 1) {
          this.predictionWonClass = this.getElementClassFrom(data.data[0].winColor)
          this.wonNumber = data.data[0].winNumber
        }
        this.currentPage = page
        this.totalResultPages = data.totalPages
        this.totalResults = data.totalGames
        this.resultData = data.data
      },
      error: err => {
        console.error("Error" + err.message);
      }
    });
  }

  onPageResultsPageChange() {
    let configId = this.gameConfig[this.activeTab]
    this.getLatest(configId, this.currentPage + 1)
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
    this.stopCounDownAudio()
    this.stopWinAudio()
  }

  playCoundDownAudio() {
    if (!this.countDownAudio.paused) {
      return;
    }
    if (!document.hidden) {
      this.countDownAudio.play();
    }
  }

  stopCounDownAudio() {
    this.countDownAudio.pause();
    this.countDownAudio.currentTime = 0;
  }

  getNearestLottery(configId: number): void {
    this.isLoading = true
    this.ballGameService.getNearestLottery(configId).subscribe({
      next: data => {
        this.isLoading = false
        if (this.tokenStorageService.isLoggedIn()) {
          this.getMyCurrentBetIfAny(data.data._id)
        }
        this.countDown.unsubscribe();
        if (data.data.countdownTime == 0) {
          this.blocked = false
          this.countdownStart = true
          this.playCoundDownAudio()
          setTimeout(() => {
            this.countdownStart = false
            this.getResult(data.data._id);
            this.getLatest(configId, 1);
            if (this.selectedBottomTab == 2) {
              this.orderTabComponent?.getMyOrders()
            }
            this.stopCounDownAudio()
            this.getNearestLottery(configId);
            this.getWinStatusIfAny(2)
            this.isButtonDisabled = "disabled"
          }, 3000);
          return;
        }
        this.content = data;
        this.gameId = data.data.issueNo
        this.counter = data.data.countdownTime + 2
        this.minRemainingTimeToComplete = data.data.minRemainingSecond
        this.countDown = timer(0, this.tick).subscribe(() => {
          this.isButtonDisabled = ""
          --this.counter
          this.dynamicWidth = this.getProgressFrom(configId, this.counter)
          if (this.counter <= data.data.minRemainingSecond) {
            this.bottomSheetRef?.dismiss()
            this.isButtonDisabled = "disabled"
            if (this.counter > 3) {
              this.blocked = true
            }
          }
          if (this.counter == 0) {
            this.countDown.unsubscribe();
          }
          if (this.counter == 3) {
            this.blocked = false
            this.countdownStart = true
            this.playCoundDownAudio()

            setTimeout(() => {
              this.countdownStart = false
              this.getResult(data.data._id);
              this.getLatest(configId, 1);
              this.getNearestLottery(configId);
              this.getWinStatusIfAny(2)
            }, 3000);
          }
        });
      },
      error: err => {
        this.isLoading = false
        console.error("Error " + err.message);
      }
    });
  }

  getMyCurrentBetIfAny(gameId: string) {
    this.lotteryServices.myBets(gameId).subscribe({
      next: data => {
        if (data.data.length > 0) {
          const betsToAdd = data.data.map((item: { predictionColor: string, predictionNumber: number }) => ({
            color: item.predictionColor,
            number: item.predictionNumber
          }));
          this.showMyBetsFromResponse(betsToAdd);
        } else {
          this.clearBets()
        }
      },
      error: err => {
      }
    })
  }

  getResult(gameId: string): void {
    this.ballGameService.getResult(gameId).subscribe({
      next: data => {
        this.predictionWonClass = this.getElementClassFrom(data.data.winColor)
        this.wonNumber = data.data.winNumber
      },
      error: err => {
        console.error("Error " + err.message);
      }
    });
  }

  handleTabClick(config: number, index: number): void {
    this.activeTab = index;
    this.clearBets()
    this.countDown.unsubscribe();
    this.blocked = false;
    this.getNearestLottery(config);
    this.getLatest(config, 1)
    if (this.selectedBottomTab == 2) {
      this.orderTabComponent?.getMyOrders()
    }
    this.selctedConfigIndex = index;
    this.scrollToNextItem(index + 1);
    const queryParams = { ...this.route.snapshot.queryParams };
    queryParams['cycle'] = config;
    this.location.replaceState(this.router.url.split('?')[0], new URLSearchParams(queryParams).toString());
  }

  ngAfterViewInit(): void {
    this.scrollToNextItem(this.activeTab)
  }

  scrollToNextItem(index: number): void {
    if (this.itemElement) {
      const elementList = this.itemElement.nativeElement.children;
      if (index > 3 && index < elementList.length) {
        const nextElement = elementList[index];
        nextElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    }
  }

  colorClicked(color: string, betNumber: number) {
    this.openBetCountWindow(color, betNumber)
  }

  openBetCountWindow(color: string, betNumber: number) {
    if (this.counter > this.minRemainingTimeToComplete) {
      const dataToSend = { color: color, number: betNumber, gameId: this.gameId };
      this.bottomSheetRef = this.bottomSheet.open(BottomSheetExampleComponent, {
        data: dataToSend
      });
      this.bottomSheetRef.afterDismissed().subscribe(result => {
        if (result.payload.code == 100 && result.payload.type == 'color') {
          this.getWalletInfo();
          this.showMyBets(result.payload.selectedBet)
          if (this.selectedBottomTab == 2) {
            this.orderTabComponent?.getMyOrders()
          }
        }
      });
    }
  }

  showMyBets(selectedBet: SelectedBet) {
    const myBetClass = this.getMyBetClass(selectedBet.color)
    const myBetNumber = this.getMyBetNumberString(selectedBet.number, selectedBet.color)
    this.betItems.push({ myBetClass: myBetClass, myBetNumber: myBetNumber });

  }

  showMyBetsFromResponse(bets: { color: string, number: number }[]) {
    bets.forEach(bet => {
      const myBetClass = this.getMyBetClassFromResponse(bet.color);
      const myBetNumber = this.getMyBetNumberResponse(bet.number, bet.color);
      this.betItems.push({ myBetClass: myBetClass, myBetNumber: myBetNumber });
    });
  }

  getMyBetNumberResponse(number: number, color: string) {
    var textToShow = ""
    if (number != null && number >= 0) {
      textToShow = number.toString()
    } else {
      switch (color) {
        case 'gv':
          textToShow = Color.GREEN_VIOLET;
          break;
        case 'g':
          textToShow = Color.GREEN;
          break;
        case 'r':
          textToShow = Color.RED;
          break;
        case 'rv':
          textToShow = Color.RED_VIOLET;
          break;
        case 'v':
          textToShow = Color.VIOLET;
          break;
        default:
          textToShow = "";
      }
    }
    return textToShow
  }

  getMyBetNumberString(number: number, color: string) {
    if (number === -1) {
      return color
    } else {
      return number.toString()
    }
  }

  getMyBetClassFromResponse(color: string) {
    let className = ""
    switch (color) {
      case 'gv':
        className = 'color-ball-green-violet-full color-ball-my-bet';
        break;
      case 'g':
        className = 'color-ball-green-full color-ball-my-bet';
        break;
      case 'r':
        className = 'color-ball-red-full color-ball-my-bet';
        break;
      case 'rv':
        className = 'color-ball-red-violet-full color-ball-my-bet';
        break;
      case 'v':
        className = 'color-ball-violet-full color-ball-my-bet';
        break;
      default:
        className = '';
    }
    return className
  }

  getMyBetClass(color: string) {
    let className = ""
    switch (color) {
      case Color.GREEN_VIOLET:
        className = 'color-ball-green-violet-full color-ball-my-bet';
        break;
      case Color.GREEN:
        className = 'color-ball-green-full color-ball-my-bet';
        break;
      case Color.RED:
        className = 'color-ball-red-full color-ball-my-bet';
        break;
      case Color.RED_VIOLET:
        className = 'color-ball-red-violet-full color-ball-my-bet';
        break;
      case Color.VIOLET:
        className = 'color-ball-violet-full color-ball-my-bet';
        break;
      default:
        className = '';
    }
    return className
  }

  getWalletInfo() {
    this.walletService.getInfo().subscribe({
      next: data => {
        this.totalWalletAmount = data.data.wallet.totalAmount
      },
      error: err => {

      }
    })
  }

  getElementClassFrom(winColor: string): string {
    var className = ""
    switch (winColor) {
      case 'gv':
        className = 'violet-green color-ball-green-violet';
        break;
      case 'g':
        className = 'green color-ball-green';
        break;
      case 'r':
        className = 'red color-ball-red';
        break;
      case 'rv':
        className = 'violet-red color-ball-red-violet';
        break;
      case 'v':
        className = 'violet color-ball-violet';
        break;
      default:
        className = '';
    }
    return className
  }

  getProgressFrom(configId: number, remaining: number): string {
    let duration
    switch (configId) {
      case 1:
        duration = 1
        break;
      case 2:
        duration = 2
        break;
      case 3:
        duration = 3
        break;
      case 4:
        duration = 5
        break;
      case 5:
        duration = 10
        break;
      case 6:
        duration = 15
        break;
      default:
        duration = 1;
    }
    return (remaining / (duration * 60)) * 100 + "%";
  }

  handleBackClick() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  onTabClick(index: number): void {
    if (index === 3) {
      if (!this.authservice.checkLoggedIn()) {
        this.router.navigate(['/login']);
        return;
      }
    }
    this.selectedBottomTab = index;
  }
}
