<div class="top-contain">
  <div class="dice-select-top h-10 flex items-center px-3 text-sm">
    3 of the same number: odds(150)
    <button (click)="firststModal()"
      class="flex items-center justify-center w-5 h-5 ml-2.5 rounded-full text-sm font-bold text-white focus:outline-none"
      style="background-color: #ff7a00"
    >
      ?
    </button>
  </div>
  <div class="grid px-3 gap-2 grid-cols-3">
    <button
      *ngFor="let button of buttons"
      [ngClass]="isButtonDisabled"
      class="flex flex-col px-3 py-2 items-center justify-center gap-1 bg-white rounded-xl"
      style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
      (click)="diceClicked(button)"
    >
      <!-- Center image and data with dynamic color -->
      <div class="md:h-22 md:w-24 h-18 w-20 flex items-center justify-center">
        <div class="flex flex-col">
          <div class="flex items-center justify-center">
            <div
              class="bg-full w-10 h-10 md:w-12 md:h-12 dice-sum-big"
              [style.backgroundImage]="'url(' + button.imageSrc + ')'"
            ></div>
          </div>
          <div class="flex flex-row">
            <div
              class="bg-full w-10 h-10 md:w-12 md:h-12 dice-sum-big"
              [style.backgroundImage]="'url(' + button.imageSrc + ')'"
            ></div>
            <div
              class="bg-full w-10 h-10 md:w-12 md:h-12 dice-sum-big"
              [style.backgroundImage]="'url(' + button.imageSrc + ')'"
            ></div>
          </div>
        </div>
      </div>
      <!-- Dynamic value binding -->
      <div class="bg-content">
        {{ button.value }}
      </div>
    </button>
  </div>
  <div class="dice-select-top h-10 flex items-center px-3 text-sm">
    Any 3 of the same number: odds(24)
    <button (click)="secondModal()"
      class="flex items-center justify-center w-5 h-5 ml-2.5 rounded-full text-sm font-bold text-white focus:outline-none"
      style="background-color: #ff7a00"
    >
      ?
    </button>
  </div>
  <div class="flex top-contain md:h-16 h-12 items-center p-2 justify-center">
    <button
      class="flex items-center flex-1 justify-center rounded-lg bg-white py-1 gap-1"
      style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)"
      [ngClass]="isButtonDisabled"
      (click)="diceClicked(bottomButton[0])"
    >
      <div class="flex flex-row">
        <div
          class="bg-full dice-sum-big w-10 h-10 md:w-12 md:h-12"
          *ngFor="let data of bottomButton"
          [style.backgroundImage]="'url(' + data.imageSrc + ')'"
        ></div>
      </div>
      <div class="flex items-center overflow-hidden justify-center">
        <div class="text-xs md:text-base">24X</div>
      </div>
    </button>
  </div>
</div>
