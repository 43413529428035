<div class="flex w-full justify-center">
<div class="container max-w-md">
  <header class="toolbar header-row overflow-hidden">
    <div class="nav-icon" (click)="handleBackClick()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" style="width: 1rem; height: 1rem;">
        <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
          stroke-linejoin="round"></path>
      </svg>
    </div>
    <div class="title">Account</div>
    <div class="actions">
      <a class="action-button" href="./wallet">
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c" class="bi bi-wallet-fill"
          viewBox="0 0 16 16">
          <path
            d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
          <path
            d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
        </svg>
      </a>
    </div>
  </header>
  <div class="profile-wrapper">
    <div class="profile-card">
      <div class="user-info">
        <div class="me-label">
          Me
        </div>
        <div class="user-container">
          <div class="user-name">
            <div class="user-details">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                class="w-6 h-6">
                <path stroke="#fff"
                  d="M16.039 3.9H7.961c-.67 0-1.212.543-1.212 1.212v13.73c0 .67.542 1.212 1.212 1.212h8.076c.67 0 1.212-.542 1.212-1.211V5.112c0-.67-.542-1.212-1.212-1.212Z">
                </path>
                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                  d="M11.192 6.323h1.616M10.385 17.63h3.23"></path>
              </svg>
              {{ user.username }}
              <span class="user-id">ID : {{ user.uid }}</span>
            </div>
          </div>
        </div>
        <div class="my-wallet">
          <div class="all-balance">
            <a class="wallet-link" routerLink="/wallet">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"
                class="w-4 h-4">
                <path stroke="#fff" stroke-linejoin="round"
                  d="M13 5.333V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-2.333"></path>
                <path stroke="#fff" stroke-linejoin="round"
                  d="M13 5.333H8.333a1 1 0 0 0-1 1v3.334a1 1 0 0 0 1 1H13a1 1 0 0 0 1-1V6.333a1 1 0 0 0-1-1Z"></path>
                <path fill="#fff" d="M9.333 8a.667.667 0 1 1 1.334 0 .667.667 0 0 1-1.334 0"></path>
              </svg> My Wallet</a>
            <div class="balance-disply">
              <a class="wallet-link" routerLink="/wallet">{{user.wallet.totalAmount}} ></a>
            </div>
          </div>
          <div class="withdrawable-container">
            <div class="withdrawable-amount">Withdrawable Amount
              <div class="withdrawable-balance-disply">{{user.wallet.withdrawableAmount}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="withdraw">
      <button class="btn-add-fund" (click)="handleRechargeClick()">Recharge<img src="../../assets/recharge.svg" /></button>
      <button class="btn-withdraw" (click)="handleWithdrawClick()">Withdraw<img src="../../assets/cash-out.svg" /></button>
    </div>

    <ng-template #loggedOut>
      Please login.
    </ng-template>
    <div class="logout">
      <a href class="logout-link" routerLink="wallet" (click)="logout()">Logout</a>
    </div>
  </div>
</div>
</div>