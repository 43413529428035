import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, FormatTimePipe, SplitPipe } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ColorHomeComponent } from './games/color-game/color-home.component';
import { WalletComponent } from './user/wallet/wallet.component';
import { ProfileComponent } from './user/profile/profile.component';
import { BoardAdminComponent } from './admin/board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { ModalComponent } from './modal/modal.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomSheetExampleComponent } from './bottom-sheet-example/bottom-sheet-example.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserAccountComponent } from './user-account/user-account.component';
import { PaymentComponent } from './payment/payment.component';
import { BetResultsComponent } from './bet-results/bet-results.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeTabComponent } from './home/home-tab/home-tab.component';
import { BallGameResultsTabComponent } from './games/ball-game-results-tab/ball-game-results-tab.component';
import { GameWinnerComponentComponent } from './game-winner-component/game-winner-component.component';
import { PayoutComponent } from './payout/payout.component';
import { HomeBannerComponent } from './home/home-banner/home-banner.component';
import { GamesHomeComponent } from './home/games-home/games-home.component';
import { PopularGamesComponent } from './home/popular-games/popular-games.component';
import { HomeDiceGameComponent } from './home/home-dice-game/dice-game.component';
import { HomeColorGameComponent } from './home/home-color-game/color-game.component';
import { HomeLotteryGameComponent } from './home/home-lottery-game/lottery-game.component';
import { HomeGameTabsComponent } from './home/home-game-tabs/home-game-tabs.component';
import { LotteryGamesComponent } from './games/lottery-games/lottery-games.component';
import { GamesBottomSheetComponent } from './games/games-bottom-sheet/games-bottom-sheet.component';
import { MatSelectModule } from '@angular/material/select';
import { TwosidesComponent } from './games/gameTypes/twosides/twosides.component';
import { OneDComponent } from './games/gameTypes/one-d/one-d.component';
import { FishPrawnComponent } from './games/gameTypes/fish-prawn/fish-prawn.component';
import { CountdownPipe } from './pipes/countdown.pipes';
import { TwoDComponent } from './games/gameTypes/two-d/two-d.component';
import { ThreeDComponent } from './games/gameTypes/three-d/three-d.component';
import { FourDComponent } from './games/gameTypes/four-d/four-d.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { Chart2Component } from './chart/chart2/chart2.component';
import { NgChartsModule } from 'ng2-charts';
import { ResultHistoryComponent } from './games/lottery-games/result-history/result-history.component'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MyOrdersComponent } from './games/my-orders/my-orders.component';
import { DuplicatePopupComponent } from './games/lottery-games/duplicate-popup/duplicate-popup.component';
import { TimerComponent } from './games/timer/timer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RulesPopupComponent } from './games/lottery-games/rules-popup/rules-popup.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DiceGameComponent } from './games/dice-game/dice-game.component';
import { SumComponent } from './games/dice-game/sum/sum.component';
import { TrippleComponent } from './games/dice-game/tripple/tripple.component';
import { DoubleComponent } from './games/dice-game/double/double.component';
import { SingleComponent } from './games/dice-game/single/single.component';
import { ResultComponent } from './games/dice-game/result/result.component';
import { PlayPopupComponent } from './games/dice-game/play-popup/play-popup.component';
import { PreSalePopupComponent } from './games/dice-game/pre-sale-popup/pre-sale-popup.component';
import { AnalysisComponent } from './games/dice-game/analysis/analysis.component';
import { TriplemodalComponent } from './games/dice-game/triplemodal/triplemodal.component';
import { MyOrderComponent } from './games/dice-game/my-order/my-order.component';
import { WinnersComponent } from './games/dice-game/winners/winners.component';
import { HomeInstantLotteryComponent } from './home/home-instant-lottery/home-instant-lottery.component';
import { HomeBottomNavComponent } from './home/home-bottom-nav/home-bottom-nav.component';
import { HomeToolbarComponent } from './home/home-toolbar/home-toolbar.component';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';


import { environment } from '../environments/environment';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TrimUppercasePipe } from './pipes/trim-uppercase.pipe';
import { RoundTimePipe } from './pipes/round-time.pipe';
import { BallGamewinnersComponent } from './games/color-game/ball-gamewinners/ball-gamewinners.component';
import { BallGameOrdersComponent } from './games/color-game/ball-game-orders/ball-game-orders.component';
import { PromoHomeScreenWheelComponent } from './home/promo-home-screen-wheel/promo-home-screen-wheel.component';
import { NgxWheelModule } from 'ngx-wheel';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ColorHomeComponent,
    WalletComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,
    FormatTimePipe,
    SplitPipe,
    ModalComponent,
    BottomSheetExampleComponent,
    UserAccountComponent,
    PaymentComponent,
    BetResultsComponent,
    HomeTabComponent,
    BallGameResultsTabComponent,
    GameWinnerComponentComponent,
    PayoutComponent,
    HomeBannerComponent,
    GamesHomeComponent,
    PopularGamesComponent,
    HomeDiceGameComponent,
    HomeColorGameComponent,
    HomeLotteryGameComponent,
    HomeGameTabsComponent,
    LotteryGamesComponent,
    GamesBottomSheetComponent,
    TwosidesComponent,
    OneDComponent,
    FishPrawnComponent,
    CountdownPipe,
    TwoDComponent,
    ThreeDComponent,
    FourDComponent,
    Chart2Component,
    ResultHistoryComponent,
    MyOrdersComponent,
    DuplicatePopupComponent,
    TimerComponent,
    RulesPopupComponent,
    DiceGameComponent,
    SumComponent,
    TrippleComponent,
    DoubleComponent,
    SingleComponent,
    ResultComponent,
    PlayPopupComponent,
    PreSalePopupComponent,
    AnalysisComponent,
    TriplemodalComponent,
    MyOrderComponent,
    WinnersComponent,
    HomeInstantLotteryComponent,
    HomeBottomNavComponent,
    HomeToolbarComponent,
    CapitalizePipe,
    TrimUppercasePipe,
    RoundTimePipe,
    BallGamewinnersComponent,
    BallGameOrdersComponent,
    PromoHomeScreenWheelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatBottomSheetModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSnackBarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    CarouselModule,
    MatSelectModule,
    CanvasJSAngularChartsModule,
    NgChartsModule,
    MatPaginatorModule,
    MatDialogModule,
    MatExpansionModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    NgxWheelModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    authInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
