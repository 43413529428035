import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SelectedOptionService } from 'src/app/_services/selectedOption.service';
import { sharedService } from 'src/app/_services/shared.service';
import { GameTypeSelect } from 'src/app/model/gameType.interface';

@Component({
  selector: 'app-fish-prawn',
  templateUrl: './fish-prawn.component.html',
  styleUrls: ['./fish-prawn.component.css']
})
export class FishPrawnComponent implements OnInit {

  selectedValues: string[] = [];

  constructor(private sharedService:sharedService){}

  @Input() selectedGame:GameTypeSelect[]=[]
    ngOnInit(): void {}

// checking the datas in the cart 
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedGame']) {
      this.selectedValues = this.sharedService.handleSelectedGameChange(this.selectedGame, 'FishPrawnCrab');  
    }
  }
  onCheckboxChange(value: string, color: string, index: number): void {
    this.selectedValues = this.sharedService.handleCheckboxChange(this.selectedValues, value, color, index,'FishPrawnCrab');
  }

}
