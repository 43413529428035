import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LotteryService } from 'src/app/_services/lottery.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-result-history',
  templateUrl: './result-history.component.html',
  styleUrls: ['./result-history.component.css']
})
export class ResultHistoryComponent implements OnInit {
  @Input() lotteryName: string = ''
  currentPage = 0; // Start at the first page
  itemsPerPage = 10; // Set the default items per page
  totalItems = 0; // Total number of items
  totalPages = 0; // Total number of pages
  resultHistory: any[] = []; // Store the paginated result

  constructor(private lotteryService: LotteryService) { }

  ngOnInit(): void {
    this.fetchResults();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lotteryName'] && !changes['lotteryName'].isFirstChange()) {
      this.fetchResults();
    }
  }

  fetchResults(): void {
    if (this.lotteryName) {
      this.lotteryService.getPagination(this.lotteryName, this.currentPage, this.itemsPerPage).subscribe({
        next: (response) => {
          this.resultHistory = response.data;
          this.currentPage = response.currentPage;
          this.totalItems = response.totalCount;
          this.totalPages = response.totalPages;
        },
        error: (error) => {
          console.error(error);
        }
      });
    }
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.fetchResults(); // Fetch the results for the new page
  }
  getColorForChar(char: string): string {
    switch (char) {
      case 'S': return '#808080';
      case 'G': return 'rgb(213, 115, 0)';
      case '1': return 'rgb(0, 135, 212)';
      case '2': return 'rgb(213, 0, 0)';
      case '3': return 'rgb(103, 165, 25)';
      case '4': return 'rgb(255, 206, 0)';
      case '5': return 'rgb(0, 150, 136)';
      default: return 'rgb(150, 150, 150)';
    }
  }

}
