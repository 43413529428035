<div>
  <div class="header bg-gray-200 px-3 py-2 flex items-center justify-between rounded-md">
    <span class="font-bold text-gray-700">Issue</span>
    <span class="font-bold text-gray-700 ml-14">Numbrt</span>
    <span class="font-bold text-gray-700 ml-16">Color</span>
  </div>
  <div class="result-item bg-white p-3 flex items-center justify-between border-b" *ngFor="let item of resultData">
    <div class="flex flex-col items-start w-12 mr-4 flex-wrap">
      <span class="issue-number text-gray-700 ">{{item.gameId.gameId}}</span>
      <span class="issue-date text-gray-500 text-xs"></span>
    </div>

    <div class="flex items-center space-x-1">
      {{item.winNumber}}
    </div>

    <div class="flex justify-center">
      <span class="color-{{item.winColor}}"></span>
    </div>

  </div>
  
  <div class="paginator-container flex justify-center pb-4">
    <mat-paginator [length]="totalGames" [pageSize]="itemsPerPage"
      (page)="onPageChange($event)" aria-label="Select page">
    </mat-paginator>
  </div>

  <!-- <div class="paginator-container pb-4">
    <mat-paginator [length]="totalGames" (page)="onPageChange($event)" aria-label="Select page">
    </mat-paginator>
  </div> -->
</div>