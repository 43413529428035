<div class="flex w-full justify-center">
    <div class="container max-w-md">
        <header class="toolbar header-row overflow-hidden">
            <div class="nav-icon" (click)="handleBackClick()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"
                    style="width: 1rem; height: 1rem;">
                    <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
            </div>
            <div class="title">Color Prediction</div>
            <div class="actions">
                <a class="action-button" href="./wallet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c"
                        class="bi bi-wallet-fill" viewBox="0 0 16 16">
                        <path
                            d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
                        <path
                            d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
                    </svg>
                </a>
            </div>
        </header>
        <div class="charge-card">
            <span class="balance-label">Balance</span>
            <span class="balance-disply">₹{{walletBalance}}</span>
        </div>
        <div class="topup-container">
            <div class="manual-entry">
                <input (input)="checkInputValue()" class="edit-text amount-entry" [(ngModel)]="selectedAmount"
                    type="selectedAmount" min="300" max="50000" placeholder="Enter Recharge Amount">
                <span class="min-and-max">Min <b>₹300</b> Max <b>₹50000</b></span>
            </div>
            <div class="topup-button-container grid grid-cols-4 gap-4">
                <!-- Iterate through the recharge denominations -->
                <ng-container *ngFor="let row of rechargeDenominations">
                    <ng-container *ngFor="let value of row; let colIndex = index">
                        <button
                            [class.active]="activeButton?.row === rechargeDenominations.indexOf(row) && activeButton?.col === colIndex"
                            class="topup-{{value}} btn-topup"
                            (click)="handleClick(rechargeDenominations.indexOf(row), colIndex, value)">₹{{value}}</button>
                    </ng-container>
                </ng-container>
            </div>
            <div class="recharge-container">
                <div class="methods-table grid grid-cols-3 gap-4">
                    <div *ngFor="let method of paymentMethods" [class.active]="method.method === selectedMethod"
                        (click)="selectMethod(method.method)" class="center-content payment-col">
                        <svg *ngIf="selectedAmount === method.method" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 8 8" style="width: 0.5rem; height: 0.5rem;">
                            <path stroke="#fff" d="M1 3.667 2.926 5.74 6.666 2"></path>
                        </svg>
                        <img class="img image-payment" [src]="method.img" alt="{{ method.method }}">
                        <span class="payment-name">{{method.method}}</span>
                    </div>
                </div>
                <div class="items-center bottom-recharge">
                    <button [disabled]="!rechargeButtonEnabled"
                        [ngClass]="rechargeButtonEnabled ? 'enabled-button' : 'disabled-button'" class="btn-recharge"
                        (click)="recharge()" type="button">Recharge</button>
                </div>
            </div>
        </div>
    </div>
</div>