import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL_PATH = 'api/v1/promo/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'channel': 'lottopi', 'version': environment.apiVersion })
};


@Injectable({
    providedIn: 'root'
})
export class PromoService {
    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getLuckyWheelPromoCodes(): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getLuckyWheelPromo', httpOptions)
    }
}
