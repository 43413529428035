import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rules-popup',
  templateUrl: './rules-popup.component.html',
  styleUrls: ['./rules-popup.component.css']
})
export class RulesPopupComponent implements OnInit {
  panelOpenState = false;

  tabs = ['TwoSide', '4D', '3D', '2D', '4X', '3X','2X','1Digit ','FishPrawnCrab'];
  selectedTab = 0;

  // Define content for each tab
  tabContents: { [key: number]: string } = {
    0: 'Content for 1 Side tab goes here.',
    1: 'Content for 2 Side tab goes here.',
    2: 'Content for 3 Side tab goes here.',
    3: 'Content for 4 Side tab goes here.',
    4: 'Content for 5 Side tab goes here.',
    5: 'Content for 6 Side tab goes here.'
  };

  constructor(private dialogRef: MatDialogRef<RulesPopupComponent>) { }

  ngOnInit(): void {
  }

  selectTab(index: number): void {
    this.selectedTab = index;
  }

  closeModal(): void {
    // Implement your modal close logic here
    this.dialogRef.close()
    
  }
}
