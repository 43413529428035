<div class="flex w-full justify-center">
  <div class="container min-w-sm max-w-md">
    <header class="toolbar header-row w-full z-50 top-0 left-0" style="box-shadow: 2px 10px 7px -12px; ">
      <div class="nav-icon" (click)="handleBackClick()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" style="width: 1rem; height: 1rem;">
          <path d="M10.5 2L5 7.99676L10.5 14" stroke="#000" stroke-width="4" stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </div>
      <div class="font-bold text-lg flex-1">Color Prediction</div>
      <div class="actions" *ngIf="isLoggedIn">
        <div class="wallet-container">
          <span class="wallet-display">Balance</span>
          <span class="amount-display">₹{{ totalWalletAmount }}</span>
        </div>
        <a class="action-button" routerLink="/wallet">
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff891c" class="bi bi-wallet-fill"
            viewBox="0 0 16 16">
            <path
              d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
            <path
              d="M16 6.5h-5.551a2.7 2.7 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
          </svg>
        </a>
      </div>
    </header>
    <div class="game-selector scrollable-nav max-h-full overflow-y-auto" #scrollContainer>
      <nav class="navbar">
        <div class="nav nav-tabs min-tab" id="nav-tab" role="tablist" #itemElement>
          <button class="nav-link active" (click)="handleTabClick(1,0)" id="nav-1-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-1-min" type="button" role="tab" aria-controls="nav-1-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">1min</span></button>

          <button class="nav-link " (click)="handleTabClick(2,1)" id="nav-2-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-2-min" type="button" role="tab" aria-controls="nav-2-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">2min</span></button>

          <button class="nav-link " (click)="handleTabClick(3,2)" id="nav-3-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-3-min" type="button" role="tab" aria-controls="nav-3-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">3min</span></button>

          <button class="nav-link " (click)="handleTabClick(4,3)" id="nav-5-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-5-min" type="button" role="tab" aria-controls="nav-5-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">5min</span></button>


          <button class="nav-link " (click)="handleTabClick(5,4)" id="nav-10-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-10-min" type="button" role="tab" aria-controls="nav-10-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">10min</span></button>

          <button class="nav-link " (click)="handleTabClick(6,5)" id="nav-15-min-tab" data-bs-toggle="tab"
            data-bs-target="#nav-15-min" type="button" role="tab" aria-controls="nav-15-min">
            <img class="nav-icon" src="../../assets/clock.webp" />
            <span class="game-time">15min
            </span></button>
        </div>
      </nav>
    </div>
    <div class="game-details">

      <div class="game-id-text">
        <span class="game-config">{{gameConfig[selctedConfigIndex]}}min</span> <br /><span
          class="game-id">{{gameId}}</span>
      </div>
      <div class="last-win-prediction">
        <div class="ball" class="{{predictionWonClass}}"><span [@slideInOut] class="numbers">{{wonNumber}}</span>
        </div>
      </div>
      <div class="timer right">
        <ng-container *ngFor="let char of counter | formatTime | splitToATag">
          <span class="timer-ticks">{{char}}</span>
        </ng-container>
      </div>
    </div>
    <div class="progress2 progress-moved">
      <div class="progress-bar2" [style.width]="dynamicWidth">
      </div>
    </div>
    <div class="prediction-buttons center pred-btn-bg flex-wrap">
      <button [ngClass]="isButtonDisabled" class="btn btn-green flex rounded-l-3xl" (click)="colorClicked('GREEN', -1)"
        data-color="green">GREEN</button>
      <button [ngClass]="isButtonDisabled" class="btn btn-violet flex min-w-unit-20" data-color="violet"
        (click)="colorClicked('VIOLET',-1)">VIOLET</button>
      <button [ngClass]="isButtonDisabled" class="btn btn-red flex rounded-r-3xl" data-color="red"
        (click)="colorClicked('RED',-1)">RED</button>
    </div>
    <div class="color-balls">
      <div class="ball-row">
        <div [ngClass]="isButtonDisabled" class="ball violet-red color-ball-red-violet"
          (click)="colorClicked('RED_VIOLET', 0)" data-color="violet-green"><span class="numbers">0</span>
        </div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball green color-ball-green"
          (click)="colorClicked('GREEN', 1)" data-color="green"><span class="numbers">1</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball red color-ball-red" data-color="red"
          (click)="colorClicked('RED', 2)"><span class="numbers">2</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball green-1 color-ball-green"
          data-color="green-1" (click)="colorClicked('GREEN', 3)"><span class="numbers">3</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball red color-ball-red"
          data-color="violet-green-violeet" (click)="colorClicked('RED', 4)"><span class="numbers">4</span></div>
      </div>
      <div class="ball-row">
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball violet-green color-ball-green-violet"
          (click)="colorClicked('GREEN_VIOLET', 5)" data-color="red-1"><span class="numbers">5</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball red-2 color-ball-red"
          data-color="red-2" (click)="colorClicked('RED', 6)"><span class="numbers">6</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball green-2 color-ball-green"
          data-color="green-2" (click)="colorClicked('GREEN', 7)"><span class="numbers">7</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball red-3 color-ball-red"
          data-color="red-3" (click)="colorClicked('RED', 8)"><span class="numbers">8</span></div>
        <div [ngClass]="isButtonDisabled" [ngClass]="isButtonDisabled" class="ball green-3 color-ball-green"
          data-color="green-3" (click)="colorClicked('GREEN', 9)"><span class="numbers">9</span></div>
      </div>
    </div>
    <div *ngIf="isLoading" class="flex justify-center fixed inset-0 items-center h-screen">
      <svg class="w-12 h-12 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none"
        xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path
          d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
          stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path
          d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
          stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-red-500">
        </path>
      </svg>
    </div>
    <div class="betting-close-overlay" *ngIf="blocked">
      <div class="close-message">
        <h4>Betting closed in the last {{minRemainingTimeToComplete}} seconds</h4>
        <img src="../../assets/error.png" />
      </div>
    </div>
    <div class="loader-overlay countdown fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      *ngIf="countdownStart">
      <ng-lottie [options]="options" class="w-80 h-80"></ng-lottie>
    </div>
    <div class="win-full-container" *ngIf="showWonStatusDialog" (click)='closeWinDialog()'>
      <div class="win-container">
        <div class="win-animation">
          <ng-lottie [options]="optionsForWin1" class="absolute inset-0 w-full h-full z-0 opacity-50"></ng-lottie>
          <button class="close-btn" aria-label="Close" (click)='closeWinDialog()'>
            &times;
          </button>
          <h2 class="text-center mb-4">You have won <b>₹{{wonAmount}}</b></h2>

          <div class="flex justify-center items-center mb-4 space-x-4">
            <ng-lottie [options]="optionsForWin2" class="w-40 h-40"></ng-lottie>
          </div>

          <!-- Second line with the wallet text -->
          <span class="wallet-add-text text-center block">
            <b>₹{{wonAmount}}</b> Has been added to your wallet
          </span>
        </div>
      </div>
    </div>
    <div class="my-bets">
      <div class="bet-header-container">
        <div class="color-machine-parting-line bg-full"></div>
        <div class="my-bet-header">MY BETS</div>
        <div class="color-machine-parting-line bg-full"></div>
      </div>
      <div class="bet-details">
        <div class="no-bets" *ngIf="!betItems || betItems.length === 0">
          No Bets Placed
        </div>
        <div *ngFor="let bet of betItems" [ngClass]="bet.myBetClass" class="bet-ball">
          <span class="numbers">{{ bet.myBetNumber }}</span>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-end px-3 h-12 min-h-12 bg-white w-full" aria-label="Options">
      <div
        class="flex-nowrap   bg-transparent w-full h-full flex items-end relative rounded-none p-0 border-b border-gray-200 overflow-auto gap-8"
        aria-label="Options" role="tablist" aria-orientation="horizontal">
        <button *ngFor="
          let label of ['Result', 'Top Winners', 'My Order'];
          let i = index
        " (click)="onTabClick(i)" [class.text-main]="selectedBottomTab === i"
          [class.border-b-2]="selectedBottomTab === i" [class.border-red-600]="selectedBottomTab === i"
          [class.font-bold]="selectedBottomTab === i"
          class="z-0 w-full py-1 flex group relative justify-center items-center cursor-pointer transition-opacity outline-none text-sm rounded-none max-w-fit px-0 h-7 text-sec"
          role="tab" [attr.aria-selected]="selectedBottomTab === i">
          <div class="relative z-10 whitespace-nowrap transition-colors">
            {{ label }}
          </div>
        </button>
      </div>
    </div>
    <div class="w-full pb-12" [ngSwitch]="selectedBottomTab" id="chart">
      <app-ball-game-results-tab *ngSwitchCase="0" [resultData]="resultData" [totalGames]="totalResults"
        [totalPages]="totalResultPages" (pageChange)="onPageResultsPageChange()"></app-ball-game-results-tab>
      <app-ball-gamewinners *ngSwitchCase="1"></app-ball-gamewinners>
      <app-ball-game-orders #orderTab *ngSwitchCase="2"></app-ball-game-orders>
    </div>

  </div>
  <app-modal #modal></app-modal>
  <app-home-bottom-nav></app-home-bottom-nav>
</div>