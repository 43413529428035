<ng-container *ngIf="isVisible">

    <head>
        <link rel="preload" href="../../../assets/img_ls_border.webp" as="image">
    </head>
    <div class="container game-container max-w-md">
        <div class="overlay items-center">
            <div class="flex flex-col items-center">
                <div class="close-icon flex items-center">
                    <img src="../../../assets/img_lucky_spin.webp" class="img-banner">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"
                        style="width: 2rem; height: 2rem;" (click)="close()">
                        <path stroke="#fff" d="M28.854 9.29a14.5 14.5 0 1 1-3.208-4.116"></path>
                        <g fill="#fff">
                            <rect width="3" height="13.333" x="12.292" y="21.893" rx="1.5"
                                transform="rotate(-135 12.292 21.893)"></rect>
                            <rect width="3" height="13.333" x="21.484" y="19.771" rx="1.5"
                                transform="rotate(135 21.484 19.771)"></rect>
                        </g>
                    </svg>
                </div>
                <div class="wheel-container" *ngIf="isWheelVisible">
                    <ngx-wheel class="wheel" [width]="300" [height]="300" [spinDuration]="13"
                        [disableSpinOnClick]="true" [items]="items" [innerRadius]="15" [spinAmount]="10"
                        pointerStrokeColor="#00fdd300" pointerFillColor="{{pointerColor}}"
                        [textOrientation]="textOrientation" [textAlignment]="textAlignment" [idToLandOn]="idToLandOn"
                        (onSpinStart)="before()" (onSpinComplete)="after()">
                    </ngx-wheel>
                    <img src="../../../assets/ic_pointer.webp" alt="Pointer" class="custom-pointer">
                    <img loading="eager" src="../../../assets/img_ls_border.webp" class="wheel-border">
                </div>
                <button *ngIf="isWheelVisible" [disabled]="isSpinning"
                    class="mx-auto spin-button text-lg text-center font-bold" (click)="startSpin()">SPIN</button>
                <div class="gift-section flex flex-col justify-center items-center text-center" *ngIf="isGiftVisible">
                    <img class="text-center" src="../../../assets/win_game_2.gif">
                    <span class="win-text text-lg font-bold mx-20">WooooooW <br/> {{giftMessage}}</span>
                    <button class="mx-auto spin-button text-lg text-center font-bold" (click)="claimGift()">Tap to Claim</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>