<div class="flex flex-col justify-center items-center w-full"> 
    <div class="flex  space-x-4 h-12 p-2 w-full" style="background-color: #F7F9FF">
        <button class="px-4 py-2 bg-gray-200 rounded-full text-gray-700  focus:outline-none">1st-prize</button>
    </div>
    <div class="text-gray-700 flex items-center justify-center h-12 px-2 text-center space-x-2 w-full" style="background-color:#E7EAF4 ;">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        <div>Please select atleast 1 number for every digit</div>
    </div>
    <div class="flex   items-center p-2 w-full " style="background-color: rgb(223, 227, 237);">
        <div class="flex-1 flex items-center justify-center">
            <div class="grid grid-cols-5 grid-rows-2 gap-x-10" >
               <div class=" flex items-center justify-center flex-col" *ngFor="let option of ['0','1','2','3','4','5','6','7','8','9']" (click)="onCheckboxChange(option, 'red', 0)">
                    <div class=" flex flex-col items-center border-2 rounded-full h-12 w-12 justify-center"[ngStyle]="{'border-color': '#9fa5ac', 'background-color': selectedValues.includes(option + 0) ? 'red' : 'white'}" style="box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 8px 0px, rgb(255, 255, 255) -4px -4px 8px 0px;">
                        <span class="font-bold   text-sm text-main" [ngStyle]="{'color': selectedValues.includes(option + 0) ? 'white' : 'black'}">{{option}}</span>
                    </div>
                    <div class=" font-medium text-center ">5X</div>
               </div>
            </div>
        </div>
    </div>
</div>