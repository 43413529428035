import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../_services/token-storage.service';
import { Observable } from 'rxjs';

const TOKEN_HEADER_KEY = 'Authorization';
const CHANNEL_ID_HEADER_KEY = 'ChannelId';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    const channelId = 'SINGAM';
    if (token != null) {

      // for Node.js Express back-end
      authReq = req.clone({
        headers: req.headers
          .set(TOKEN_HEADER_KEY, token)
          .set(CHANNEL_ID_HEADER_KEY, channelId)
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set(CHANNEL_ID_HEADER_KEY, channelId)
      });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];