import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL_PATH = 'api/v1/lottery/';
const API_BALL_GAME_URL_PATH = 'api/v1/color-game/';
const API_URL_PATH_2 = 'api/v1/keralalottery/';

@Injectable({
    providedIn: 'root'
})
export class LotteryService {
    private baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    private getHttpOptions() {
        const token = localStorage.getItem('Authorization');  // Replace 'authToken' with the key used in local storage
        // console.log(token);
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'channel': 'lottopi',
                'version': environment.apiVersion,
                'Authorization': `${token}` // Assuming a Bearer token, adjust as needed
            })
        };
    }

    getActive(): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getActiveLotteries', this.getHttpOptions());
    }

    getLotteryTypes(): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getLotteryTypes', this.getHttpOptions());
    }

    getLotteryDetails(lotteryId: string): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'details/' + lotteryId, this.getHttpOptions());
    }

    getChartData(lotteryName: string): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'analyze/' + lotteryName, this.getHttpOptions());
    }

    getPagination(lotteryType: string, page: number = 0, limit: number): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + `getResultHistory?gameName=${lotteryType}&page=${page}&limit=${limit}`, this.getHttpOptions());
    }

    getMyOrders(page: number = 0, limit: number): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + `orders?page=${page}&limit=${limit}`, this.getHttpOptions());
    }

    placeBet(payload: any): Observable<any> {
        return this.http.post(this.baseUrl + API_URL_PATH + `lotteryGameBet`, payload, this.getHttpOptions())
    }
    
    getActiveInstantLotteries(): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + 'getActiveInstantLotteries', this.getHttpOptions())
    }
    
    getNextLottery(lotteryName: string): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH + `getNextLottery?gameName=${lotteryName}`, this.getHttpOptions())
    }
    
    getLotteryWinStatus(gameId: string): Observable<any> {
        return this.http.get(this.baseUrl + API_URL_PATH_2 + `winStatus?gameId=${gameId}`, this.getHttpOptions())
    }
}
