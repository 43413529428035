<!-- dice-game.component.html -->
<div class="game-tab-container">
    <div class="header">
      <img src="../../../assets/dice-game-head.webp" />
      <div>Dice</div>
    </div>
    <div class="horizontal-list">
      <div class="item" *ngFor="let image of images" (click)="onImageClick(image)">
        <img [src]="image.src" [alt]="image.alt">
      </div>
    </div>
  </div>
  